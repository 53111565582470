@import 'account-form-variables';

.account-form {
    padding: $account-form__padding;

    &__info {
        margin: $account-form__info-margin\@small;
        font-size: $account-form__info-font-size;
        color: $account-form__text-color;

        @include mq($screen-m) {
            margin: $account-form__info-margin;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $account-form__form-margin;
    }

    &__fieldset {
        margin: $account-form__fieldset-margin;
        width: 100%;

        .label, .input__label {
            font-size: 12px;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $account-form__actions-width;

        @include mq(false, $screen-m) {
            flex-direction: column;
        }

        .button {
            width: calc(50% - 10px);

            @include mq(false, $screen-m) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        &.registration__actions {
            .button {
                width: 100%;
            }
        }
    }

    &__forgot-password,
    &__link {
        text-decoration: $account-form__forgot-password-text-decoration;
    }

    &__validation-tips {
        margin: $account-form__validation-tips-margin;
        font-size: $account-form__validation-tips-font-size;
    }

    .double-inputs__field {
        display: flex;
        justify-content: space-between;

        @include mq(false, $screen-m) {
            flex-direction: column;
        }

        .input {
            width: calc(50% - 10px);

            @include mq(false, $screen-m) {
                width: 100%;
            }
        }

        &.full-width-input {
            .input {
                width: 100%;
            }
        }
    }

    .note-info {
        margin-top: 15px;
        position: relative;

        .shipping-info-icon {
            position: absolute;
        }

        .info-text {
            font-size: 14px;
            line-height: $account-form__info-block-line-height;
            color: $account-form__text-color;
            padding-left: 22px;
            display: block;
        }
    }

    .password-strength {
        margin-top: 20px;
    }

    .req-note {
        font-size: $account-form__info-block-font-size;
        line-height: $account-form__info-block-line-height;
        color: $account-form__text-color;
        margin-bottom: 30px;
    }

    .checkbox {
        margin-bottom: 30px;

        .checkbox__label {
            display: block;
            padding: 0 0 0 29px;
            color: $account-form__text-color;
            font-size: 14px;
            line-height: $account-form__info-block-line-height;

            a {
                text-decoration: none;
                color: $account-form__blue;

                &:hover {
                    text-decoration: underline;
                }
            }

            &::before {
                content: '';
                display: block;
                border: 1px solid $account-form__text-color ;
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 1px;
                left: 2px;
                top: 4px;
            }

            &::after {
                width: 15px;
                height: 15px;
                top: 4px;
                background: $account-form__text-color ;
                transform: scaleX(0) translateY(0);
            }
        }

        .checkbox__icon,
        .checkbox__field {
            top: 4px;
            transform: translateY(0);
            width: 15px;
            height: 15px;
            left: 2px;

            &:checked ~ .checkbox__label:after {
                transform: scaleX(1) translateY(0);
                top: 4px;
            }
        }
    }

    .region_id {
        display: none;
    }
}

.customer-account-createpassword {
    .account-form__actions .button {
        width: 100%;
    }
}
