// @author    Sarah Lee Mandolla <SarahLee.Mandolla@brewes.de>
// @author    Alex Mischarin <Alex.Mischarin@brewes.de>
// @copyright 2023 Brewes GmbH
// @link      https://www.brewes.de/
// ------------------------------------------------------------------
// CSS for Brewes main page built by the Magento2 Page Builder
// ------------------------------------------------------------------

@import "../../Atoms/variables/brewes-variables";
@import "brewes-homepage-variables";

#html-body .brewes_homepage {

    &--mainimage-row[data-content-type="row"] {
        height: 480px;
        background-position: center top;
        background-size: 100% auto;
        background-repeat: no-repeat;

        .row-full-width-inner {
            width: 100%;
            position: relative;
        }

        .pagebuilder-column-group {
            position: absolute;
            margin-top: 280px;
            padding: 0 50px;
        }

        .pagebuilder-column-line {
            column-gap: $brew-homepage-vertical-column-gap;
        }

        .pagebuilder-column {
            padding: 40px 20px 20px 20px;
            box-shadow: $brew-homepage-white-block-shadow;
            position: relative;

            div[data-content-type="text"] {
                p {
                    hyphens: auto;
                }
            }

            .link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .link-text {
                text-transform: uppercase;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                p {
                    display: inline-flex;
                    column-gap: 10px;
                    align-items: center;
                    font-size: 14px;
                    line-height: 18px;
                    font-style: italic;
                    color: #666666;
                    font-weight: bold;
                    &::before {
                        content: url("../images/icons/arrow-gray.svg");
                    }
                }
            }

            .heading {
                font-size: 23px;
                font-weight: bold;
                line-height: 1.2;
                height: 4.5rem;
                hyphens: none;
            }
        }

        .pagebuilder-column:hover  {

            .link-text {
                padding-left: 10px;
                color: #333333;
            }
        }
    }

    &--top-category-row {
        padding-top: 160px;
        padding-bottom: 60px;
        background-color: $brew-homepage-block-gray-background;
    }

    &--special-item-header {
        margin-top: 60px;
        margin-bottom: 35px;
    }

    &--special-item {
        background-color: $brew-section-midtone;
        padding: 10px;
        width: 100%;
        height: 100%;
        margin-bottom: 80px;
        cursor: pointer;
        border: 1px solid $brew-section-midtone;
        border-radius: 4px;

        &:hover {
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
            border-color: #D9D9D9;
            transition: box-shadow 250ms, border-color 250ms;

            .link--arrow-gray {
                padding-left: 10px;
                transition: all .3s ease-in-out;
                color: #333;
            }
        }

        .pagebuilder-column-group .pagebuilder-column-line {

            .pagebuilder-column:first-child {
                background-color: $brew-section-bright;
                padding-top: 0;
                padding-bottom: 0;

                figure {
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto;
                        height: 460px;
                        width: auto;
                    }
                }
            }

            .pagebuilder-column:last-child {
                padding-left: 25px;
                padding-right: 35px;
                position: relative;
            }
        }

        .special-item-price-tip {
            position: absolute;
            top: -10px;
            right: 60px;

            svg {
                width: 35px;
                height: 62px;
            }
        }

        .special-item-name {
            width: 100%;

            p {
                font-size: 40px !important;
                font-style: italic !important;
                text-align: center !important;
                font-weight: lighter;
                line-height: 1.2;
                color: $brew-text;

                span {
                    font-size: inherit !important;
                    font-style: inherit !important;
                    text-align: inherit !important;
                    font-weight: inherit !important;
                }
            }
        }

        .special-item-price {

            &--container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 45px;
            }

            &--value {
                font-size: 80px;
                font-weight: bold;
                font-style: italic;
                color: $brew-text;

                .cents {
                    font-size: 40px;
                    text-decoration: underline;
                    vertical-align: super;
                }
            }

            &--description {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            &--old {
                color: $brew-red-brewes;
                font-size: 20px;
                padding-left: 20px;

                .price-value {
                    font-size: inherit;
                    text-decoration: line-through;
                }
            }

            &--include {
                color: $brew-text;
                font-size: 12px;
                padding-top: 13px;
            }
        }

        .link {
            text-transform: uppercase;
            text-decoration: none;
            font-size: 20px;
        }

        .link:hover {
            padding-left: 10px;
        }
    }

    &--contacts {
        background-color: $brew-section-midtone;
        margin-bottom: 70px;

        .row-full-width-inner {
            padding: 9px 30px;
        }

        .pagebuilder-column-line {
            justify-content: space-between;

            .pagebuilder-column {
                align-self: center;
                width: auto;
            }
        }

        .contacts-text {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
        }

        .contacts-phone {

            &--container {
                display: flex;
                flex-direction: row;
            }

            &--icon {
                padding-right: 41px;

                svg {
                    width: 60px;
                    height: 60px;
                }
            }

            &--number {
                font-size: 20px;
                font-weight: 600;
                white-space: nowrap;
                line-height: 1.3;
            }

            &--time {
                font-size: 16px;
                line-height: 1.3;
            }
        }

        .contacts-mail {
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid rgba(51, 51, 51, 0.5);

            &--container {
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;
                }

                a:hover {
                    .contacts-mail {
                        border-bottom: 1px solid rgba(102, 102, 102, 0.5);
                    }
                }
            }

            &--icon {
                padding-right: 37px;

                svg {
                    width: 74px;
                    height: 54px;
                }
            }
        }
    }

    &--world-theme-header {
        margin-bottom: 35px;
    }

    &--world-theme {
        div[data-content-type="row"] {
            border-bottom: 1px solid $brew-border;
            padding-bottom: 50px;
        }
    }

    &--about-container {
        background: linear-gradient(173deg, $brew-red-brewes 68%, $brew-section-midtone calc(68% + 1px));
    }

    &--about-title-container {
        padding: 102px 0 91px 0;
    }

    &--about-title {
        color: $brew-text--light;
        font-size: 50px;
        font-weight: bold;
        font-style: italic;
        width: 100%;
        text-align: center;
    }

    &--about {
        padding-bottom: 100px;

        .pagebuilder-column-group:first-child {
            position: relative;
            height: 485px;

            .pagebuilder-column-line {
                justify-content: center;
                padding-top: 25px;
                position: absolute;
            }

            .pagebuilder-column {
                width: 347px;
                margin: 0 6px;

                div[data-content-type="html"] {
                    height: 100%;
                    position: relative;
                }
            }
        }

        .about-card {
            background-color: $brew-section-bright;
            padding: 35px 35px 0px 35px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
            height: 465px;
            width: 100%;
            transition: all 0.25s ease;

            &--title,
            &--tagline,
            &--text {
                text-align: center;
                width: 100%;
                transition: all 0.25s ease;
            }

            &--title {
                color: $brew-titles;
                font-size: 20px;
                font-weight: bold;
                font-style: italic;
            }

            &--tagline {
                color: $brew-text;
                font-size: 20px;
                font-style: italic;
                margin-bottom: 30px;
            }

            &--text {
                color: $brew-text;
                font-size: 16px;
                padding-top: 25px;
                border-top: 1px solid #5C6166;
                line-height: 1.5;
            }
        }

        .about-card.active {
            position: absolute;
            padding: 40px 46px 0px 46px;
            width: calc(100% + 40px);
            height: calc(100% + 50px) !important;
            margin-top: -25px;
            margin-left: -20px;
            z-index: 1;

            .about-card--title {
                padding-bottom: 4px;
                font-size: 21px;
            }

            .about-card--tagline {
                margin-bottom: 37px;
                font-size: 21px;
            }

            .about-card--text {
                padding-top: 28px;
                font-size: 17px;
                border-top: 2px solid #5C6166;
            }
        }
    }

    &--reference {
        background-color: $brew-section-midtone;
        padding-top: 30px;
        padding-bottom: 90px;

        .trusted-reference--container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }

        .trusted {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            border-right: 1px solid $brew-section-bright;

            &--link {
                padding: 0 60px;

                .button--icon.button--icon-top.button--icon-trusted {
                    background: none;
                    width: 183px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &--data {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0 60px 0 40px;
            }

            &--title {
                color: $brew-text;
                font-size: 16px;
                margin-bottom: 3px;
            }

            &--score {
                font-size: 48px;
                margin-bottom: 3px;
            }

            &--stars {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 15px;
                position: relative;

                svg {
                    margin: 0 8px;
                    width: 48px;
                    height: 45px;
                }
            }

            &--cover {
                position: absolute;
                overflow: hidden;
                height: 100%;
                background: white;
                top: 0;
                right: 0;
                mix-blend-mode: color;
            }

            &--tagline {
                color: $brew-text;
                font-size: 16px;
            }
        }

        .reference {
            display: none;
            width: 100%;
        }

        .reference.active {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding: 0 120px;

            .reference--name {
                color: $brew-titles;
                font-size: 30px;
                margin-bottom: 15px;
                text-align: center;
            }

            .reference--text {
                color: $brew-text;
                font-size: 16px;
                line-height: 1.4;
                text-align: center;
            }
        }
    }

    .brewes_about--indicator-container {
        display: none;
    }

    &--information {

        .pagebuilder-column {
            margin: 0 20px;
        }

        .information {

            &--title {
                color: $brew-titles;
                font-size: 20px;
                font-weight: bold;
                font-style: italic;
                line-height: 1.3;
                margin-bottom: 20px;
            }

            &--text {
                color: $brew-text;
                font-size: 16px;
            }
        }
    }

    &--logos {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 40px 0;

        .logos {

            &--left-arrow,
            &--right-arrow {

                a {
                    display: block;
                    background-color: $brew-section-bright;
                    width: 65px;
                    height: 109px;
                    padding: 27px 22px 27px 22px;
                    transition: all 0.2s ease;

                    svg {
                        width: 21px;
                        height: 55px;
                        transition: all 0.2s ease;
                    }
                }

                a:hover {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
                    border-radius: 5px;
                    padding: 22px 20px 22px 20px;

                    svg {
                        width: 25px;
                        height: 65px;
                    }
                }
            }

            &--left-arrow {
                padding-right: 10px;
            }

            &--right-arrow {
                padding-left: 10px;
            }

            &--container {
                overflow-y: hidden;
                scrollbar-width: none;
            }

            &--container::-webkit-scrollbar {
                display: none;
            }

            &--row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                column-gap: 38px;
                touch-action: auto;
                width: auto;

                img {
                    width: 185px;
                    filter: grayscale(1);
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    #html-body .brewes_homepage {

        &--mainimage-row[data-content-type="row"] {
            background-size: cover;
        }

        &--about {

            .about-card {
                padding: 37px 20px 50px 20px;

                &--title,
                &--tagline,
                &--text {
                    text-align: center;
                    width: 100%;
                    transition: all 0.25s ease;
                }

                &--title {
                    font-size: 18px;
                }

                &--tagline {
                    font-size: 17px;
                }

                &--text {
                    font-size: 15px;
                }
            }

            .about-card.active {
                padding: 37px 20px 50px 20px;
                position: unset;
                width: 100%;
                height: 465px !important;
                margin: 0;

                .about-card--title {
                    padding-bottom: 0;
                    font-size: 18px;
                }

                .about-card--tagline {
                    margin-bottom: 30px;
                    font-size: 17px;
                }

                .about-card--text {
                    padding-top: 25px;
                    font-size: 15px;
                    border-top: 1px solid #5C6166;
                }
            }
        }

        &--logos {
            display: none;
        }
    }

    &--reference {

        .reference {
            padding: 0 45px;
        }
    }
}

@media screen and (max-width: 1200px) {

    #html-body .brewes_homepage {

        &--mainimage-row[data-content-type="row"] {
            background-size: 120% auto;
            height: 340px;

            .pagebuilder-column-group {
                position: initial;
                margin-top: 55px;
            }

            .pagebuilder-column-line {
                flex-direction: row;
                flex-wrap: wrap;
                column-gap: $brew-homepage-vertical-column-gap-mobile;
                row-gap: $brew-homepage-horizontal-column-gap-mobile;
                justify-content: center;
            }

            .pagebuilder-column {
                position: relative;
                width: 230px !important; // fix grid.css important flag
                height: 230px;
                padding: 5px;
                align-items: center;
                justify-content: center;

                [data-content-type="heading"] {
                    text-align: center;
                    height: auto;
                    font-size: 18px;
                }

                [data-content-type="text"] {
                    display: none;
                }

                [data-content-type="html"] {

                    .link::before {
                        display: none;
                    }
                }
            }
        }

        &--top-category-row {
            padding-top: 50px;
        }

        &--special-item {
            background-color: $brew-section-bright;
            padding: 0;
            margin-bottom: 60px;

            .pagebuilder-column-group .pagebuilder-column-line {

                .pagebuilder-column:first-child {
                    padding-top: 0;
                    padding-bottom: 20px;

                    figure {

                        img {
                            height: 270px;
                        }
                    }
                }

                .pagebuilder-column:last-child {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            .special-item-price-tip {
                display: none;
            }

            .special-item-name {

                p {
                    font-size: 25px !important;

                    span {
                        font-size: inherit !important;
                    }
                }
            }

            .special-item-price {

                &--container {
                    margin-top: 5px;
                    margin-bottom: 20px;
                }

                &--value {
                    font-size: 45px;

                    .cents {
                        font-size: 25px;
                    }
                }

                &--description {
                    padding-left: 6px;
                }

                &--old {
                    font-size: 16px;
                    padding-left: 10px;
                }

                &--include {
                    padding-top: 0;
                }
            }

            .link {
                font-size: 16px;
            }
        }

        &--contacts {
            margin-bottom: 40px;

            .row-full-width-inner {
                padding: 30px 15px;
            }

            .pagebuilder-column-line {
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                row-gap: 50px;

                .pagebuilder-column {
                    width: 50% !important;
                    align-self: flex-start;
                }

                .pagebuilder-column:first-child {
                    width: 100% !important;
                }
            }

            .contacts-text {
                font-size: 18px;
                font-weight: normal;

                &--container {
                    text-align: center;
                }
            }

            .contacts-phone {
                text-align: center;

                &--container {
                    flex-direction: column;
                }

                &--icon {
                    padding-right: 0;
                    margin-bottom: 10px;
                    align-self: center;

                    svg {
                        width: 38px;
                        height: 38px;
                    }
                }

                &--number {
                    font-size: 18px;
                }

                &--time {
                    font-size: 14px;
                }
            }

            .contacts-mail {
                font-size: 16px;

                &--container {

                    a {
                        flex-direction: column;
                        align-items: center;
                        text-decoration: none;
                    }
                }

                &--icon {
                    padding-right: 0;
                    margin-bottom: 10px;

                    svg {
                        width: 52px;
                        height: 38px;
                    }
                }
            }
        }

        &--world-theme {
            div[data-content-type="row"] {
                padding-bottom: 5px;
            }
        }

        &--about-container {
            background-color: $brew-red-brewes;
            background-image: none;
        }

        &--about-title-container {
            padding: 45px 0 54px 0;
            background: none;
        }

        &--about-title {
            font-size: 25px;
        }

        &--about {
            padding-bottom: 25px;
            background: none;

            .pagebuilder-column-group:first-child {
                overflow-y: hidden;
                scrollbar-width: none;
                min-height: 425px;
                height: auto;

                .pagebuilder-column-line {
                    padding-top: 0;
                    width: auto;
                    touch-action: auto;
                    flex-direction: row;
                    flex-wrap: nowrap;
                }

                .pagebuilder-column {
                    width: 340px !important;
                }
            }

            .pagebuilder-column-group::-webkit-scrollbar {
                display: none;
            }

            .about-card {
                padding: 30px 35px 0 35px;
                height: 400px;

                &--tagline {
                    margin-bottom: 25px;
                }
            }

            .about-card.active {
                padding: 30px 35px 0 35px;
                height: 400px !important;
            }
        }

        &--reference {
            padding-top: 56px;
            padding-bottom: 35px;

            .trusted-reference--container {
                flex-direction: column;
            }

            .trusted {
                flex-direction: column;
                border-right: none;
                border-bottom: 1px solid $brew-section-bright;
                width: 100%;
                padding-bottom: 40px;

                &--link {
                    padding: 0;
                    margin-bottom: 35px;

                    .button--icon.button--icon-top.button--icon-trusted {
                        width: 113px;
                    }
                }

                &--data {
                    padding: 0;
                }

                &--title {
                    color: $brew-titles;
                }

                &--score {
                    font-size: 40px;
                }

                &--stars {
                    margin-bottom: 35px;

                    svg {
                        margin: 0 5px;
                        width: 30px;
                        height: 28px;
                    }
                }

                &--tagline {
                    color: $brew-titles;
                }
            }

            .reference.active {
                padding: 0;
                margin-top: 40px;

                &--name {
                    font-size: 20px;
                }

                &--text {
                    color: $brew-titles;
                }
            }
        }

        &--information {

            div[data-content-type="row"] {
                padding-top: 45px;
                padding-bottom: 15px;
            }

            .pagebuilder-column {
                margin: 0 0 20px 0;
            }

            .information {

                &--title {
                    font-size: 17px;
                    margin-bottom: 15px;
                }

                &--text {
                    color: $brew-titles;
                }
            }
        }
    }

    .brewes_about {

        &--indicator-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            width: 100%;
            margin: 2px 0 4px 0;
        }

        &--indicator {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            border: 1px solid $brew-section-bright;
        }

        &--indicator.active {
            background-color: $brew-section-bright;
        }
    }
}

@media screen and (max-width: 1080px) {

    #html-body .brewes_homepage {

        &--mainimage-row[data-content-type="row"] {
            background-size: 133% auto;

            .pagebuilder-column-group {
                padding: 0;
                margin-top: 90px;
                width: 100%;
            }

            .pagebuilder-column {
                width: 165px !important; // fix grid.css important flag
                height: 165px;

                .heading {
                    hyphens: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {

    #html-body .brewes_homepage {

        &--mainimage-row[data-content-type="row"] {
            background-size: auto 150%;

            .pagebuilder-column-group {
                position: absolute;
                margin-top: 23px;
                width: 340px;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .pagebuilder-column {
                flex-basis: initial;
            }
        }

        &--special-item {

            .pagebuilder-column-group .pagebuilder-column-line {

                .pagebuilder-column:first-child {

                    figure {

                        img {
                            height: 180px;
                        }
                    }
                }
            }
        }
    }
}
