$account-form__text-color                           : $brew-text;
$account-form__blue                                 : $brew-text--accent;

$account-form__padding                              : $spacer 0 0;
$account-form__actions-width                        : 100%;
$account-form__actions-width\@medium                : auto;

$account-form__form-margin                          : 0;

$account-form__fieldset-width                       : 100%;
$account-form__fieldset-margin                      : 0 0 14px;

$account-form__forgot-password-text-decoration      : none;

$account-form__info-margin                          : 0 0 40px;
$account-form__info-margin\@small                   : 0 0 35px;
$account-form__info-font-size                       : $font-size-medium;

$account-form__validation-tips-margin               : 0 0 $spacer--semi-medium;
$account-form__validation-tips-font-size            : $font-size-medium;

$account-form__info-block-font-size                 : 12px;
$account-form__info-block-line-height               : 18px;
