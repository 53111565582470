$dashboard-items__padding                : $spacer--medium;
$dashboard-items__title-text-transform   : uppercase;

$dashboard-items__subtitle-font-size     : $font-size-medium;
$dashboard-items__subtitle-font-weight   : $font-weight-normal;
$dashboard-items__subtitle-text-transform: uppercase;
$dashboard-items__item-font-size         : $font-size-medium;

$dashboard-items__collapsible-transform  : rotate(180deg);

.dashboard-items {
    &__item {
        padding: $dashboard-items__padding;
        font-size: $dashboard-items__item-font-size;

        .list__item {
            &:last-child {
                padding: 0 0 8px 12px;
            }
        }
    }

    &__title {
        @extend .heading--arrow-red;
        text-transform: $dashboard-items__title-text-transform;
    }

    &__collapsible {
        &--active {
            .icon {
                transform: $dashboard-items__collapsible-transform;
            }
        }
    }

    &__title-collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__subtitle {
        font-size: $dashboard-items__subtitle-font-size;
        font-weight: $dashboard-items__subtitle-font-weight;
        text-transform: $dashboard-items__subtitle-text-transform;
    }

    border-bottom: 1px solid $border-color-base;
    margin-bottom: 50px;
}

.note-item {
    display: flex;
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
    color: $brew-text;
    letter-spacing: 0;

    .note-icon {
        margin-right: 9px;
        flex: 0 0 auto;
        background: #3683c5;
        border-radius: 50%;
        display: inline-block;
        height: 14px;
        position: relative;
        transform: translateY(3px);
        width: 14px;
    }
    .note-icon:before,
    .note-icon:after {
        background-color: #fff;
        content: "";
        display: block;
        left: 50%;
        margin-left: -1px;
        position: absolute;
        width: 2px;
    }
    .note-icon:before {
        height: 2px;
        top: 3px;
    }
    .note-icon:after {
        height: 5px;
        top: 6px;
    }
}
