$categories-list__max-height           : 100%;
$categories-list__overflow             : hidden;

$categories-list__font-size            : $font-size-base;
$categories-list__line-height          : 29px;
$categories-list__font-weight          : $font-weight-base;
$categories-list__font-weight-hover    : $font-weight-base;
$categories-list__text-transform       : unset;
$categories-list__text-decoration      : none;
$categories-list__text-decoration-hover: none;
$categories-list__color                : $brew-text;
$categories-list__color-hover          : $brew-text;
$categories-list__bg-color-hover       : $brew-border;
$categories-list__bg-color-active      : $brew-section-midtone;
$categories-list__item-padding         : 0;
$categories-list__link-padding         : 6px 10px 6px;
$categories-list__link-border          : 1px solid $brew-border-dark;

.categories-list {
    @include mq($screen-l) {
        max-height: $categories-list__max-height;
        overflow-x: $categories-list__overflow;
    }

    &__item,
    .nav.item {
        padding: $categories-list__item-padding;

        &:last-child {
            .categories-list__link {
                border-bottom: $categories-list__link-border;
            }
        }

        &.current {
            .categories-list__link {
                background: $categories-list__bg-color-active;
            }
        }
    }

    &__link,
    .nav.item a {
        display: block;
        padding: $categories-list__link-padding;
        font-size: $categories-list__font-size;
        font-weight: $categories-list__font-weight;
        color: $categories-list__color;
        text-transform: $categories-list__text-transform;
        text-decoration: $categories-list__text-decoration;
        border-top: $categories-list__link-border;

        &:hover,
        &[aria-current="true"] {
            background: $categories-list__bg-color-hover;
            color: $categories-list__color-hover;
            font-weight: $categories-list__font-weight-hover;
            text-decoration: $categories-list__text-decoration-hover;
        }
    }
}
