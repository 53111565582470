// This file contains various global variables for the Brewes Banner Templates.

// Banner Height
$banner-height: 223px;

// Font Sizes
$fs-xs: 10px;
$fs-sm: 20px;
$fs-md: 32px;
$fs-lg: 42px;

// Margin-Left values
$ml-md: 40px;
$ml-lg: 20%;

// Margin-Top value
$mt-sm: 4%;

// Margin-Bottom value
$mb-sm: 20px;

// Colors
$platinum     : rgba(212,214,220,1);
$french-gray  : rgba(187,193,197,1);
$cadet-gray   : rgba(147,152,163,1);

// Mixin: font-weight
// Defines various font weights for elements and their nested tags.
@mixin font-weight($weight) {
  font-weight: $weight !important;
  p, span {
    font-weight: $weight !important;
    span {
      font-weight: $weight !important;
    }
  }
}

// Mixin: font-style
// Defines various font styles for elements and their nested tags.
@mixin font-style($style) {
  font-style: $style;
  span {
    font-style: $style;
  }
  p {
    font-style: $style;
    span {
      font-style: $style;
    }
  }
}

// Mixin: font-size
// Defines various font sizes for elements and their nested tags.
@mixin font-size($size) {
  font-size: $size !important;
  span {
    font-size: $size !important;
  }
  p {
    font-size: $size !important;
    span {
      font-size: $size !important;
    }
  }
}

// Mixin: font-color
// Defines various font colors for elements and their nested tags.
@mixin font-color($color) {
  color: $color !important;
  span {
    color: $color !important;
  }
  p {
    color: $color !important;
    span {
      color: $color !important;
    }
  }
}

// Mixin: margin-left
// Defines the left margin for elements
@mixin margin-left($value) {
  margin-left: $value;
}

// Mixin: margin-bottom
// Defines the bottom margin for elements.
@mixin margin-bottom($value) {
  margin-bottom: $value;
}

// Mixin: margin-top
// Defines the top margin for elements.
@mixin margin-top($value) {
  margin-top: $value !important;
}