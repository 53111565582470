$minicart-conten__total-counter-color          : $brew-titles;

$minicart-content__height                      : auto;
$minicart-content__font-size                   : $font-size-base;
$minicart-content__padding                     : 0;
$minicart-content__padding-sides               : 18px;

//minicart-content top
$minicart-content__top-padding                 : 0 0 21px 0;
$minicart-content__top-margin                  : 0;

//minicart-content middle
$minicart-content__middle-padding              : $spacer--semi-medium 0;
$minicart-content__middle-margin               : 0;
$minicart-content__middle-border               : 0;
$minicart-content__middle-border-width         : 0;

//minicart-content bottom
$minicart-content__bottom-width                : 100%;
$minicart-content__bottom-padding              : $minicart-content__padding-sides 0 0;
$minicart-content__bottom-margin               : 0;
$minicart-content__bottom-background           : $white;
$minicart-content__bottom-border               : $border-base;
$minicart-content__bottom-border-width         : $border-width-base 0 0 0;
$minicart-content__bottom-box-shadow           : 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
$minicart-content__bottom-box-shadow\@medium   : none;

//minicart-content heading
$minicart-content__heading-font-size           : $font-size-large;
$minicart-content__heading-font-size\@medium   : $font-size-extra-large;
$minicart-content__heading-font-weight         : $font-weight-base;
$minicart-content__heading-margin              : 0;
$minicart-content__heading-height              : 48px;
$minicart-content__heading-padding-right       : 70px;
$minicart-content__heading-padding-left        : 0;

//minicart-content counter
$minicart-content__counter-font-size           : $font-size-large;
$minicart-content__counter-font-weight         : $font-weight-semibold;
$minicart-content__counter-margin              : 0;
$minicart-content__counter-height              : 20px;

//minicart summary
$minicart-content__summary-label-color         : $brew-text--light;
$minicart-content__summary-label-font-weight   : $font-weight-base;
$minicart-content__summary-value-color         : $brew-text--light;
$minicart-content__summary-background-color    : $brew-text;
$minicart-content__summary-value-font-weight   : $font-weight-semibold;
$minicart-content__summary-margin              : 0 0 $minicart-content__padding-sides;
$minicart-content__summary-font-size           : $font-size-large;

//minicart actions
$minicart-content__actions-padding             : 0 $minicart-content__padding-sides;
$minicart-content__action-flex-flow            : row wrap;
$minicart-content__action-justify              : space-between;
$minicart-content__action-button-width         : 100%;
$minicart-content__action-button-padding       : 0 $spacer--semi-medium;
$minicart-content__action-button-height        : 60px;
$minicart-content__action-button-font-size     : 20px;

//extra-actions
$minicart-content__extra-actions-flex          : 0 0 100%;
$minicart-content__extra-actions-padding       : $spacer--medium 0 0 0;

//minicart products
$minicart-content__products-list-padding       : 0 $minicart-content__padding-sides;
$minicart-content__products-list-margin        : 0;
