// Colors
$order-summary__color-titles                 : $brew-titles;
$order-summary__color-text                   : $brew-text;
$order-summary__color-midtone                : $brew-section-midtone;
$order-summary__color-light-grey             : $brew-text--light-grey;
$order-summary__color-light                  : $brew-text--light;
$order-summary__color-red                    : $brew-red-brewes;

// Font size
$order-summary__font-size-small              : 13px;
$order-summary__font-size-medium             : 15px;
$order-summary__font-size-big                : 16px;

$order-summary__line-height-small            : 19px;
$order-summary__line-height-medium           : 22px;
$order-summary__line-height-big              : 20px;

// Cart Summary
$order-summary__padding                      : 20px 20px 10px;
$order-summary__padding-m                    : 20px 15px 10px;
$order-summary__background                   : $gray-lighter;
$order-summary__margin                       : 0 0 $spacer--extra-large;
$order-summary__radius                       : 3px;

// Title
$order-summary__title-margin                 : 20px 0 $spacer--medium;
$order-summary__title-font-family            : $font-family-base;
$order-summary__title-font-size              : $font-size-large;
$order-summary__title-font-weight            : $font-weight-bold;
$order-summary__title-text-transform         : none;
$order-summary__title-total-margin           : 0;
$order-summary__title-total-font-family      : $font-family-base;
$order-summary__title-total-font-size        : 15px;
$order-summary__title-total-font-weight      : $font-weight-bold;
$order-summary__title-total-text-transform   : none;

// Amount
$order-summary__amount-margin                : 0;
$order-summary__amount-font-family           : $font-family-base;
$order-summary__amount-font-size             : 15px;
$order-summary__amount-font-weight           : $font-weight-bold;
$order-summary__amount-text-transform        : none;

// Subtitle
$order-summary__subtitle-margin              : 0 0 $spacer--medium;
$order-summary__subtitle-font-family         : $font-family-base;
$order-summary__subtitle-font-size           : $font-size-large;
$order-summary__subtitle-font-weight         : $font-weight-base;
$order-summary__subtitle-text-transform      : none;

// List
$order-summary__list-margin                  : 0;
$order-summary__list-padding                 : $spacer--medium 0;
$order-summary__list-border                  : $border-base;
$order-summary__list-border-width            : 1px 0 0 0;
$order-summary__list-style                   : none;
$order-summary__list-item-min-height         : 48px;
$order-summary__label-font-size              : 15px;
$order-summary__label-light-height           : 18px;

// Link
$order-summary__link-padding                 : 0;
$order-summary__link-font-weight             : $font-weight-base;

// Divider
$order-summary__divider-margin               : 20px 0 10px;
$order-summary__divider-border               : 1px solid #E5E5E5;
$order-summary__divider-border-width         : 1px 0 0;

// Tab
$order-summary__tab-title-padding            : $spacer--small 0;
$order-summary__tab-title-border-top         : $border-base;
$order-summary__tab-title-border-bottom      : $border-base;
$order-summary__tab-text-font-weight         : $font-weight-medium;
$order-summary__tab-icon-width               : 14px;
$order-summary__tab-icon-height              : 14px;
$order-summary__tab-icon-transition          : $transition-base;

// Products
$order-summary__products-display             : block;
$order-summary__product-item-padding         : $spacer--semi-medium 0;
$order-summary__product-item-border-bottom   : $border-base;
$order-summary__product-image-height         : auto;
$order-summary__product-image-width          : 64px;
$order-summary__product-image-max-width      : 64px;
$order-summary__product-image-margin-right   : $spacer;
$order-summary__product-info-width           : 100%;
$order-summary__product-details-padding-right: $spacer;
$order-summary__product-name-font-size       : $font-size-base;
$order-summary__product-name-font-weight     : $font-weight-base;
$order-summary__product-qty-color            : $gray-dark;
$order-summary__product-price-font-size      : $font-size-base;
$order-summary__product-wrapper-width        : calc(100% - 140px);

// Divider
$order-summary__divider-width                : calc(100% + 50px);
$order-summary__divider-width-m              : calc(100% + 42px);
$order-summary__divider-margin-left          : -25px;
$order-summary__divider-margin-left-m        : -17px;

// Cards
$order-summary__cards-padding                : 10px 20px 0;
$order-summary__cards-width                  : calc(100% + 40px);
$order-summary__cards-margin-left            : -20px;
$order-summary__cards-svg-width              : 60px;
$order-summary__cards-svg-height             : 30px;

// Cart banner
$order-summary__cart-banner-padding          : 0 24px;

// Tooltip
$order-summary__tooltip-text-padding         : 12px 12px 10px;
