@import 'minicart-content-variables';

.minicart-content {
    height: $minicart-content__height;
    font-size: $minicart-content__font-size;
    padding: $minicart-content__padding;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    &__top {
        padding: $minicart-content__top-padding;
        margin: $minicart-content__top-margin;
        border-bottom: 1px solid $brew-border;
    }

    &__middle {
        margin: $minicart-content__middle-margin;
        border: $minicart-content__middle-border;
        border-width: $minicart-content__middle-border-width;
        overflow-y: visible;
        height: auto !important; // sass-lint:disable-line no-important

        @include hide-scrollbar();

        @include mq($screen-l) {
            overflow-y: auto;

            @include hide-scrollbar(false);
        }
    }

    &__bottom {
        width: $minicart-content__bottom-width;
        margin: $minicart-content__bottom-margin;
        background: $minicart-content__bottom-background;
        padding: 0 0 100px;

        @include mq($screen-l) {
            bottom: 0;
            left: 0;
            padding: $minicart-content__bottom-padding;
            position: relative;
        }
    }

    &__heading {
        font-size: $minicart-content__heading-font-size;
        font-weight: $minicart-content__heading-font-weight;
        margin: $minicart-content__heading-margin;

        @include font-padding(
            $minicart-content__heading-font-size,
            $minicart-content__heading-height,
            $minicart-content__heading-padding-right,
            $minicart-content__heading-padding-left
        );

        @include mq($screen-l) {
            font-size: $minicart-content__heading-font-size\@medium;

            @include font-padding(
                $minicart-content__heading-font-size\@medium,
                $minicart-content__heading-height,
                $minicart-content__heading-padding-right,
                $minicart-content__heading-padding-left
            );
        }
    }

    &__counter {
        font-size: $font-size-medium;
        font-weight: $minicart-content__counter-font-weight;
        margin: $minicart-content__counter-margin;

        @include font-padding(
            $minicart-content__counter-font-size,
            $minicart-content__counter-height,
            $minicart-content__padding-sides,
            $minicart-content__padding-sides
        );

        @include mq($screen-l) {
            font-size: $minicart-content__counter-font-size;
        }
    }

    &__summary {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin: $minicart-content__summary-margin;
        font-size: $minicart-content__summary-font-size;
        background-color: $minicart-content__summary-background-color;
        padding: $minicart-content__padding-sides;
    }

    &__summary-label {
        color: $minicart-content__summary-label-color;
        font-weight: $minicart-content__summary-label-font-weight;

        .price .info-text {
            font-size: 14px;
        }
    }

    &__summary-value {
        color: $minicart-content__summary-value-color;
        font-weight: $minicart-content__summary-value-font-weight;
    }

    &__actions {
        display: flex;
        flex-flow: $minicart-content__action-flex-flow;
        justify-content: $minicart-content__action-justify;
        padding: $minicart-content__actions-padding;

        @include mq(false, $screen-l) {
            padding-bottom: 20px;
        }
    }

    &__extra-actions {
        flex: $minicart-content__extra-actions-flex;

        & > div {
            display: flex;
            padding: $minicart-content__extra-actions-padding;
        }
    }

    &__action-button {
        width: $minicart-content__action-button-width;
        padding: $minicart-content__action-button-padding;
        height: $minicart-content__action-button-height;
        font-size: $minicart-content__action-button-font-size;

        &.minicart-content__checkout {
            margin-bottom: 20px;
        }
    }

    &__products-list {
        margin: 0;
        background-color: $white;
    }

    &__newsletter {
        padding: 0 20px 30px;

        .icon {
            margin-right: 10px;
        }

        &:hover a {
            text-decoration: none;
        }

        span {
            display: contents;
        }
    }

    &__total-counter {
        color: $minicart-conten__total-counter-color;
    }
}
