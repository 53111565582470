$quantity-update__input-border-style       : solid;
$quantity-update__input-border-color       : $border-color-base;
$quantity-update__input-border-width       : 1px 0;
$quantity-update__input-border-width--error: 1px;
$quantity-update__input-border-radius      : 0;
$quantity-update__input-width              : 40px;
$quantity-update__input-height             : 40px;
$quantity-update__input-font-size          : $font-size-medium;
$quantity-update__input-color              : $color-primary;

$quantity-update__button-width             : 30px;
$quantity-update__button-height            : 40px;
$quantity-update__button-margin            : 0;
$quantity-update__button-border-style      : $border-style-base;
$quantity-update__button-border-color      : $border-color-base;
$quantity-update__button-border-width      : $border-width-base;
$quantity-update__button-radius-plus       : 0 3px 3px 0;
$quantity-update__button-radius-minus      : 3px 0 0 3px;

$quantity-update__max-width                : 100%;
$quantity-update__max-width\@medium        : 100px;
$quantity-update__width                    : 100%;

$quantity-update__border--active           : $border-width-base $border-style-base $color-primary;
$quantity-update__transition               : $transition-base;

$quantity-update__mage-error-bottom        : $spacer--semi-large ;
$quantity-update__mage-error-width         : 250px;

$quantity-update__background               : $brew-text--light-grey2;
$quantity-update__background-tooltip       : #000;
$quantity-update__color-tooltip            : #fff;
$quantity-update__fontsize-tooltip         : $font-size-extra-small;
$quantity-update__tooltip-padding          : 2px 10px;
$quantity-update__tooltip-top              : 45px;
