$catalog-list__margin                         : 0 0 $spacer--extra-large;

$catalog-list__banner-padding                 : $spacer--large 0;
$catalog-list__banner-padding\@medium         : $spacer--medium 0;
$catalog-list__banner-padding\@large          : 0;
$catalog-list__banner-margin                  : 0 0 21px 0;
$catalog-list__banner-margin\@large           : 8px 0 40px 0;
$catalog-list__banner-border                  : $border-base;
$catalog-list__banner-border-width            : 0 0 1px 0;
$catalog-list__banner-border-width\@large     : 0;

$catalog-list__product-banner-background      : transparent radial-gradient(closest-side at 50% 76%, #E9EAED 0%, #B1B8C2 100%) 0% 0% no-repeat padding-box;
$catalog-list__product-banner-padding-grid    : 27px 18px 37px 25px;
$catalog-list__product-banner-padding-list    : 29px 39px 19px 36px;
$catalog-list__product-banner-padding-list-m  : 39px 15px 19px 15px;

$catalog-list__sidebar-width                  : 282px;
$catalog-list__sidebar-indent                 : 20px;

.catalog-list {
    display: flex;
    flex-flow: column nowrap;
    margin: $catalog-list__margin;

    @include mq(1024px) {
        display: grid;
        grid-template-columns: $catalog-list__sidebar-width 1fr;
        grid-column-gap: $catalog-list__sidebar-indent;
        grid-template-rows: auto 1fr;
        grid-template-areas:
        'sidebar banner'
        'sidebar subcategories'
        'sidebar cms'
        'sidebar bottom'
        'sidebar products';
    }

    &__title {
        width: 100%;
    }

    &__banner {
        padding: $catalog-list__banner-padding;
        margin-bottom: $catalog-list__banner-margin;

        @include mq($screen-m) {
            padding: $catalog-list__banner-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $catalog-list__banner-padding\@large;
            margin: $catalog-list__banner-margin\@large;
            border-width: $catalog-list__banner-border-width\@large;
        }
    }

    &__products {
        position: relative;
        order: 2;

        @include mq($screen-l) {
            grid-area: products;
        }
    }

    &__products:has(> div.message.info.empty)  {

        @include mq($screen-l) {
            grid-row-start: 2;
        }
    }

    &__sidebar {
        @include mq($screen-l) {
            grid-area: sidebar;
            position: relative;
            order: 1;
            // necessary for tooltip proper visibility
            z-index: $z-index-normal;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $brew-text;
        }

        &-wrapper {
            @include mq($screen-xl) {
                position: sticky;
                top: 202px;
                overflow-y: auto;
                height: calc(100vh - 210px);

                &::-webkit-scrollbar-thumb {
                    background-color: $brew-text;
                }

                > div:not(:first-child) {
                    margin-top: 40px;
                }
            }
        }
    }

    &__category-description {
        position: relative;
        h1, h2, h3, h4, h5, h6, p, span, strong, a {
            color: $brew-text;
        }

        h1, h2, h3, h4, h5, h6 {
            color: #333;
        }

        h1, h2, h3 {
            font-weight: 700;
        }

        h3 {
            font-size: 16px;
        }

        p {
            line-height: 1.6;
        }

        #infotext {
            position: absolute;
            top: 0;
            margin-top: -55px;

            @include mq(768px) {
                margin-top: -68px;
            }

            @include mq(1024px) {
                margin-top: -120px;
            }

        }
    }

    &__sidebar-additional {
        order: 3;

        @include mq($screen-l) {
            grid-area: sidebar2;
            align-self: start;
        }
    }
}

.grey-background .catalog-list__category-description {
    margin: $spacer--extra-large 0 0 0;
    padding: 39px 0 26px;
    background-color: #eee;
    overflow: hidden;

    @include mq($screen-m) {
        padding: 69px 0 56px;
    }

    @include mq($screen-l) {
        padding: 99px 0 86px;
    }

    > * {
        max-width: $max-content-width;
        padding: 0 15px;
        margin: 0 auto;
        color: $brew-text;

        @include mq($screen-xxl) {
            padding: 0;
        }
    }
}

.subcategories {
    grid-area: subcategories;
    display: grid;
    grid-template-columns: minmax(165px, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding-left: 0;
    margin-top: 0;
    list-style-type: none;

    &--active {
        .subcategories__item--hidden {
            display: block;
        }

        .subcategories__show-all {
            display: none;
        }
    }

    grid-template-columns: repeat(2, minmax(165px, 1fr));

    @include mq(false, $screen-s) {
        &__item {
            .category-tile__title {
                width: 100%;

                h2 {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    hyphens: auto;
                }
            }
        }
    }

    @include mq($screen-m) {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
    }

    @include mq($screen-l) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-column-gap: 20px;
        grid-row-gap: 55px;
    }

    @include mq($screen-xl) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @include mq($screen-xxl) {
        grid-template-columns: repeat(auto-fill, minmax(200px, calc(25% - (20px * 3 / 4))));
    }

    &__heading {
        margin-bottom: 21px;

        @include mq($screen-l) {
            margin-bottom: 40px;
        }
    }

    &__item--hidden {
        display: none;
    }

    &__show-all-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        background-color: $brew-section-midtone;
        color: #5C6166;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        border: $brew-standard-border-dark;
        border-radius: $brew-standard-border-radius;

        @include mq($screen-xl) {
            font-size: 22px;
            line-height: 36px;
        }

        @include mq($screen-xxl) {
            font-size: 27px;
            line-height: 49px;
        }

        &:hover {
            color: $brew-titles;
        }
    }

    &__show-all-title {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .promo-tile {
        .promo-tile-text {
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }

        a:hover {
            .promo-tile-text {
                text-decoration: underline;
            }
        }
    }
}

// Category tiles
.category-tile {
    a {
        display: inline;
    }

    &__title {
        @include mq($screen-l) {
            margin-bottom: 2px;
        }

        .heading {
            margin-bottom: 0;
        }
    }

    &__image-wrapper {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 10px;
        overflow: hidden;
        border: $brew-standard-border-dark;
        border-radius: $brew-standard-border-radius;

        @include mq($screen-l) {
            margin-bottom: 20px;
        }

        &:hover {
            .category-tile__image {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        max-width: unset;
        min-width: 100%;
        min-height: 100%;
        transition: transform 100ms ease-in-out;

        &::after {
            content: attr(alt);
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $brew-section-midtone;
            font-size: 16px;
        }
    }

    &__child {
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: '•';
            color: #5C6166;
            margin-right: 7px;
        }
    }

    &__child-list {
        display: none;

        @include mq($screen-l) {
            display: block;
            padding-left: 0;
            list-style-type: none;
        }
    }

    &__child-link {
        color: #5C6166;
        font-size: 14px;
        line-height: 29px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }

    &__child-show-all {
        padding-left: 12px;
    }
}

.category-cms {
    grid-area: cms;
}

.page-bottom {
    grid-area: bottom;
}

.product-banner {
    background: $catalog-list__product-banner-background;
    border-radius: 3px;
    font-family: $font-family-sans-serif;
    position: relative;

    .banner-title {
        display: block;
        font-size: 24px;
        line-height: 33px;
        font-style: italic;
        font-weight: 700;
        color: white;
    }

    p {
        font-size: 20px;
        line-height: 26px;
        font-style: italic;
        font-weight: 300;
        color: white;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 14px;

            a {
                display: flex;
                align-items: center;

                span {
                    font-size: 16px;
                    line-height: 22px;
                    color: #5C6166;
                    font-weight: 600;
                }
            }
        }
    }

    &-grid {
        padding: $catalog-list__product-banner-padding-grid;
        min-height: 492px;

        .banner-title {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 40px;
        }

        ul.banner__contact-info {
            margin-bottom: 40px;

            li {
                a {
                    svg,
                    img {
                        width: 30px;
                        height: 30px;
                        margin-right: 20px;
                    }
                }
            }
        }

        .banner-logo-icon {
            position: absolute;
            width: 160px;
            height: 17px;
            right: 18px;
            bottom: 30px;
        }
    }

    &-list {
        display: block;
        padding: $catalog-list__product-banner-padding-list;

        @include mq(false, $screen-m) {
            padding: $catalog-list__product-banner-padding-list-m;
            text-align: center;
        }

        .banner-title {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 30px;
        }

        ul  {
            display: flex;

            @include mq(false, $screen-m) {
                flex-direction: column;
                margin-bottom: 10px;
            }

            li {
                margin-right: 30px;

                @include mq(false, $screen-m) {
                    margin-right: 0;
                    margin-bottom: 30px;
                }

                a {
                    @include mq(false, $screen-m) {
                        justify-content: center;
                    }

                    svg,
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 20px;

                        @include mq($screen-m, $screen-xl) {
                            display: none;
                        }
                    }
                }
            }
        }

        .banner-logo-icon {
            position: absolute;
            width: 230px;
            height: 25px;
            right: 56px;
            bottom: 37px;

            @include mq(false, $screen-m) {
                position: static;
                margin: 0 auto;
            }
        }
    }
}

.page-layout-1column {
    .catalog-list__sidebar {
        display: none;
    }
    .catalog-list {
        display: block;

        @include mq($screen-xl) {
            #products-list {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }
}


