@import 'product-view-variables';

$show-option-button_margin-top: 20px;

.product-view {
    &__wrapper {
        display: flex;
        margin: $product-view__wrapper-margin;
        max-width: $product-view__wrapper-max-width;
        padding: 0;
        justify-content: space-between;

        @include mq($screen-s) {
            padding: $product-view__conteiner-padding;
        }
    }

    &__description,
    &__details {
        position: relative;
        width: 100%;
    }

    &__description {
        order: $product-view__desc-order;
        min-width: $product-view__description-width;
    }

    &__details {
        order: $product-view__details-order;
        width: 100%;
        margin-left: $product-view__left-padding;
        max-width: $product-view__details-width;

        .link-to-grouped {
            margin-top: 30px;
            width: 100%;

            &::before {
                content: "   ";
                width: 24px;
                height: 16px;
                display: block;
                margin-right: 20px;
                background: url("../images/icons/variants.svg") no-repeat;
            }
        }
    }

    &__gallery {
        position: relative;
        margin: $product-view__gallery-margin;
        order: $product-view__gallery-order;
        width: 100%;
        padding-right: $product-view__gallery-right-padding;
    }

    &__summary-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $product-view__summary-padding;
        background-color: $product-view__base-background-color;
        border: 1px solid $product-view__base-border-color;
        border-radius: $product-view__radius-base;
        color: $product-view__base-text-color;

        &-bottom {
            position: relative;
            order: $product-view__details-order;

            .prices-list {
                margin-top: 0;
                margin-bottom: 20px;
            }

            .select {
                margin-bottom: 25px;
                margin-top: 25px;

                label {
                    font-size: $product-view__font-size-smaller;
                    line-height: $product-view__line-height-medium;
                    color: $product-view__base-text-color;
                    margin-bottom: 5px;
                }
            }

            .price-on-request-tooltip {
                font-size: 12px;
                line-height: 17px;
                margin-top: 1rem;
            }
        }
    }

    &__shipping-information {
        margin-top: 20px;
        p {
            margin-bottom: 0;
            font-size: $product-view__font-size-smaller;
            line-height: $product-view__line-height-medium;
            color: $product-view__base-text-color;
        }
    }

    &__badges {
        z-index: $product-view__badges-z-index;
        top: $product-view__badges-top;
        left: $product-view__badges-left;

        @include mq($screen-m) {
            top: $product-view__badges-top\@medium;
            left: $product-view__badges-left\@medium;
        }

        @include mq($screen-l) {
            top: $product-view__badges-top\@large;
            left: $product-view__badges-left\@large;
        }

        @include mq($screen-xl) {
            left: $product-view__badges-left\@xl;
        }
    }

    &__title {
        font-size: $product-view__font-size-huge;
        line-height: $product-view__line-height-more-huge;
        color: $product-view__base-dark-grey;
        font-weight: $product-view__semibold;
        margin-bottom: 20px;

        &.heading--page {
            margin: $product-view__title-margin;
            text-transform: $product-view__title-text-transform;
            font-size: $product-view__title-font-size;

            @include mq($screen-m) {
                font-size: $product-view__title-font-size\@medium;
            }

            @include mq($screen-l) {
                font-size: $product-view__title-font-size\@large;
            }
        }
    }

    &__sku-wrapper {
        display: flex;
        margin-bottom: 1.25rem;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1rem;
        flex-wrap: wrap;
    }

    &__sku {
        font-weight: $product-view__sku-font-weight;
        color: $product-view__sku-color;
        display: block;
        flex-grow: 1;

        .product-view__sku-label {
            font-size: 13px;
        }

        .product-view__sku-value {
            font-size: 13px;
            position: relative;
            padding-right: 13px;
        }

        .product-view__sku-value-container {
            position: relative;
        }

        .product-view__sku-value-tooltip {
            position: absolute;
            display: none;
            opacity: 0;
            background-color: white;
            border: 1px solid #acacac;
            white-space: nowrap;
            padding: 5px 10px;
            font-size: 11px;
            color: #333333;
            box-shadow: 0 3px 16px rgba(0, 0, 0, 0.19);
            transition: all 0.3s ease-in-out;
            right: -75px;
            top: 23px;
        }

        .product-view__sku-value-tooltip:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10.5px 14px 10.5px;
            border-color: transparent transparent #ACACAC transparent;
            transition: all 0.3s ease-in-out;
            right: 70px;
            top: -14px;
        }

        .product-view__sku-value-tooltip::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 13px 10px;
            border-color: transparent transparent #FFFFFF transparent;
            transition: all 0.3s ease-in-out;
            right: 70px;
            top: -12.5px;
        }

        .product-view__sku-value:hover + .product-view__sku-value-tooltip {
            display: block;
            opacity: 1;
        }
    }

    &__sku:hover {
        cursor: pointer;
    }

    .etrusted-widget__container {
        position: relative;
    }

    .etrusted-widget__link {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
    }

    &__stock {
        margin-top: 1.563rem;
        margin-bottom: 0.938rem;
        order: 1;

        p {
            font-size: $product-view__font-size-smaller;
            line-height: $product-view__line-height-medium;
            color: $product-view__base-text-color;
            margin-bottom: 0;
        }
    }

    &__rating {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: $product-view__rating-padding;
        border: $product-view__rating-border;
        border-width: $product-view__rating-border-width;
    }

    &__price {
        margin-bottom: 17px;

        .price {
            &__value {
                font-size: 35px;
                line-height: $product-view__line-height-more-very-huge;
                color: #333333;
                font-weight: 600;

                &--old {
                    display: block;
                    font-size: $product-view__font-size-medium-smaller;
                    line-height: $product-view__line-height-more-medium;
                    color: $product-view__base-text-color;
                    text-decoration: line-through;
                    font-weight: 400;
                }

                &--special {
                    color: $product-view__base-red;
                    font-size: $product-view__font-size-more-huge;
                    line-height: $product-view__line-height-more-very-huge;
                    font-weight: $product-view__semibold;
                }

                &--zero {
                    font-size: $product-view__font-size-more-huge;
                }
            }
        }

        .price-box {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            &__info-block {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                flex-wrap: nowrap;
                width: 100%;
            }
        }

        .sales-unit {
            font-size: 20px;
            color: #666666;
            padding-left: 8px;
        }

        .price-note {
            color: #666666;
            font-size: 12px;
            line-height: $product-view__line-height-medium-smaller;

            a {
                text-decoration: underline;
                color: #666666;

                &:hover {
                    text-decoration: none;
                }
            }
            &--zero {
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: bold;
                color: #333333;
            }
        }

        .bottom-price-note {
            font-size: $product-view__font-size-small;
            line-height: $product-view__line-height-medium-smaller ;
            color: $product-view__base-text-color;
            margin-bottom: 0;
            width: 100%;

            strong {
                font-weight: $product-view__semibold;
                text-decoration: line-through;
            }
        }
    }

    .price--between {
        display: $product-view__price-display--between;
        flex-direction: $product-view__price-flex-direction--between;
        line-height: $product-view__price-line-height--between;

        .price__text {
            display: $product-view__price-text-display--between;
            min-width: $product-view__price-text-min-width--between;
        }
    }

    &__short-description,
    .product.description {
        margin-bottom: 30px;

        p {
            margin: 0;
        }

        p,
        li {
            font-size: $product-view__font-size-base;
            line-height: $product-view__line-height-huge;
            color: $product-view__base-text-color;
        }

        &-heading {
            font-family: $product-view__short-description-heading-font-family;
            font-size: $product-view__short-description-heading-font-size;
            font-weight: $product-view__short-description-heading-font-weight;
            color: $product-view__short-description-heading-color;
            text-transform: $product-view__short-description-heading-text-transform;
        }

        ul {
            padding-left: 17px;
            margin-top: 0;

            li::marker {
                font-size: $product-view__font-size-smaller;
            }
        }
    }

    &__buttons-wrapper {
        width: 100%;

        &--ee {
            .product-view__extra-actions {
                display: flex;
            }

            .product-view__update-wishlist {
                order: 4;
            }

            .towishlist {
                display: none;
            }
        }

        &--alt {
            display: flex;
        }

        button {
            height: 40px;
        }
    }

    &__buttons {
        display: flex;
        width: 100%;
        align-items: flex-end;
        flex-wrap: wrap;

        .dropdown-list__label {
            &.button--icon-filled {
                &:after {
                    @include mq($screen-m) {
                        left: $product-view__dropdown-wishlist-button-after-left;
                    }
                }
            }
        }

        .paypal {
            margin: 0 $spacer--medium 0 0;
        }
    }

    &__extra-actions {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: $product-view__extra-actions-width;

        @include mq($screen-m) {
            display: flex;
            width: $product-view__extra-actions-width\@medium;
        }
    }

    &__button {
        width: $product-view__button-width;
        background-color: $product-view__button-bg;
        order: 2;

        @include mq($screen-m) {
            margin: $product-view__button-margin\@medium;
            width: $product-view__button-width\@medium;
            min-width: $product-view__button-min-size;
            min-height: $product-view__button-min-size;
        }

        .button__text {
            @include mq($screen-m) {
                display: none;
            }
        }

        &--wishlist {
            &.button--icon-filled {
                background-color: $product-view__button-bg;

                .icon {
                    fill: $color-primary;
                }

                &:after {
                    display: block;
                    background-position: 0 100%;
                }

                &:hover {
                    &:before {
                        background-color: $product-view__button-wishlist-filled-bg-color-hover;
                    }

                    &:after {
                        display: block;
                        background-image: $product-view__button-wishlist-filled-bg-image-after-hover;
                    }

                    .icon {
                        fill: $white;
                    }
                }
            }
        }
    }

    &__extra-actions {
        order: $product-view__extra-actions-order;
    }

    &__addtowishlist {
        display: none;
        width: $product-view__button-wishlist-width;
        min-height: $product-view__button-wishlist-min-height;
        min-width: $product-view__button-wishlist-min-width;
        background-color: $product-view__button-wishlist-background-color;
        order: $product-view__update-wishlist-order;

        @include mq($screen-m) {
            display: flex;
            width: $product-view__button-wishlist-width\@medium;
            margin: $product-view__button-wishlist-margin\@medium;
        }

        .button__text {
            display: none;
        }

        &--update {
            display: flex;

            .button__text {
                display: inline-block;
            }
        }
    }

    &__update-wishlist {
        width: $product-view__update-wishlist-width;
        height: $product-view__update-wishlist-height;
        margin: $product-view__update-wishlist-margin;
        order: $product-view__update-wishlist-order;

        @include mq($screen-m) {
            margin: $product-view__update-wishlist-margin\@medium;
        }
    }

    &__requisition,
    &__multiwishlist {
        width: $product-view__dropdown-wishlist-width;
        margin: $product-view__dropdown-wishlist-margin;

        @include mq($screen-m) {
            margin: $product-view__dropdown-wishlist-margin\@medium;
        }

        .button {
            width: $product-view__dropdown-wishlist-button-width;
        }

        .dropdown-list {
            &__label {
                justify-content: flex-start;

                @include mq($screen-m) {
                    justify-content: center;
                }
            }

            &__content {
                @include mq($screen-m) {
                    min-width: $product-view__dropdown-wishlist-content-min-width\@medium;
                }

                @include mq($screen-l) {
                    min-width: $product-view__dropdown-wishlist-content-min-width\@large;
                }
            }
        }
    }

    &__requisition {
        order: $product-view__requistion-dropdown-order;

        @include mq($screen-m) {
            width: $product-view__requistion-dropdown-width\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__requistion-dropdown-width\@large;
        }

        .dropdown-list {
            &__content {
                right: $product-view__requistion-dropdown-content-right;
                left: auto;
            }
        }
    }

    &__update-requisition-list {
        display: flex;
        height: $product-view__update-requisition-list-height;
        width: $product-view__update-requisition-list-width;
        margin: $product-view__update-requisition-list-margin;

        .button {
            width: $product-view__update-requisition-list-button-width;
        }
    }

    &__multiwishlist {
        order: $product-view__multiwishlist-dropdown-order;

        @include mq($screen-m) {
            width: $product-view__multiwishlist-dropdown-width\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__multiwishlist-dropdown-width\@large;
        }

        .dropdown-list {
            &__item {
                @include mq($screen-l) {
                    padding: $product-view__multiwishlist-dropdown-item-padding\@large;
                }
            }
        }
    }

    &__add-to-cart,
    &__update-cart {
        order: $product-view__order;
        flex-grow: $product-view__flex-grow;
        height: $product-view__add-to-cart-height;
        padding: $product-view__add-to-cart-padding;

        @include mq($product-view__buttons-mq-display) {
            display: $product-view__add-to-cart\@buttons-mq-display;
        }
    }

    &__select-option {
        .select__field-list--single,
        .select__field-item {
            height: $product-view__select-option-height;
            line-height: $product-view__select-option-height;
        }
    }

    &__options {
        margin: $product-view__options-margin;
    }

    &__qty {
        margin: $product-view__qty-margin;
    }

    &__stock-status {
        margin: $product-view__stock-status-margin;
        @include mq($screen-m) {
            margin: $product-view__stock-status-margin\@medium;
        }
    }

    &__brief {
        margin: $product-view__brief-margin;

        @include mq($screen-m) {
            margin: $product-view__brief-margin\@medium;
        }
        .brief-info {
            display: block;

            &__title {
                text-transform: $product-view__brief-title-text-transform;
                font-weight: $product-view__brief-title-font-weight;
                font-size: $product-view__brief-title-font-size;
            }

            &__subtitle {
                font-size: $product-view__brief-subtitle-font-size;
                text-transform: none;
            }

            &__item {
                margin: $product-view__brief-item-margin;
            }
        }
    }

    &__form {
        margin: $product-view__form-margin;

        details {
            padding: 0.75rem 1rem 0.25rem  2.5rem;
            margin-bottom: 1.5rem;
            border: 1px solid #e5e5e5;
            border-radius: 4px;

            summary {
                font-size: 18px;
                color: #666666;
                margin-left: -20px;
                padding-bottom: 0.5rem;
            }

            summary:hover {
                cursor: pointer;
            }
        }
    }

    &__summary {
        width: $product-view__summary-width;
        padding: $product-view__summary-padding;
        margin: $product-view__summary-margin;
        font-size: $font-size-medium;


        @include mq($screen-m) {
            margin: $product-view__summary-margin\@medium;
        }
    }

    &__summary-price {
        display: flex;
        align-items: center;
        margin: $product-view__summary-price-margin;
        font-weight: $product-view__summary-price-font-weight;
        .price__value {
            font-size: $product-view__summary-price-font-size;
        }
    }

    &__summary-unit {
        padding: $product-view__summary-unit-padding;
        font-size: $product-view__summary-unit-font-size;
    }

    &__summary-list {
        font-size: $product-view__summary-list-font-size;
    }

    &__summary-title {
        margin: $product-view__summary-title-margin;
        font-family: $product-view__summary-title-font-family;
        font-size: $product-view__summary-title-font-size;
        font-weight: $product-view__summary-title-font-weight;
        text-transform: $product-view__summary-title-text-transform;
    }

    &__summary-item-title {
        display: block;
        font-weight: $product-view__summary-list-item-title-font-weight;
        text-transform: $product-view__summary-list-item-title-text-transform;
    }

    &__summary-item-value {
        color: $product-view__summary-list-item-value-color;
    }

    &__customization {
        border: $product-view__customization-border;
        border-width: $product-view__customization-border-width;
        padding: $product-view__customization-padding;
        margin: $product-view__customization-margin;
    }

    // grouped product, overwrite default table styles
    &__grouped-table {
        margin: $product-view__grouped-table-margin;
        border-width: $product-view__grouped-table-border-width;

        & th,
        & td {
            border-right: $product-view__grouped-cell-border-right;
            justify-content: flex-end;

            &.product-view__grouped-product-name-cell {
                justify-content: space-between;
                @include mq($screen-m) {
                    display: table-cell;
                }
                &:before {
                    display: none;
                }
            }
        }

        & th,
        & td:before {
            padding: $product-view__grouped-th-padding;
            font-size: $product-view__grouped-th-font-size;
            font-weight: $product-view__grouped-th-font-weight;
            background-color: $product-view__grouped-th-bg-color;
            text-align: $product-view__grouped-th-align;
            @include mq($screen-m) {
                text-align: $product-view__grouped-th-align\@medium;
            }
        }

        & td {
            padding: $product-view__grouped-td-padding;
            vertical-align: $product-view__grouped-td-vertical-align;
        }
    }

    &__grouped-product-qty-td {
        padding-left: $product-view__grouped-td-qty-padding;
    }

    &__grouped-product-name {
        display: block;
        font-weight: $product-view__grouped-td-name-font-weight;
        @include mq($screen-m) {
            margin: $product-view__grouped-td-name-margin\@medium;
        }
    }

    &__grouped-qty {
        max-width: $product-view__grouped-qty-field-max-width;
        .input__field {
            text-align: $product-view__grouped-qty-field-align;
            border-color: $product-view__grouped-qty-field-border-color;
        }
    }

    // tabs
    &__tabs {
        max-width: $product-view__wrapper-max-width;
        margin: $product-view__tabs-margin;

        @include mq($screen-m) {
            margin: $product-view__tabs-margin\@medium;
            padding: $product-view__tabs-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $product-view__tabs-padding\@large;
        }

        .tab__title {
            height: $product-view__tabs-title-height;
            padding: $product-view__tabs-title-padding;
            text-align: $product-view__tabs-title-text-align;

            @include mq($screen-m) {
                padding: $product-view__tabs-title-padding\@medium;
            }

            @include mq($screen-l) {
                text-align: $product-view__tabs-title-text-align\@large;
                padding: $product-view__tabs-title-padding\@large;
            }
        }
    }

    &__tabs-sticky-wrap {
        display: none;

        @include mq($product-view__tabs-sticky-show-mq) {
            display: block;
            position: fixed;
            visibility: hidden;
            top: 0;
            width: 100vw;
            left: 50vw;
            right: auto;
            margin-left: -50vw;
            margin-right: auto;
            background-color: $product-view__tabs-sticky-bg;
            border: $product-view__tabs-sticky-border;
            border-width: $product-view__tabs-sticky-border-width;
            box-shadow: $product-view__tabs-sticky-shadow;
            z-index: $product-view__tabs-sticky-z-index;

            &.aos-animate {
                visibility: visible;
            }
        }
    }

    &__tabs-sticky {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;
        max-width: $product-view__tabs-sticky-max-width;
        margin: $product-view__tabs-sticky-margin;

        .tab__title--sticky {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $product-view__tabs-sticky-label-height;
            border-width: $product-view__tabs-sticky-label-border-width;
            text-decoration: $product-view__tabs-sticky-label-decoration;
        }
    }

    &__table {
        .table {
            td {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
    }

    // styles for related / upsells
    .slider--products {
        margin: $product-view__slider-margin;
    }

    // styles for addtocart-sticky EE version
    .add-to-cart--ee {
        .add-to-cart__wrapper {
            padding: $product-view__add-to-cart-ee-wrapper-padding;

            @include mq($screen-m) {
                padding: $product-view__add-to-cart-ee-wrapper-padding\@medium;
            }

            @include mq($screen-l) {
                padding: $product-view__add-to-cart-ee-wrapper-padding\@large;
            }
        }

        .add-to-cart__action  {
            position: absolute;
            bottom: $product-view__add-to-cart-ee-action-bottom;
            right: $product-view__add-to-cart-ee-action-right;

            @include mq($screen-m) {
                position: static;
            }
        }

        .price-box {
            display: flex;
            flex-direction: column;
            padding: $product-view__add-to-cart-ee-price-box-padding;

            @include mq($screen-m) {
                flex-direction: row;
                align-items: center;
            }

            @include mq($screen-l) {
                padding: $product-view__add-to-cart-ee-price-box-padding\@large;
            }
        }
    }

    .description-info {
        list-style-type: none;
        padding: 6px 0;
        margin: 0 0 20px 0;
        border-top: 1px solid $product-view__base-border-color;
        border-bottom: 1px solid $product-view__base-border-color;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;
        column-gap: 2rem;

        li {
            a {
                display: flex;
                align-items: center;
                color: $product-view__base-dark-grey;
                font-size: 14px;
                line-height: 16px;
            }

            svg {
                display: inline-block;
                margin-right: 6px;
                width: 24px;
                height: 24px;
            }

            img {
                display: inline-block;
                margin-right: 6px;
                width: 24px;
                height: 24px;
            }
        }

        .icon--upload {
            position: relative;
            display: inline-block;
            width: 19px;
            height: 21px;
            margin-right: 5px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
            }

            &::before {
                height: 14px;
                width: 3px;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $brew-text;
            }

            &::after {
                width: 9px;
                height: 9px;
                bottom: 3px;
                left: 50%;
                transform: translateX(-50%) rotate(-45deg);
                border-bottom: 3px solid $brew-text;
                border-left: 3px solid $brew-text;
            }
        }
    }

    .other-category-products {
        font-size: $product-view__font-size-base;
        line-height: $product-view__line-height-huge;

        span {
            font-weight: $product-view__semibold;
            color: $product-view__base-dark-grey;
            display: inline-block;
            margin-right: 10px;
        }

        a {
            text-decoration: underline;
            color: $product-view__base-text-color;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__main-actions {
        display: flex;
        justify-content: space-between;
    }

    &.container {
        max-width: $product-view__wrapper-max-width;
    }

    .fotorama__stage {
        @media all and (min-width: 1024px) {
            width: 100% !important;
        }
    }

    .product-options-wrapper {
        &.product-options {

            select {
                margin-bottom: 0;
            }
        }
    }

    &__customizable {
        &-form {
            margin-top: 25px;

            .product-view__add-to-cart {
                width: 100%;
                height: 40px;
            }
        }

        &-divider {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            margin-bottom: -($show-option-button_margin-top);
            font-size: 12px;
        }
    }
}

.product-info-details-wrapper .product-info-details-main .product-options-wrapper > .field > .label {
    font-weight: 800;
}

.catalog-product-view {
    .breadcrumbs {
        display: block;
        padding: 20px 15px;
    }

    &.page-product-grouped {
        .product-view {
            &__description {
                min-width: calc(100% - #{$product-view__grouped-gallery-width});

                @include mq(false, $screen-m) {
                    min-width: 100%;
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            &__gallery {
                padding-right: $product-view__grouped-gallery-padding;

                @include mq(false, $screen-m) {
                    padding-right: 0;
                    width: 100%;
                }
            }
        }

        .grouped-product-filter {
            font-size: $product-view__font-size-base;
            line-height: $product-view__line-height-huge-smaller-2;
            color: $product-view__base-text-color;
            margin: $product-view__grouped-filter-margin;
            max-width: $product-view__max-width\@large;
            padding: $product-view__grouped-filter-padding;

            @include mq($screen-m) {
                margin: $product-view__grouped-filter-margin-m;
                padding: $product-view__grouped-filter-padding-m;
            }

            &-wrapper {
                background-color: $product-view__base-background-color;
            }

            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: $product-view__grouped-filter-header-margin;

                span {
                    font-weight: $product-view__semibold;
                    color: $product-view__base-dark-grey;
                }

                a {
                    color: $product-view__base-blue-2;
                    text-decoration: none;
                    line-height: $product-view__line-height-huge-smaller-2;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &-body {
                margin-bottom: $product-view__grouped-filter-body-margin;

                @include mq(false, $screen-m) {
                    margin-bottom: $product-view__grouped-filter-body-margin-m;
                }

                span {
                    font-weight: $product-view__semibold;
                    display: block;
                    width: $product-view__grouped-filter-body-width;
                    padding-top: 8px;

                    @include mq(false, $screen-m) {
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: flex;
                        margin-bottom: 10px;

                        @include mq(false, $screen-m) {
                            flex-wrap: wrap;
                            margin-bottom: $product-view__grouped-filter-body-margin;
                        }
                    }
                }
            }

            &-footer {
                @include mq($screen-m) {
                    padding-left: $product-view__grouped-filter-body-width;
                }

                i {
                    font-style: normal;
                }
            }

            &-options-list {
                display: flex;
                flex-wrap: wrap;
                width: 85%;

                a {
                    display: inline-block;
                    background-color: $product-view__base-white;
                    border: 1px solid $product-view__base-light-grey-border;
                    border-radius: 4px;
                    margin: 0 10px 10px 0;
                    padding: 6px 10px;

                    &:hover {
                        text-decoration: none;
                    }

                    &.selected {
                        background: rgba($product-view__base-blue, 0.1);
                        border: 1px solid $product-view__base-blue;
                        color: $product-view__base-blue;

                        &::after {
                            content: '×';
                            font-weight: $product-view__bold;
                            margin-left: 10px;
                            font-size: large;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        border: 1px solid $product-view__base-light-grey-border;
                        color: $product-view__base-light-grey-border;
                    }
                }
            }
        }

        .grouped-product-list {
            margin: 0 auto 50px auto;
            max-width: $product-view__max-width\@large;
            padding: 0 15px;

            .product-list-item {
                margin-bottom: 16px;
            }
            .product-view__add-to-cart {
                height: 40px;
            }
            .product-grid-item__delivery-info {
                height: auto;
                min-height: 20px;
            }
        }

        .no-products-message {
            font-size: $product-view__font-size-base;
            text-align: center;
            color: $product-view__base-text-color;
            margin-bottom: $product-view__grouped-no-products-message-margin;
        }

        .bss-gpo-img {
            @extend .product-list-item__image-link;
        }
    }

    .grouped-product-list {
        .product-list-item {
            cursor: pointer;

            @include mq(false, $screen-m) {
                flex-direction: column;
            }

            .product-list-item__details {
                @include mq($screen-m) {
                    max-width: 100%;
                }
            }

            &__image-link {
                margin: 25px 14px 40px 25px;
                max-width: 120px;
                min-width: 120px;
                flex-basis: 120px;

                @include mq(false, $screen-m) {
                    margin: 0 0 5px 0;
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    padding: 30px 20px 20px;
                }

                .product-image-container {
                    width: 120px !important;

                    @include mq(false, $screen-m) {
                        width: 175px !important;
                    }

                    .product-image-wrapper {
                        padding-bottom: 0 !important;

                        img {
                            height: auto;
                        }
                    }

                    @include mq(false, $screen-m) {
                        padding: 0 !important;
                        width: auto;
                        height: auto;

                        img {
                            width: 100%;
                            max-width: 175px;
                            position: static;
                            transform: none;
                        }
                    }
                }

                .ratio-container {
                    margin-top: 0;
                    padding-bottom: 0 !important;
                    height: auto;
                    overflow: unset;

                    .ratio-image {
                        position: static;
                        transform: none;

                        @include mq(false, $screen-m) {
                            margin: 0 auto;
                        }
                    }
                }
            }

            &__badges {
                left: 0;
                margin-top: -25px;
                margin-left: -25px;
            }

            &__main {
                padding: 35px 30px 20px 20px;

                @include mq(false, $screen-m) {
                    flex-basis: 100%;
                    max-width: 100%;
                    padding: 0 15px 20px;
                }
            }

            &__name-link {
                color: $product-view__base-dark;
                font-size: $product-view__font-size-base;
                line-height: 30px;
            }

            &__actions {
                flex-direction: column;
                max-width: $product-view__grouped-actions-width;
                min-width: $product-view__grouped-actions-width;

                @include mq(false, $screen-m) {
                    max-width: 100%;
                    align-items: baseline;
                }

                .price-box {
                    margin-bottom: 12px;
                }

                .product-grid-item__delivery-info {
                    margin-bottom: 20px;
                }

                .product-list-item__add-to-action {
                    display: flex;
                    align-items: center;

                    .product-view__qty {
                        margin-right: 10px;
                    }

                    .button {
                        &--add-to {
                            overflow: unset;
                            padding: 0 8px;

                            @include mq(false, $screen-m) {
                                .button__text {
                                    display: block;
                                }

                                &:hover {
                                    color: $product-view__base-white;
                                }
                            }
                        }

                        &__text {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .product-list-item__actions-inner {
            .product-view__form {
                width: 100%;
                display: flex;
                padding-top: 0;

                .product-view__qty {
                    margin-right: 10px;

                    .quantity-update {
                        max-width: 82px;

                        &__input {
                            width: 30px;
                        }

                        &__button {
                            &--minus {
                                &:after {
                                    left: 9px;
                                }
                            }

                            &--plus {
                                &:after {
                                    left: 9px;
                                }

                                &:before {
                                    left: 12px;
                                }
                            }
                        }
                    }
                }

                .product-view__add-to-cart {
                    width: 100%;
                    height: 40px;
                }
            }
        }

        .grouped-price {
            display: flex;
            align-items: baseline;

            .price-label,
            .price__value {
                font-size: $product-view__font-size-more-huge;
                line-height: 34px;
                color: $product-view__base-dark;
                font-weight: 600;

                @include mq(false, $screen-m) {
                    font-size: $product-view__font-size-huge;
                    line-height: $product-view__line-height-huge-more;
                }
            }

            .product-list-item__tax {
                font-size: $product-view__font-size-small;
                line-height: $product-view__line-height-medium-smaller;
                color: $product-view__base-text-color;

                @include mq(false, $screen-m) {
                    font-size: $product-view__font-size-smaller;
                    line-height: $product-view__line-height-medium;
                }
            }

            .price__value {
                margin: 0 10px 0 0;

                &--special {
                    color: $product-view__base-red;
                }

                &--old {
                    font-size: $product-view__font-size-smaller;
                    line-height: $product-view__line-height-medium;
                    color: $product-view__base-text-color;
                    position: absolute;
                    top: -15px;
                    left: 0;
                    margin: 0;
                }
            }

            .price-label {
                margin-right: 5px;
            }

            &.has-special {
                position: relative;
                margin-top: 15px;

                .price-label {
                    color: $product-view__base-red;
                }

                .price {
                    &__value {
                        &:not(.price__value--old) {
                            color: $product-view__base-red;
                        }
                    }
                }
            }
        }
    }

    .button {
        &--add-to {
            &:after {
                content: url("../images/icons/icon_shopping-cart.svg");
                height: 24px;
                width: 24px;
                filter: brightness(0) invert(1);
            }
        }
        &--add-to.negotiable {
            &:after {
                content: url("../images/icons/inquiry-list-icon.svg");
                height: 34px;
            }
        }
    }
}

.download-link {
    font-size: 14px;
    line-height: 16px;
    color: $product-view__base-text-color;
    display: flex;
    align-items: center;
    margin: 8px 0;

    .icon--download {
        width: 22.5px;
        height: 22.5px;
        margin-right: 10px;
        filter: brightness(0) invert(0.4);
    }

    strong {
        display: inline-block;
        margin-right: 15px;
        font-weight: $product-view__semibold;
    }
}

.show-more {
    margin-top: -$product-view__show-more-margin;

    a {
        font-size: $product-view__font-size-base;
        line-height: $product-view__line-height-huge-more;
        font-weight: $product-view__semibold;
        color: $product-view__base-text-color;
        align-items: center;
        margin: 16px 0;
    }

    .icon-arrow-select {
        display: block;
        width: $product-view__show-more-arrow-size;
        height: $product-view__show-more-arrow-size;
        border-right: 2px solid $product-view__base-text-color;
        border-bottom: 2px solid $product-view__base-text-color;
        margin-left: 10px;
        transform: rotate(45deg);
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: $product-view__show-more-margin;
        background: transparent linear-gradient(180deg, rgba($product-view__base-white, 0) 0%, rgba($product-view__base-white, 0.4) 20%, $product-view__base-white 100%) 0% 0% no-repeat padding-box;
        position: relative;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    &[data-show="1"] {
        .icon-arrow-select {
            transform: rotate(-135deg);
            margin-top: 5px;
        }

        &:before {
            opacity: 0;
        }
    }
}

.product-info-options-details-wrapper {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 60px;

    &.show-details {
        display: flex;
    }

    .heading {
        width: 100%;
    }

    .product-info-options-form {
        width: 545px;
        padding: 0 40px 20px 15px;

        form {
            padding-top: 0;
            margin-top: 0;

            div.required > label {
                &:not(.checkbox__label) {
                    &::after {
                        display: none;
                    }

                    &::before {
                        content: '*';
                        white-space: nowrap;
                    }
                }
            }

            label {
                color: #707070;
            }

            textarea {
                min-height: 100px;
            }

            select {
                background-color: #f3f3f3;
                color: $brew-text;
            }

            .product-options__price {
                white-space: nowrap;
            }

            .product-options-wrapper > .field {
                margin-bottom: 2rem;
            }
        }

        .field.file {
            .control {
                label {
                    background: #F3F3F3;
                    border: 1px solid #E5E5E5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    cursor: pointer;

                    svg {
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        margin-right: 18px;
                    }

                    span {
                        font-size: 16px;
                        color: $brew-text;
                    }
                }
            }
            input[type="file"] {
                display: block;
                height: 0;
                width: 0;
                overflow: hidden;
            }
        }

        .file-names {
            font-size: 14px;
            line-height: 20px;
            color: $brew-text;

        }

        .product-view__main-actions {
            margin-top: 3rem;

            .product-view__buttons-wrapper {
                width: 100%;
            }
        }
    }

    .product-info-options-note {
        width: calc(100% - 545px);

        i {
            background-color: #C2C2C2;
            margin-right: 5px;
            transform: translateY(2px);
        }

        strong {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $brew-text;
        }

        p {
            margin-bottom: 18px;
            font-size: 14px;
            line-height: 20px;
            color: $brew-text;
        }

        ul {
            padding-left: 18px;
            color: $brew-text;
        }

        [data-content-type=row][data-appearance=contained] {
            margin-top: 20px;
        }
    }

    .product-info-options-note-select-helper {
        position: absolute;

        .pagebuilder-column {
            padding: 20px;
            border: 1px solid #dddddd;
            box-sizing: content-box;
            box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);
            position: relative;
            width: auto !important;
            background-color: white;
        }

        .pagebuilder-column:before {
            content: "";
            position: absolute;
            top: 28px;
            left: 0;
            margin-left: -15px;
            width: 0;
            border-right: 15px solid #cecece;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
        }

        .pagebuilder-column:after {
            content: "";
            position: absolute;
            top: 28px;
            left: 0;
            margin-left: -14px;
            width: 0;
            border-right: 15px solid white;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
        }

        .brewes_tile--container {
            width: 150px;
            padding-bottom: 150px;
        }

        .brewes_tile--filler {
            display: none;
        }
        .brewes_tile--image {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

.show-option-button {
    margin-top: $show-option-button_margin-top;

    .button {
        background-color: $product-view__base-background-color-individual;
        color: $product-view__base-dark-grey;
        font-size: 1rem;

        .button__icon {
            filter: brightness(0.2);
        }
    }

    a {
        color: $product-view__base-white;

        span {
            order: 1;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }
}

.shipping-info-icon {
    display: inline-block;
    background: $product-view__base-blue;
    width: $product-view__shipping-info-icon-size;
    height: $product-view__shipping-info-icon-size;
    border-radius: 50%;
    position: relative;
    transform: translateY(3px);
    cursor: pointer;

    &::after,
    &::before {
        content: '';
        background-color: $product-view__base-white;
        display: block;
        width: 2px;
        position: absolute;
        left: 50%;
        margin-left: -1px;
    }

    &::after {
        height: 5px;
        top: 6px;
    }

    &::before {
        height: 2px;
        top: 3px;
    }
}

.shipping-info {
    position: relative;

    .shipping-info-tooltip-text {
        color: $product-view__base-dark-grey;
        font-size: $product-view__font-size-smaller;
        line-height: $product-view__line-height-huge-smaller;
        position: absolute;
        right: -80px;
        top: 28px;
        z-index: 1;
        background-color: $product-view__base-white;
        padding: $product-view__shipping-info-tooltip-padding;
        border: 1px solid $product-view__base-grey;
        box-shadow: 0 3px 16px rgba($product-view__base-black, 0.3);
        min-width: $product-view__shipping-info-min-width;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            top: -13px;
            right: $product-view__shipping-info-tooltip-arrow-right;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 13px solid $product-view__base-grey;
        }

        &:after {
            content: "";
            position: absolute;
            top: -11.5px;
            right: $product-view__shipping-info-tooltip-arrow-right;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 13px solid $product-view__base-white;
        }
    }

    &:hover {
        .shipping-info-tooltip-text {
            pointer-events: all;
            opacity: 1;
        }
    }
}

.product-view__summary-block-bottom {
    &::before {
        content: '';
        display: block;
        background-color: $product-view__base-border-color;
        width: calc(100% + 28px);
        height: 1px;
        position: initial;
        top: $product-view__summary-padding-top;
        margin-left: -14px;
        margin-bottom: 0.938rem;
    }
}

.product-view__negotiable-note {
    line-height: 1.35;
    font-size: 13px;
    margin-top: 2rem;

    .shipping-info-icon {
        cursor: default;
    }
}

.prices-list {
    margin-top: $product-view__summary-margin-top;
    dl {
        color: $product-view__base-text-color;
        font-size: $product-view__font-size-medium-smaller;
        line-height: $product-view__line-height-more-medium;
        display: flex;
        justify-content: space-between;
        align-items: center;

        dt {
            padding: 4px 3px 5px;
        }

        dd {
            text-align: right;
            padding: 4px 19px 5px;
        }

        &:nth-child(2n) {
            background-color: $product-view__base-border-color;
        }
    }
}

.catalog-product-view {
    .product-view {
        &__buttons-wrapper {
            &--ee {
                .product-view__update-wishlist {
                    display: none;
                }

                .dropdown-list__icon {
                    margin: 0;
                }
            }
        }
    }

    &.wishlist-index-configure {
        .product-view {
            &__buttons-wrapper {
                &--ee {
                    .product-view__update-wishlist {
                        display: flex;
                    }
                }
            }
        }
    }

    .grecaptcha-badge {
        bottom: $product-view__grecaptcha-badge-bottom !important;

        @include mq($screen-m) {
            bottom: $product-view__grecaptcha-badge-bottom\@medium !important;
        }
    }

    .product-info-details-wrapper {
        width: 100%;
        max-width: $product-view__wrapper-max-width;
        margin: $product-view__details-margin;
        padding: $product-view__details-padding;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            margin: $product-view__conteiner-padding;
            position: absolute;
            left: 0;
            top: 0;
            border-top: 1px solid $product-view__border;
        }

        .product-info-details-main {
            width: 100%;
            padding-right: $product-view__right-padding;

            h3 {
                &.heading {
                    margin-bottom: 40px;
                }
            }

            .product.data.items {
                .data.item.content {
                    margin-bottom: 60px;
                }
            }

            .table {
                @include mq(false, $screen-m) {
                    tr {
                        display: table-row;
                        border-bottom: none;
                    }

                    th {
                        display: table-cell;
                        border-right: 2px solid $product-view__table-th-border-color;
                        vertical-align: top;

                        &[scope="row"] + td:before {
                            display: none;
                        }
                    }
                }

                td {
                    display: table-cell;
                    vertical-align: middle;

                    @include mq($screen-m) {
                        width: $product-view__description-width;
                    }
                }
            }
        }

        .product-info-details-sidebar {
            max-width: $product-view__details-width;
            padding-top: $product-view__top-padding;

            .product-contact-information-bubble {
                padding: $product-view__information-bubble-padding;
                margin-bottom: $product-view__information-bubble-margin-bottom;
                background: $product-view__section-midtone;
                border: 1px solid $product-view__border;
                box-shadow: 0 3px 16px $product-view__border;
                position: relative;
                border-radius: 3px;
                min-width: 352px;

                .contact-bubble {
                    & > p {
                        margin-bottom: 35px;
                    }

                    ul {
                        li {
                            .contact-page-icons {
                            }

                            &:last-child {
                                margin-bottom: 8px;
                                margin-top: -22px;
                            }
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 20px;
                    left: 0;
                    margin-left: -22px;
                    width: 0;
                    border-right: 22px solid $product-view__border;
                    border-top: 22px solid transparent;
                    border-bottom: 22px solid transparent;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 22px;
                    left: 0;
                    margin-left: -20px;
                    width: 0;
                    border-right: 20px solid $product-view__section-midtone;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                }
            }

            .product-banner {
                padding: $product-view__product-banner-padding;
                margin: $product-view__product-banner-margin-bottom;
                .banner-title {
                    margin-bottom: $product-view__product-banner-margin-titel-bottom;
                }

                .banner-logo-icon {
                    position: absolute;
                    width: 160px;
                    height: 17px;
                    right: 18px;
                    bottom: 24px;
                }
            }
        }
    }
}

@include mq(false, $screen-xxl) {
    .product-view {
        &__gallery {
            padding-right: 0;
            min-width: 300px;
            width: auto;

            .gallery {
                max-width: $product-view__details-width;
                margin: 0 auto;
            }
        }

        &__description {
            width: 100%;
            min-width: auto;
            padding-left: 40px;
        }

        &__details {
            margin-left: 50px;
            min-width: $product-view__details-width;
        }
    }

    .catalog-product-view {
        &.page-product-grouped {
            .product-view {
                &__wrapper {
                    flex-wrap: nowrap;
                }
            }
        }
    }
}

@include mq(false, $screen-xl) {
    .product-view {
        &__wrapper {
            flex-wrap: wrap;
        }

        &__description {
            min-width: auto;
        }

        &__details {
            margin-left: 25px;
            max-width: none;
            width: calc(30% - 25px);
        }

        &__gallery {
            margin: 0 auto 16px;
        }

        &.container {
            margin: 0;
        }
    }

    .product-info-options-details-wrapper {
        .product-info-options-form {
            width: 400px;
        }

        .product-info-options-note {
            width: calc(100% - 400px);
        }
    }

    .catalog-product-view {
        .product-info-details-wrapper {
            flex-wrap: wrap;
            margin-top: $product-view__details-mobile-margin-top;
            padding-top: $product-view__details-mobile-padding-top;

            .product-info-details-main {
                padding-right: 0;
            }

            .product-info-details-sidebar {
                max-width: none;
                width: 100%;
                padding-top: $product-view__details-mobile-padding-top;

                .product-contact-information-bubble {
                    &:after,
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .product-view {
            &__description {
                width: 100%;
                margin-bottom: 58px;
                padding-left: 0;
            }

            &__details {
                margin-left: 0;
                width: 100%;
            }

            &__brief {
                display: none;
            }
        }

        &.page-product-grouped {
            .product-view {
                &__wrapper {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

@include mq(false, $screen-m) {
    .catalog-product-view {
        .description-info,
        .other-category-products {
            display: none;
        }

        .product-view {
            &__title {
                font-size: $product-view__font-size-more-base;
                margin-bottom: $product-view__details-mobile-margin-top;
            }

            &__short-description {
                margin-bottom: 0;
            }


        }

        .product-info-details-wrapper {
            .product-info-details-main {
                h3 {
                    font-size: $product-view__font-size-more-base;
                    line-height: $product-view__line-height-more-medium-2;
                    margin-bottom: 30px;
                }

                .product.data.items {
                    .data.item.content {
                        margin-bottom: $product-view__details-mobile-margin-top;
                    }
                }
            }

            .product-info-details-sidebar {
                .product-contact-information-bubble {
                    margin-bottom: $product-view__details-mobile-margin-top;
                }

                .product-banner {
                    .banner-title {
                        font-size: $product-view__font-size-more-huge;
                    }

                    p {
                        font-size: 23px;
                    }
                }
            }
        }

        .download-link {
            flex-wrap: wrap;

            span {
                display: inline-block;
                margin-top: 5px;
            }
        }

        .product-info-options-details-wrapper {
            margin-bottom: 0;

            .product-info-options-form,
            .product-info-options-note {
                width: 100%;
                padding: 0 0 20px 0;

                .product-view__main-actions {
                    margin-top: 30px;
                }
            }
        }
    }

    .product-view {
        &__gallery {
            .gallery {
                .fotorama__wrapper {
                    margin: 0 auto;
                }
            }
        }
    }

    .product-info-options-note-select-helper {
        right: 5px;
    }
}

@include mq(false, $screen-s) {
    .product-info-options-note-select-helper {
        display: none !important;
    }
}

.page-product-grouped.catalog-product-view .product-info-details-wrapper {
    margin-top: 10px;
    padding-top: 35px;

    @include mq(false, $screen-xl) {
        margin-top: 10px;
        padding-top: 20px;
    }

    &:before {
        display: none;
    }

    .product-info-details-main {
        .product.data.items .data.item.content {
            margin-bottom: 30px;

            &.long-description {
                max-height: 125px;
                overflow: hidden;
                position: relative;

                &:after {
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    background: $brew-text--light;
                    height: 42px;
                    width: 100%;
                    bottom: 0;
                    opacity: 0.6;
                }

                &:before {
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    background: $brew-text--light;
                    height: 22px;
                    width: 100%;
                    bottom: 0;
                    opacity: 0.3;
                }
            }
        }

        .read-more {
            color: $product-view__base-text-color;
            font-size: 16px;
            position: relative;

            &::after {
                content: ' ';
                position: absolute;
                background-color: $product-view__base-text-color;
                -webkit-mask-image: url('../images/fotorama/angle-down.svg');
                mask-image: url('../images/fotorama/angle-down.svg');
                width: 24px;
                height: 24px;
            }
        }
    }
}

@include mq($screen-l) {
    ul#grouped-products-list {
        margin-bottom: 45px;
    }
}

.product-banner + .product-special-banner {
    margin-top: -18px;
}

.product-special-banner {
    margin-bottom: 50px;
    position: relative;

    .brewes-gradient {
        background: transparent radial-gradient(closest-side at 50% 76%, #E9EAED 0%, #B1B8C2 100%) 0% 0% no-repeat padding-box;
    }
}
