.brewes-contacts-block {

    .pagebuilder-column-line {
        justify-content: space-between;

        .pagebuilder-column {
            align-self: center !important;
            width: auto !important;
        }
    }

    .contacts-text {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
    }

    .contacts-phone {

        &--container {
            display: flex;
            flex-direction: row;
        }

        &--icon {
            padding-right: 48px;

            svg {
                width: 60px;
                height: 60px;
            }
        }

        &--number {
            font-size: 20px;
            font-weight: 600;
            white-space: nowrap;
            line-height: 1.3;

            a {
                text-decoration: none;
                border-bottom: 1px solid rgba(51, 51, 51, 0.5);
                margin-bottom: 15px;
            }
        }

        &--time {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    .contacts-mail {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid rgba(51, 51, 51, 0.5);

        &--container {

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
            }

            a:hover {
                .contacts-mail {
                    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
                }
            }
        }

        &--icon {
            padding-right: 45px;

            svg {
                width: 74px;
                height: 54px;
            }
        }
    }
}

table.pagebuilder_category_description_table {
    width: 100%;
    border-color: #bbbbbb;

    th,
    td {
        padding: 5px 10px;
    }

    thead {
        tr {
            background-color: #bbbbbb;
            font-weight: 700;

            td {
                color: #333333;
                border-color: #919191;
            }

            &:has(+ tr) {
                background-color: white;

                td {
                    border-color: #bbbbbb;
                    text-align: center;

                    img,
                    picture {
                        margin: auto;
                        max-width: unset;
                    }
                }
            }

            &:not(:first-child) {
                td:not(:first-child) {
                    text-align: center;
                }
            }
        }
    }

    tbody {
        tr {
            background-color: white;

            &:nth-child(even) {
                background-color: #eeeeee;
            }

            th {
                text-align: start;
            }

            td {
                color: #666666;
            }
        }
    }

    thead:has(> tr:not(:first-child)) + tbody {
        tr td {
            text-align: center;
        }
    }
}

div.pagebuilder_scrollable_table {
    display: block;
    overflow-x: auto;
}
