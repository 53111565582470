$newsletter__margin                           : 0;
$newsletter__padding                          : 39px 15px 72px;
$newsletter__padding\@medium                  : 40px 15px 27px;

$newsletter__heading-padding                  : $spacer 0;

$newsletter__title-margin                     : 0 0 $spacer--medium;
$newsletter__title-font-family                : $font-family-base;
$newsletter__title-font-size                  : $font-size-large;
$newsletter__title-font-weight                : $font-weight-bold;
$newsletter__title-line-height                : 48px;
$newsletter__title-color                      : $color-primary;

$newsletter__subtitle-font-family             : $font-family-base;
$newsletter__subtitle-color                   : $color-secondary;

$newsletter__controls-margin                  : 0 auto;

$newsletter__button-padding                   : 0 $spacer--medium;
$newsletter__button-min-width                 : 340px;

$newsletter__checkbox-label-padding           : $checkbox__label-padding;
$newsletter__checkbox-label-color             : $color-secondary;
$newsletter__checkbox-label-link-color        : $color-primary;
$newsletter__checkbox-label-link-color--hover : $color-focus;
$newsletter__checkbox-label-font-family       : $font-family-base;
$newsletter__checkbox-label-font-size         : $font-size-small;
$newsletter__checkbox-label-line-height       : 1.33;

$newsletter__checkbox-before-background       : $color-primary;

$newsletter__checkbox-icon-fill               : $white;

$newsletter__checkbox-text-color              : $gray-dark;

$newsletter__agreements-margin                : 0 0 0 $spacer;
$newsletter__agreements-max-width             : 450px;
