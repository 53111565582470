$slider__margin                               : 0;
$slider__width                                : 100%;
$slider__min-height                           : 440px;
$slider__recently-viewed-min-height           : 360px;
$slider__item-min-width                       : 220px;

$slider__heading-justify-content              : left;
$slider__heading-margin                       : 50px 0 46px;

//slider products
$slider__heading-margin--products             : 0 0 $spacer--extra-large;
$slider__item-padding--products               : 5px 15px 20px;
//vars used to avaiod jumping content before js is loaded
$slider__hidden-item-number--products         : 3;
$slider__hidden-item-number\@medium--products : 4;
$slider__hidden-item-number\@large--products  : 5;

//slider brands
$slider__item-width--brands                   : 96px;
$slider__item-to-show--brands                 : 3;
$slider__item-width\@medium--brands           : 144px;
$slider__item-to-show\@medium--brands         : 4;
$slider__item-width\@large--brands            : 144px;
$slider__item-to-show\@large--brands          : 6;
$slider__item-width\@max-width--brands        : 208px;
$slider__item-to-show\@max-width--brands      : 6;

$slider__handler-max-width--brands            : $slider__item-width--brands * $slider__item-to-show--brands;
$slider__handler-max-width\@medium--brands    : $slider__item-width\@medium--brands * $slider__item-to-show\@medium--brands;
$slider__handler-max-width\@large--brands     : $slider__item-width\@large--brands * $slider__item-to-show\@large--brands;
$slider__handler-max-width\@max-width--brands : $slider__item-width\@max-width--brands * $slider__item-to-show\@max-width--brands;

//slider catalog events
$slider__max-width--catalog-events            : $max-content-width;
$slider__margin--catalog-events               : 0 auto;

//navigation
$slider__navigation-display                   : none;
$slider__navigation-display-breakpoint        : $screen-s;
$slider__navigation-width                     : 100%;
$slider__navigation-top                       : 50%;
$slider__navigation-IE-width                  : calc(50% - #{$slider__navigation-top});

$slider__prev-left                            : -100px;
$slider__next-right                           : -100px;
$slider__nav-button-border-radius             : 5px;
$slider__nav-button-size                      : 80px;
$slider__nav-icon-size                        : 70px;

//dots
$slider__dots-bottom                          : 0;
$slider__dots-margin                          : 30px 10px 0 0;
$slider__dots-padding                         : 0;
$slider__dot-size                             : 10px;
$slider__dot-background-color                 : $white;
$slider__dot-background-color--active         : #5C6166;

$slider__link-font-weight                     : $font-weight-bold;
$slider__link-min-height                      : 48px;
$slider__link-text-align                      : left;
$slider__link-font-size                       : $font-size-base;

$slider__after-padding                        : $spacer--medium 0;
$slider__after-text-align                     : center;

//breakpoint overflow items styles
$slider__container-before-bg                  : linear-gradient(to left, rgba($white, 0), $white 50%);
$slider__container-after-bg                   : linear-gradient(to right, rgba($white, 0), $white 50%);

// picture image in slider
$slider__picture-lazyload-min-height          : initial;
$slider__picture-lazyload-bg-image            : none;
