//Brewes Colors
$brew-dark-grey              : #333333;
$brew-titles                 : $brew-dark-grey;
$brew-text                   : #666666;
$brew-gray--darken           : #cdcdcd;
$brew-text--light            : #FFFFFF;
$brew-text--light-grey       : #e5e5e5;
$brew-text--light-grey2      : #D1D1D1;
$brew-text--accent           : #3683C5;
$brew-footer                 : #404040;
$brew-about-brewes           : #FF0000;
$brew-red-brewes             : #E3000F;
$brew-purple-brewes          : #800080;
$brew-section-dark           : #EEEEEE;
$brew-section-midtone        : #F3F3F3;
$brew-section-bright         : #FFFFFF;
$brew-accent                 : #367FBE;
$brew-accent--darker         : #2e6fa7;
$brew-accent--darker2        : #3073AC;
$brew-accent--lighter        : #EBF2F9;
$brew-border                 : #E5E5E5;
$brew-border-dark            : #D9D9D9;
$brew-border-gray            : #ACACAC;
$brew-green                  : #0CAA21;
$brew-icon-gray              : #A8A8A8;
$brew-yellow                 : #F1C65C;

// Layout
$max-content-width           : 1450px; // Brewes max content width
$max-content-width--full     : 1920px; // Brewes max page width
$container-max-width         : 1471px;

// Layout - breakpoints
$screen-xs                   : 320px;
$screen-s                    : 480px;
$screen-m                    : 768px;
$screen-l                    : 992px;
$screen-xl                   : 1200px;
$screen-xl-plus              : 1300px;
$screen-xxl                  : 1600px;

// Spacers
$spacer                      : 5px;

// Overwriting some general colors variables
$color-warning               : $brew-red-brewes;
$color-error                 : $brew-red-brewes;
$color-focus                 : $brew-text--accent;
$border-color-base           : $brew-border;
$border-color-secondary      : $brew-border-gray;
$color-primary               : $brew-dark-grey;
$color-secondary             : $brew-text;
$font-color-base             : $color-primary;

$brew-standard-border        : 1px solid $brew-border;
$brew-standard-border-dark   : 1px solid $brew-border-dark;
$brew-standard-border-radius : 4px;

// Button
$button-font-size-large      : 1rem;
