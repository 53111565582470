// ----------------------------------------------------
// @author    Alex Mischarin <Alex.Mischarin@brewes.de>
// @copyright 2023 Brewes GmbH
// @link      https://www.brewes.de/
// ----------------------------------------------------
// CSS for Custom Contact Multicolumn Forms
// ----------------------------------------------------

.custom-mc-form {
   margin-bottom: 78px;

   .pccform {
      padding-right: 0;

      .form-group.input-text {
         width: 100%;
      }

      .form-group.upload-field .fs-upload-target {

         .dz-message {
            text-align: center;
            padding: 0 25px;
            background-color: #367FBE;
            border: none;
            border-radius: 4px;
            outline: none;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            color: #FFFFFF;
            cursor: pointer;
            text-transform: unset;
            text-decoration: none;
            transform: perspective(1px) translateZ(0);
            transition: all 0.5s ease-in-out;
            margin-top: 0;
            margin-bottom: 15px;
            max-width: 320px;
         }

         .dz-message:before {
            filter: brightness(100);
         }

         .dz-preview {
            background-color: #f5f5f5;
            margin-bottom: 10px;
            border-radius: 4px;
            padding: 5px 0;

            .dz-details {

               .dz-size {
                  font-size: 14px;
                  color: #5C6166;
                  margin-bottom: 0;
               }

               .dz-filename {
                  font-size: 14px;
                  color: #5C6166;
               }
            }

            .dz-remove {
               font-style: italic;
               text-transform: uppercase;
               font-weight: bold;
               padding-right: 10px;
            }
         }
      }

      .form-group.upload-field.upload {
         margin-top: 47px !important;
         flex-direction: column;

         .fs-upload-target {
            margin-top: 0;
         }
      }

      .form-group.upload-field.upload > div {
         width: 100%;
      }
   }
   
   .heading--arrow-red,
   .heading--arrow-gray,
   .infotext {
      flex-direction: row;
      flex-wrap: nowrap;

      .col-md-2 {
         display: none;
      }

      .col-md-8 {
         flex-basis: unset;
         max-width: unset;
         width: 100%;
      }

      .col-md-8.text-center {
         text-align: left;
      }
   }

   .infotext {

      p {
         text-align: left;
         color: #5C6166;
         font-style: italic;
      }
   }

   .gray {

      select,
      input,
      textarea {
         background-color: #F3F3F3 !important;
         border-color: #d4d4d4 !important;
         color: #6f6f6f !important;
      }
   }

   .select--native {

      .col-md-6 {
         flex-basis: unset;
         max-width: unset;
         padding-left: 0;
         padding-right: 0;
      }
   }

   .text-input--native {

      input[type="text"] {
         width: 100%;
         height: 40px;
         padding: 0 16px;
         border: 1px solid #e5e5e5;
         border-radius: 3px;
         line-height: normal;
         font-size: 16px;
         font-family: "Open Sans", sans-serif;
         color: #666;
      }
   }

   .textarea-input--native {

      textarea {
         display: block;
         min-width: 100%;
         max-width: 100%;
         min-height: 210px;
         padding: 8px 16px;
         border-radius: 3px;
         line-height: 1.6;
         font-size: 16px;
         margin: 0;
      }
   }

   .show-more-form {
      transition: all 0.3s ease;
      margin-bottom: 4px;
      padding-top: 13px;

      h3 {
         font-style: italic;
         text-decoration: underline;
         transition: all 0.3s ease;
      }
   }

   .show-more-form:hover {
      cursor: pointer;
      margin-left: 10px;

      h3 {
         color: #666666;
      }
   }

   .annotation {
      padding-left: 32px;

      p {
         font-size: 14px;
      }

      &--first {
         margin-top: 30px;
      }

      &--last {
         margin-top: 14px;
         margin-bottom: 14px;
      }
   }

   .mobile {
      display: none;
   }
}

.custom-mc-form.contact-2-column-form {

   fieldset {
      flex-direction: row;
      flex-wrap: nowrap;
      column-gap: 58px;
   }

   .right-panel,
   .left-panel {
      width: calc(50% - 29px) !important;
   }

   .right-panel {
      padding-top: 106px;
      display: none;
   }
}

@media screen and (max-width: 1200px) {
   .custom-mc-form {

      .mobile {
         display: flex;
      }

      .desktop {
         display: none;
      }

      .annotation.desktop,
      .button-field.desktop {
         display: flex;
      }

      .annotation.hideIfMobile,
      .button-field.hideIfMobile {
         display: none;
      }
   }

   .custom-mc-form.contact-2-column-form {

      fieldset {
         flex-direction: column;
      }
   
      .right-panel,
      .left-panel {
         width: 100% !important;
      }
   
      .right-panel {
         padding-top: 0;
      }
   }
}