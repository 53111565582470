$quote-table-totals-wrap-padding        : 1rem 1rem !default;
$quote-table-totals-wrap-padding\@medium: 1rem 1rem !default;
$quote-table-totals-wrap-padding\@large : 1rem 1rem !default;
$quote-table-totals-wrap-padding\@xl    : 1rem 1rem !default;
$quote-table-totals-wrap-bg             : $gray-lighter !default;
$quote-table-cell-width                 : 65% !default;

.quote-table-totals-wrap {
    padding: $quote-table-totals-wrap-padding;
    background: $quote-table-totals-wrap-bg;

    @include mq($screen-m) {
        padding: $quote-table-totals-wrap-padding\@medium;
    }

    @include mq($screen-l) {
        padding: $quote-table-totals-wrap-padding\@large;
    }

    @include mq($screen-xl) {
        padding: $quote-table-totals-wrap-padding\@xl;
    }

    table {
        width: 100%;
        background: transparent;
        border: 0;

        @include mq($screen-l) {
            width: 80%;
            margin-left: auto;
        }

        tr {
            padding: 0;
            border: 0;
        }

        td,
        th,
        th[scope="row"] {
            background: transparent;
            border: 0;
            font-size: 16px;
        }

        th,
        th[scope="row"] {
            @include mq($screen-m) {
                width: $quote-table-cell-width;
            }
        }

        th[scope="row"] + td {
            flex-flow: row nowrap;
        }

        td {
            @include mq($screen-m) {
                text-align: end;
            }
        }

        tfoot {
            td {
                display: flex;

                @include mq($screen-m) {
                    display: table-cell;
                }
            }
        }
    }

    .catalog_price_table {
        > td {
            padding: 0;
            border-bottom: $table__border;
            flex-direction: column;

            &:before {
                display: none;
            }
        }
    }
}
