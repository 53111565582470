// Cart grid
$cart-grid__header-border-bottom          : 1px solid $gray-light;
$cart-grid__header-label-padding          : $spacer--medium 0;
$cart-grid__footer-justify-content\@medium: space-between;
$cart-grid__footer-flex-direction\@medium : row-reverse;
$cart-grid__header-color                  : $brew-text;
$cart-grid__header-background-color       : $brew-section-bright;

// Cart discount
$cart-discount__margin                    : $spacer--medium 0;
$cart-discount__padding                   : 0;
$cart-discount__button-icon-transition    : $transition-base;
$cart-discount__button-padding            : $spacer--medium 0;
$cart-discount__button-border             : $border-base;
$cart-discount__button-border-width       : 0 0 1px 0;
$cart-discount__button-text-color         : $color-primary;
$cart-discount__button-text-margin        : 0 $spacer--medium 0 0;
$cart-discount__button-text-font-size     : $font-size-large;
$cart-discount__button-text-font-weight   : $font-weight-base;
$cart-discount__button-icon-transform     : rotate(180deg);
$cart-discount__button-min-width          : 150px;
$cart-discount__button-background         : none;
$cart-discount__button-background--hover  : none;
$cart-discount__button-font-family        : $font-family-base;
$cart-discount__controls-margin-bottom    : $spacer--medium;
$cart-discount__controls-padding          : $spacer--semi-medium 0;
$cart-discount__input-margin              : 0;
$cart-discount__button-text-transform     : uppercase;
