$file-upload__margin                                  : $spacer--medium;
$file-upload__font-size                               : $font-size-base;
$file-upload__button-padding                          : 0 $spacer--large;
$file-upload__label-margin--native                    : 0;

$file-upload__attachments-label-margin                : 0 $spacer 0 0;
$file-upload__attachments-button-padding              : 0;
$file-upload__attachments-button-background           : none;

$file-upload__attachments-button-content              : url("../images/icons/delete.svg");
$file-upload__attachments-button-icon-left            : calc(50% - 12px);
$file-upload__attachments-button-icon-top             : calc(50% - 12px);
$file-upload__attachments-button-icon-width           : 24px;
$file-upload__attachments-button-icon-height          : 24px;
$file-upload__attachments-button-icon-padding         : 0;
$file-upload__attachments-button-icon-opacity         : 1;
$file-upload__attachments-button-icon-transform       : translateY(0);
$file-upload__attachments-button-icon-transform--hover: rotate(180deg);
