@import "cart-variables";

.cart {
    &__summary {
        order: 1;
    }

    &__items {
        @include mq($screen-l) {
            margin-bottom: 0;
        }

        @include mq(false, $screen-l) {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 40px;
        }
    }

    &.row {
        margin-top: -44px;
        margin-bottom: 70px;

        @include mq($screen-m) {
            margin-top: 0;
        }
    }
}
