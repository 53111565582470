@import 'product-list-item-variables';

.product-list-item {
    position: relative;
    display: flex;
    align-items: stretch;
    border: $brew-standard-border;
    margin-bottom: 30px;
    border-radius: 3px;

    &:hover {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        transition: box-shadow 125ms;
    }

    &__image {
        transition: $product-list-item__transition;

        .image {
            transition: $product-list-item__transition;
        }
    }

    &__card-link{
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: inherit;
        }

        .container{
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    &__image-link {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: $product-list-item__image-width;
        text-decoration: $product-list-item__image-link-decoration;

        @include mq($screen-m) {
            flex-basis: $product-list-item__image-width\@medium;
            min-width: $product-list-item__image-width\@medium;
            margin: $product-list-item__image-margin;

            .ratio-container {
                padding-bottom: 70% !important;
                margin-top: 30px;
            }
        }

        @include mq($screen-xl) {
            margin: $product-list-item__image-margin\@xl;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            transition: $product-list-item__transition;
        }
    }

    &__main {

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: $product-list-item__main-width;
        max-width: $product-list-item__main-width;
        padding: $product-list-item__padding;

        @include mq($screen-m) {
            display: flex;
            flex-basis: $product-list-item__main-width;
        }
    }

    &__details {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include mq($screen-m) {
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 580px;
            padding-right: 70px;
        }
    }

    &__name {
        margin: $product-list-item__name-margin;
        font-size: $product-list-item__name-font-size;
    }

    &__name-link, p > &__name-link {
        display: block;
        text-decoration: $product-list-item__name-decoration;
        text-transform: $product-list-item__name-text-transform;
        font-size: $product-list-item__name-font-size;
        font-weight: $font-weight-semibold;
        &:hover {
            color: $product-list-item__name-color-hover;
            text-decoration: $product-list-item__name-decoration-hover;
        }
    }

    &__sku {
        font-size: $product-list-item__sku-font-size;
        line-height: 19px;
        color: $product-list-item__sku-color;
        margin: $product-list-item__sku-margin;

        ul {
            list-style-type: disc;
            padding-left: 18px;
        }

        @include mq($screen-m) {
            font-size: $product-list-item__sku-font-size\@medium;
        }
    }

    &__description {
        color: $product-list-item__description-color;
        font-size: $product-list-item__description-font-size;
        margin: $product-list-item__description-margin;
        @include mq($screen-m) {
            font-size: $product-list-item__description-font-size\@medium;
        }
    }

    &__list {
        display: $product-list-item__list-display;
        margin: $product-list-item__list-margin;
        color: $product-list-item__list-color;
        font-size: $product-list-item__list-font-size;
        list-style-type: disc;

        dl {
            color: $product-list-item__list-color;
        }

        dt {
            position: relative;
            padding: $product-list-item__list-label-padding;
        }
    }

    &__actions {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        max-width: 240px;

        @include mq($screen-m) {
            flex-grow: 1;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__actions-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .button--icon:not(.button--add-to) {
            margin: $product-list-item__action-margin;

            @include mq($screen-m) {
                margin: $product-list-item__action-margin\@medium;
            }
        }

        .button--configure {
            background-color: $product-list-item__action-margin-individual-background;
            color: $product-list-item__action-margin-individual-color;
            font-size: 1rem;

            span {
                order: 1;
            }

            .button__icon {
                filter: brightness(0.2);
            }
            svg {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
            &:hover {
                color: $white;
            }
        }

        .product-view__negotiable-note {
            margin-top: 0;
        }

        &--ee {
            display: flex;
            align-items: center;

            .button--tocompare {
                background-color: $product-list-item-ee__tocompare-background-color;
            }

            .button--wishlist {
                background-color: $product-list-item-ee__towishlist-background-color;
            }

            .button {
                justify-content: center;
                order: 1;

                &__text {
                    display: none;
                }

                &.button--add-to .button__text {
                    display: block;
                }
            }

            .product-list-item__add-to-action {
                flex-grow: 0;
                width: 100%;
                order: 4;

                @include mq(false, $screen-xl) {
                    margin-top: 30px;
                }

                .button {
                    .button__text {
                        display: block;
                    }

                    .button__icon {
                        display: block;
                    }
                }
            }

            .block-requisition-list {
                order: 3;
            }

            .product-view {
                &__update-wishlist {
                    display: none;
                }

                &__multiwishlist {
                    width: $product-list-item-ee__multiwishlist-width;
                    margin: $product-list-item-ee__multiwishlist-margin;
                    order: 2;

                    .dropdown-list__label {
                        justify-content: center;
                    }
                }
            }

            .dropdown-list {
                &__content {
                    top: $product-list-item-ee__dropdown-list-content-top;
                    bottom: $product-list-item-ee__dropdown-list-content-bottom;
                    width: $product-list-item-ee__dropdown-list-content-width;

                    @include mq($screen-m) {
                        width: $product-list-item-ee__dropdown-list-content-width\@medium;
                    }
                }

                &__icon {
                    display: none;
                }

                &__label {
                    width: $product-list-item-ee__dropdown-list-label-width;
                    height: $product-list-item-ee__dropdown-list-label-height;
                }

                &__item {
                    position: relative;
                }
            }
        }
    }

    &__delivery-price {
        margin-top: -12px;
        margin-bottom: 12px;
        font-weight: bold;
        color: #253746;
    }

    .product-view__negotiable-note {
        line-height: 1.35;
        font-size: 13px;
    }

    &__price {
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        width: $product-list-item__price-width;

        .price {
            display: inline-flex;
            flex-flow: column nowrap;
            flex: 0 1 auto;
            margin-right: 5px;
            align-items: flex-start;
        }

        @include mq($screen-m) {
            margin: $product-list-item__price-margin\@medium;

            .price__value {
                font-size: $product-list-item__price-font-size\@medium;

                &--old {
                    margin: $product-list-item__price-old-margin\@medium;
                    font-size: $product-list-item__price-old-font-size\@medium;
                }
            }
        }

        .price__wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: -5px;
        }

        .price-box.price-final_price {
            margin-bottom: -5px;
        }
    }

    &__tax {
        justify-content: flex-start;
        display: inline-flex;
        vertical-align: bottom;
        flex: 0 0 auto;

        .price-note {
            color: $brew-text--light-grey2;
            font-size: $font-size-small;
        }
    }

    &__add-to-action {
        flex-grow: $product-list-item__add-to-action-flex-grow;
        max-width: $product-list-item__add-to-action-max-width;

        @include mq($screen-l) {
            min-width: $product-list-item__add-to-action-min-width\@large;
        }

        .button--add-to {
            @include add-to-button($product-list-item__add-to-icon-display);
            width: 100%;
            padding: $spacer;
            height: 40px;

            @include mq($screen-m) {
                @include add-to-button($product-list-item__add-to-icon-display\@medium);
            }

            @include mq($screen-xl) {
                @include add-to-button($product-list-item__add-to-icon-display\@xl);
            }
        }
    }

    &__stock {
        display: $product-list-item__stock-display;
        width: $product-list-item__stock-width;
        justify-content: $product-list-item__stock-text-aligment;
        padding: $product-list-item__stock-padding;
        text-transform: $product-list-item__stock-text-transform;
        text-decoration: $product-list-item__stock-decoration;

        @include mq($screen-m) {
            display: $product-list-item__stock-display\@medium;
            justify-content: $product-list-item__stock-text-aligment\@medium;
        }

        &:hover {
            text-decoration: $product-list-item__stock-text-decoration-hover;
        }
    }

    .button--wishlist {
        &.button--icon-filled {
            .icon {
                fill: $color-primary;
            }

            &:after {
                display: block;
                background-position: 0 100%;
            }

            &:hover,
            &:active {
                &:before {
                    background-color: $product-list-item__wishlist-filled-bg-color-hover;
                }

                &:after {
                    display: block;
                    background-image: $product-list-item__wishlist-filled-bg-image-after-hover;
                }

                .icon {
                    fill: $white;
                }
            }
        }
    }

    &__rating {
        .review__amount,
        .review__summary-add {
            display: none;
        }
    }

    // swatch & loader
    .swatch {
        &__container--catalog {
            .loader__icon {
                margin: $product-list-item__swatch-loader-icon-margin;
            }
        }

        &__wrapper {
            justify-content: flex-start;
            margin-left: -$swatch__option-padding--catalog;

            @include mq($screen-m) {
                justify-content: flex-end;
                margin-left: 0;
            }
        }
    }

    &__ministars-wrapper {
        width: 100%;
        margin-top: 0.75rem;
    }
}
