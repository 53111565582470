$badge__padding           : 20px 40px 5px;
$badge__margin            : 0 0 $spacer;
$badge__color             : $white;
$badge__background        : $brew-about-brewes;
$badge__background--new   : $white;
$badge__background--red   : $brew-red-brewes;
$badge__background--purple: $brew-purple-brewes;
$badge__font-size         : $font-size-small;
$badge__font-weight       : $font-weight-semibold;
$badge__text-transform    : uppercase;
$badge__line-height       : 15px;
