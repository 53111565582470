@import 'auth-variables';

.auth {
    &__link {
        position: relative;
        justify-content: flex-start;
        width: $auth__link-width;
        height: $auth__link-height;
        border: $auth__link-border;
        border-width: $auth__link-border-width;
        color: $auth__link-color;
        font-size: $auth__link-font-size;
        font-weight: $auth__link-font-weight;
        text-decoration: $auth__link-text-decoration;
        text-transform: $auth__link-text-transform;

        @include mq($screen-m) {
            justify-content: center;
            width: $auth__link-width\@medium;
        }

        &:hover,
        &.focus-visible,
        &--active {
            color: $auth__link-color--active;
        }

        &--active {
            background: $auth__link-background--active;
            font-weight: $auth__link-font-weight--active;

            &:hover,
            &.focus-visible {
                background: $auth__link-background--active;
            }

            .auth__icon {
                transform: $auth__icon-transform--active;
            }
        }

        &--registration {
            display: none;

            @include mq($screen-m) {
                display: flex;
            }

            &-mobile {
                @include mq($screen-m) {
                    display: none;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        right: $spacer--medium;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: $color-secondary;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__heading {
        margin: $auth__heading-margin;

        @include mq($screen-m) {
            margin: $auth__heading-margin\@medium;
        }
    }

    &__container {
        max-width: $auth__container-max-width;
        margin: $auth__container-margin;
    }

    &--registration {
        .auth__link {
            &--registration-mobile {
                display: none;
            }
        }
    }
}

.customer-account-login, .customer-account-create {
    .login-container {
        padding-bottom: 80px;
    }

    #is_privacy-error {
        bottom: -25px;
    }
}

.customer-account-create {

    .field.choice.margin-bottom-l {
        display: none;
    }

    .auth__links--registration {
        background-color: transparent;
        margin-bottom: 20px;
    }

    .auth__link {
        padding: 10px 0 10px;
        font-size: 20px;
        color: $auth__link-dark-grey;
        font-weight: 600;
        border-width: 1px 1px 0;
        background-color: transparent;

        @include mq($screen-m) {
            width: 100%;
        }
    }

    .account-form__actions .account-form__button {
        width: 100%;
    }
}

.login-forms {
    max-width: 594px;
    width: 100%;
    margin: 0 auto;

    .container.auth {
        @include mq(false, $screen-m) {
            padding: 0;
        }
    }

    .login-form-titles {
        display: flex;
        margin-bottom: 24px;
        border-bottom: 1px solid $auth__link-grey;

        @include mq($screen-m) {
            padding: 0 15px;
            border-bottom: none;
            margin-bottom: 38px;
        }

        .title {
            width: 100%;

            @include mq($screen-m) {
                background-color: $auth__link-dark;
                border: 1px solid $auth__link-grey;
            }

            .switch {
                padding: 10px 0 26px;
                font-size: 20px;
                color: $auth__link-dark-grey;
                font-weight: 600;

                @include mq($screen-m) {
                    padding: 17px 20px;
                    font-weight: 400;
                }

                &:hover {
                    text-decoration: none;
                }

                span {
                    @include mq(false, $screen-m) {
                        display: none;
                    }
                }

                @include mq(false, $screen-m) {
                    &::after {
                        content: attr(data-tab);
                    }
                }
            }

            &:not(.active) {
                .switch {
                    &::before {
                        content: url("../images/icons/arrow-gray.svg");
                    }
                }
            }

            &.active {
                background-color: $auth__link-white;
                border-bottom-color: $auth__link-white;

                .switch {
                    font-weight: 600;
                }
            }

            &:first-child {
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;

                @include mq($screen-m) {
                    min-width: 305px;
                }
            }
        }
    }
}

@include mq($screen-m) {
    .forgot-password {
        max-width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
    }
}
