// @author    Sarah Lee Mandolla <SarahLee.Mandolla@brewes.de>
// @copyright 2023 Brewes GmbH
// @link      https://www.brewes.de/
// ------------------------------------------------------------------
// CSS for Brewes Banner Templates
// ------------------------------------------------------------------

// Import the global variables and mixins
@import '../../Atoms/variables/brewes-variables';

// Import specific variables for Banner Templates
@import 'brewes-banner-variables';

// Base class for banner styling
.basic-banner-styling {

  // Set the height for elements with class .pagebuilder-column-line
  .pagebuilder-column-line {
    max-height: $banner-height;
  }

  // Adjust maximum height for images in banner
  .product-for-coupon img {
    max-height: $banner-height;
    object-fit: contain;
  }

  // Class for white font color
  .fc-white {
    @include font-color($brew-text--light);
  }

  // Class for italic font style
  .fst-italic {
    @include font-style(italic);
  }

  // Class for light font weight
  .fw-light {
    @include font-weight(lighter);
  }

  // Class for bold font weight
  .fw-bold {
    @include font-weight(bold);
  }

  // Class for font size "xs"
  .fs-xs {
    @include font-size($fs-xs);
  }

  // Class for font size "sm"
  .fs-sm {
    @include font-size($fs-sm);
  }

  // Class for font size "md"
  .fs-md {
    @include font-size($fs-md);
  }

  // Class for font size "lg"
  .fs-lg {
    @include font-size($fs-lg);
  }

  // Class for left margin "md"
  .ml-md {
    @include margin-left($ml-md);
  }

  // Class for left margin "lg" (important)
  .ml-lg {
    @include margin-left($ml-lg !important);
  }

  // Class for bottom margin "sm"
  .mb-sm {
    @include margin-bottom($mb-sm);
  }

  // Class for top margin "sm"
  .mt-sm {
    @include margin-top($mt-sm);
  }

  // Dashed border style
  .dashed-border-coupon {
    border: 2px $brew-text--light dashed;
    border-radius: 6px;
    width: fit-content;
    padding: 5px;

    p {
      margin-bottom: 0 !important;
    }
  }
}

// Class for special gradient background
.special-banner-gradient {
  padding: 20px;
  background: rgb(212,214,220);
  background: linear-gradient(45deg, $platinum  0%, $french-gray 8%, $cadet-gray 25%);

  // Inherit the base class "basic-banner-styling"
  @extend .basic-banner-styling;
}

// Class for Brewes Banner info
.brewes-banner-info {
  // Inherit the base class "basic-banner-styling"
  @extend .basic-banner-styling;
}

// Media Query for screens with max width of 1200px
@media only screen and (max-width: 1200px) {
  // Reset the banner height
  $banner-height: unset;

  // Reset maximum height for elements with class .pagebuilder-column-line
  .pagebuilder-column-line {
    max-height:unset !important;
  }

}
