@import 'message-variables';

.message {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $max-content-width;
    padding: $message__padding;
    margin: 0 auto 20px;
    background-color: $message__background;
    border-color: $message__border-color;
    border-width: $message__border-width;
    border-style: $message__border-style;
    border-radius: $message__border-radius;
    color: $message__color;
    font-size: $message__font-size;
    transition: $message__transition;
    z-index: 2;
    pointer-events: none;

    &[style="display: block;"] {
        display: flex !important; // sass-lint:disable-line no-important
    }

    p {
        color: $message__color;
        font-size: $message__font-size;
        line-height: inherit;
        margin: 0;
    }

    @include mq($screen-m) {
        padding: $message__padding\@medium;
    }

    &:before {
        content: '';
        position: absolute;
        order: $message__icon-order--before;
        height: 18px;
        width: 20px;
        min-width: 20px;
        margin: $message__icon-margin--before;
        background-image: $message__icon-bg-image--close;
        background-repeat: no-repeat;
        background-size: $message__icon-spacer;
        pointer-events: visibleStroke;
        right: 10px;
    }

    &:active {
        animation: hideMessage 0.1s ease-in-out;
    }

    @keyframes hideMessage {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            height: 0;
            opacity: 0;
            margin: 0;
            padding: 0;
        }
    }

    &:after {
        content: '';
        position: relative;
        order: $message__icon-order;
        height: $message__icon-spacer;
        width: $message__icon-width;
        min-width: $message__icon-width;
        margin: $message__icon-margin;
        background-image: $message__icon-bg-image;
        background-repeat: no-repeat;
        background-size: $message__icon-spacer;
    }

    &--success {
        background-color: $message__background--success;
        border-color: $message__border-color--success;
        color: $message__color--success;

        &:after {
            background-image: $message__icon-bg-image--success;
        }
    }

    &--error {
        background-color: $message__background--error;
        border-color: $message__border-color--error;
        color: $message__color--error;

        &:after {
            background-image: $message__icon-bg-image--eror;
        }
    }

    .link {
        text-decoration: underline;
    }
}

//extend messages generated in backend or 3rd party modules
.message-success {
    @extend .message--success;
}

.message-error {
    @extend .message--error;
}

#maincontent .messages.container {
    display: none;
}

.header .messages.container {
    .message {
        box-shadow: 0 5px 14px rgba(0, 0, 0, 0.33);
    }
}

.header .messages-box {
    position: absolute;
    margin: 0 auto;
    max-width: 1471px;
    width: 100%;
    left: 0;
    right: 0;
}

.header--checkout .messages-box {
    position: fixed;
    top: 0;
    z-index: 100;
}
