$headings-margin                              : 0 0 0.5em 0;
$headings-margin--page                        : $spacer--medium 0;

$headings-font-family                         : $font-family-base;
$headings-font-family--secondary              : $font-family-base;
$headings-font-weight                         : $font-weight-semibold;
$headings-font-weight--bold                   : $font-weight-bold;
$headings-line-height                         : 19px;
$headings-font-size                           : 20px;
$headings-color                               : $brew-titles;

$headings-text-transform                      : inherit;
$headings-text-transform--page                : inherit;

$heading-font-size--page\@screen-m            : $font-size-super-extra-large;
$heading-font-size--page                      : $font-size-extra-large;
$heading-margin--page-full-width              : 0 0 $spacer--extra-large;
$heading-text-transform--page-full-width      : center;

$heading-font-size--first-level\@screen-m     : $font-size-super-extra-large;
$heading-font-size--first-level               : $font-size-extra-large;

$heading-font-size--second-level\@screen-m    : $font-size-extra-large;
$heading-font-size--second-level              : $font-size-large;

$heading-font-size--big-second-level\@screen-m: 50px;
$heading-font-size--big-second-level          : $font-size-extra-large;

$heading-font-size--third-level\@screen-m     : $font-size-large;
$heading-font-size--third-level               : $font-size-medium;

$heading-font-size--fourth-level              : $font-size-medium;
$heading-font-size--fifth-level               : $font-size-medium;
$heading-font-size--sixth-level               : $font-size-medium;

.heading {
    display: block;
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-large;
    color: $headings-color;
    text-transform: $headings-text-transform;
    letter-spacing: 0;

    @include mq($screen-l) {
        font-size: $heading-font-size--second-level\@screen-m;
    }

    &--arrow {
        display: flex;
        column-gap: 10px;
        font-size: $font-size-extra-large;
        line-height: $headings-line-height;
    }

    &--arrow-red {
        @extend .heading--arrow;

        &::before {
            content: url("../images/icons/arrow-red.svg");
        }
    }

    &--arrow-gray {
        @extend .heading--arrow;

        &::before {
            content: url("../images/icons/arrow-gray.svg");
        }
    }

    &--banner-light {
        font-weight: $font-weight-bold;
        font-style: italic;
        font-size: 50px;
        line-height: 1;
        color: $white;
    }

    &--small {
        font-size: 16px;
        line-height: 20px;
        color: $brew-text;
    }

    &--small-bold {
        font-size: 16px;
        line-height: 29px;
        font-weight: $font-weight-bold;
    }

    &--article {
        font-size: 25px;
        line-height: 42px;
    }

    &--first-level {
        font-size: $heading-font-size--first-level;

        @include mq($screen-m) {
            font-size: $heading-font-size--first-level;
        }
    }

    &--second-level {
        font-size: $heading-font-size--second-level;

        @include mq($screen-m) {
            font-size: $heading-font-size--second-level\@screen-m;
        }

        &-big {
            font-size: $heading-font-size--big-second-level;
            font-style: italic;

            @include mq($screen-m) {
                font-size: $heading-font-size--big-second-level\@screen-m;
            }
        }
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
        font-weight: $headings-font-weight--bold;

        @include mq($screen-m) {
            font-size: $heading-font-size--third-level\@screen-m;
        }
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
    }

    &--font-secondary {
        font-family: $headings-font-family--secondary;
    }

    &--page {
        margin: $headings-margin--page;
        font-size: $heading-font-size--page;
        text-transform: $headings-text-transform--page;

        @include mq($screen-m) {
            font-size: $heading-font-size--page\@screen-m;
        }

        &.heading--page-full-width {
            margin: $heading-margin--page-full-width;
            text-align: $heading-text-transform--page-full-width;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}

[data-content-type="row"] {
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $font-weight-semibold;
    }
}
