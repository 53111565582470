@import 'nav-variables';

.dashboard-nav {
    &__mobile {
        @extend %arrow;

        display: block;
        position: relative;
        border: $dashboard-nav__mobile-border;
        border-width: $dashboard-nav__mobile-border-width;
        padding: $dashboard-nav__mobile-padding;
        font-weight: $dashboard-nav__mobile-font-weight;
        cursor: pointer;

        @include mq($screen-l) {
            display: none;
        }

        &--active {
            @extend %arrow-active;
        }
    }

    &__content {
        display: none;

        &--visible {
            display: block;
        }

        @include mq($screen-l) {
            display: block;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin: $dashboard-nav__actions-margin;

        @include mq($screen-l) {
            margin: $dashboard-nav__actions-margin\@large;
        }
    }

    &__button {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
            min-width: $dashboard-nav__button-min-width\@medium;
        }

        @include mq($screen-l) {
            width: 100%;
            min-width: auto;
        }
    }

    &__logout-button {
        display: none;

        @include mq($screen-l) {
            display: flex;
        }

        &--mobile {
            display: flex;

            @include mq($screen-l) {
                display: none;
            }
        }
    }

    &__section {
        display: none;

        @include mq($screen-l) {
            display: flex;
            flex-direction: column;
        }
    }

    &__section-title {
        margin: $dashboard-nav__section-title-margin;
        font-family: $dashboard-nav__section-title-font-family;
        font-size: $dashboard-nav__section-title-font-size;
        letter-spacing: $dashboard-nav__section-title-letter-spacing;
    }

    &__section-info {
        margin: $dashboard-nav__section-info-margin;
    }

    // This class is changing by Magento
    .current {
        position: relative;
        background: $dashboard-nav__link-bg-current;

        .dashboard-nav__link {
            font-weight: $dashboard-nav__link-font-weight-current;

            &:hover {
                color: $dashboard-nav__link-color-current-hover;
                text-decoration: $dashboard-nav__link-text-decoration-current-hover;
            }
        }
    }

    .nav.item.current {
        padding: 6px 10px;

        @extend .current;
    }

    .nav.item.current:has(a) {
        padding: 0;

        @extend .dashboard-nav__link;

        & > a {
            padding: 6px 10px;
            &:after {
                display: none;
            }
        }
    }
}
