// Child theme variables
@import "../Snowdog_Components/components/Atoms/variables/_brewes-variables";

// Variables
@import '../Snowdog_Components/components/Atoms/variables/variables';

// Components
@import '../Snowdog_Components/components/styles/non-critical';

// Theme partials
@import '../Amasty_Storelocator/styles/module';
@import '../Amasty_GiftCard/styles/module';
@import '../Magento_Ui/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_PageBuilder/styles/module';
@import '../Magento_QuickOrder/styles/module';
@import '../Yotpo_Yotpo/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../PayPal_Braintree/styles/module';
@import '../Magento_ReCaptchaFrontendUi/styles/module';
@import '../Magento_Theme/styles/module-non-critical';

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important;
}
.header__minicart {
    .visually-hidden {
        display: initial;
    }
}
div.newsletter,
.mega-menu__list {
    display: flex;
}

.fotorama-item {

    .fotorama__nav-wrap--horizontal {
        margin-top: 10px;
    }
}

