.lexicon-overview-container {

    .letters-list {
        border-bottom: 1pt solid $brew-border-gray;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        padding-bottom: 1em;

        @include mq(false, $screen-xl) {
            flex-wrap: wrap;
        }

        .letters__link {
            color: $brew-text;
            text-decoration: none;
            font-size: 20px;
            font-weight: 600;

            @include mq(false, $screen-m) {
                min-width: 2em;

                &:last-child {
                    margin-right: auto;
                }
            }

            &.empty {
                color: $brew-gray--darken;
                font-weight: 300;
            }
        }
    }

    .letter-container {
        scroll-margin-top: 120px;

        .letter {
            color: $brew-titles;
            font-size: 22px;

            &.empty {
                color: $brew-gray--darken;
            }
        }

        .items {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .lexicon-article, .card-title {
        font-size: 16px;
        line-height: 1.5;
        padding: 8px 0;
        color: $brew-text;
    }

    .lexicon-article {
        flex: 0 0 100%;

        @include mq($screen-m) {
            flex: 0 0 50%;
        }
        @include mq($screen-xl) {
            flex: 0 0 20%;
            margin-left: 30px;
        }
    }
    @include mq($screen-m) {
        .card-title {
            padding-right: 30px;
        }
    }

    &.sub-search-for-content {
        .lexicon-article {
            display: block;
        }
    }

    .toolbar__amount {
        @include mq(false, $screen-m) {
            margin: 0 auto;
        }
    }
}

.ct-listing-lexicon .heading--article {
    color: $brew-titles;
}

.lexicon__wrapper {
    @include mq($screen-xl) {
        max-width: 60%;
    }
}
