$dashboard-form__label-font-size    : 16px;

.dashboard-form {
    &__title {
        @extend .heading--arrow-red;
        text-transform: uppercase;
    }

    &--hidden {
        display: none;
    }

    .label,
    .input__label,
    .input__field,
    select {
        font-size: $dashboard-form__label-font-size;
    }
}

.field-company_legal_name {
    margin-bottom: 16px;

    #company_legal_name {
        width: 100%;
        height: $spacer--semi-large;
        padding: 0 $spacer--medium;
        border: $form-elements-border;
        border-radius: $form-elements-radius;
        line-height: normal;
        font-size: 16px;
        font-family: $font-family-base;
        color: $brew-text;

        &::placeholder {
            color: $brew-icon-gray;
            font-size: 16px;
            font-weight: 400;
        }

        &.focus-visible {
            @include focus-input();
        }
    }
}

