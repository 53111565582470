.recently-viewed {
    position: relative;
    max-width: $container-max-width;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;

    &--sidebar {
        .slider__slides {
            flex-wrap: wrap;
        }
    }
}
