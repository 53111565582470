
$footer__sidebar-background                        : $brew-text--light;
$footer__sidebar-border                            : $brew-text--light-grey;
$footer__sidebar-brew-text                         : $brew-text;
$footer__sidebar-gray-darker                       : $brew-titles;
$footer__sidebar-gray-lighter                      : $brew-section-midtone;
$footer__sidebar-green                             : $brew-green;
$footer__sidebar-red                               : $brew-red-brewes;
$footer__sidebar-box-shadow                        : 0 3px 16px rgba(0, 0, 0, 0.1);
$footer__sidebar-icon-size                         : 43px;
$footer__sidebar-padding-top                       : 20px;

$footer__sidebar-font-size-extra-small             : $font-size-extra-small;
$footer__sidebar-font-size-medium                  : $font-size-medium;
$footer__sidebar-font-size                         : 14px;
$footer__sidebar-line-height                       : 22px;
$footer__sidebar-font-size-middle                  : 16px;

$footer__sidebar-font-line-height-low              : 20px;

$footer__sidebar-border-radius                     : 3px;
$footer__sidebar-button-height                     : 40px;
$footer__sidebar-font-line-semi-large              : 38px;
$footer__sidebar-opacity                           : 0.7;

$footer__sidebar-button-icon-width                 : 18px;
$footer__sidebar-button-icon-height                : 18px;

$footer__sidebar-spacer--medium-low                : 20px;
$footer__sidebar-spacer--extra-small               : 2px;
$footer__sidebar-spacer--base                      : 15px;
$footer__sidebar-spacer--small                     : 7px;

@keyframes shake {
    0% { transform: translate(2px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-5deg); }
    20% { transform: translate(-3px, 0px) rotate(5deg); }
    30% { transform: translate(0px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(5deg); }
    50% { transform: translate(-1px, 2px) rotate(-5deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(2px, 1px) rotate(-5deg); }
    80% { transform: translate(-1px, -1px) rotate(5deg); }
    90% { transform: translate(2px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-5deg); }
}

.sidebar-contact-menu {
    position: fixed;
    right: 15px;
    bottom: 30px;
    z-index: 11;

    @include mq($screen-m) {
        right: 26px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 20px;

            @include mq($screen-m) {
                margin-bottom: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.sidebar-phone {
                position: relative;
            }
        }
    }

    a,
    span.link {
        background: $footer__sidebar-background;
        box-shadow: $footer__sidebar-box-shadow;
        border: 1px solid $footer__sidebar-border;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $footer__sidebar-icon-size;
        height: $footer__sidebar-icon-size;
    }

    .go-to-top {
        padding-top: $footer__sidebar-padding-top;
        transition: $transition-base;
        height: $footer__sidebar-icon-size + $footer__sidebar-padding-top;
        overflow: hidden;

        &.hidden {
            height: 0;
            padding-top: 0;

            a {
                box-shadow: none;
            }
        }
    }

    .phone-number-popup {
        position: absolute;
        right: 50px;
        bottom: 0;
        background: $footer__sidebar-background;
        box-shadow: $footer__sidebar-box-shadow;
        border: 1px solid $footer__sidebar-border;
        border-radius: 3px;
        padding: 20px 30px;
        width: 240px;
        z-index: 101;
        transform: translateX(1000px);
        transition: $transition-base;

        p {
            font-size: $footer__sidebar-font-size;
            line-height: $footer__sidebar-line-height;
            color: $footer__sidebar-brew-text;
            margin-bottom: 0;

            a {
                font-size: $footer__sidebar-font-size;
                line-height: $footer__sidebar-line-height;
                color: $footer__sidebar-brew-text;
                background: none;
                border: none;
                font-weight: 600;
                display: inline;
                box-shadow: none;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .phone-number-popup-wrapper {
        .phone-number-popup-back {
            position: fixed;
            z-index: 100;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
        }

        &.opened-phone-popup {
            .phone-number-popup-back {
                display: block;
            }

            .phone-number-popup {
                transform: translateX(0);
            }
        }
    }

    .sidebar-promo-button.shake {
        animation-name: shake;
        animation-duration: 0.5s;
        transform-origin: 50% 50%;
        animation-iteration-count: 4;
    }
}

.modal--secondary {
    &.header__dialog-modal {
        .modal__container {
            padding: 22px 0;

            @include mq($screen-l) {
                max-width: 530px;
            }
        }

        .dialog-content {
            height: auto;
            font-size: $footer__sidebar-font-size;
            padding: 0;
            display: flex;
            flex-direction: column;
            max-height: 100%;

            &__top {
                padding: 0 0 21px 0;
                margin: 0;
                border-bottom: 1px solid $footer__sidebar-border;
            }

            &__counter {
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
                color: $footer__sidebar-brew-text;
                margin: 0;
                padding: 0 50px 0 15px;

                @include mq($screen-m) {
                    padding: 0 30px;
                }

                @include mq($screen-l) {
                    font-size: 20px;
                }
            }

            &__middle {
                overflow-y: auto;
                height: calc(100vh - 73px);
            }

            .pccf {
                legend {
                    font-size: $footer__sidebar-font-size-middle;
                    line-height: $footer__sidebar-line-height;
                    text-align: left !important;
                    color: $footer__sidebar-brew-text;
                    padding: 30px 15px 0;

                    @include mq($screen-m) {
                        padding: 35px 30px 0;
                    }
                }

                form {
                    padding: 0;
                    border: none;
                }

                fieldset {
                    padding: 30px 15px 0;
                    border: none;

                    @include mq($screen-m) {
                        padding: 35px 30px 0;
                    }
                }

                .form-group {
                    margin-bottom: 20px;

                    label {
                        font-size: $footer__sidebar-font-size-middle;
                        line-height: $footer__sidebar-line-height;
                        font-weight: 600;
                        color: $footer__sidebar-brew-text;

                        &.col-md-3 {
                            flex-basis: 100%;
                            max-width: 100%;
                            padding: 0;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    &.upload-field {
                        width: 100%;
                        justify-content: space-between;
                        padding-bottom: 24px;

                        @include mq(false, $screen-m) {
                            flex-wrap: wrap;
                        }

                        & > div {
                            width: calc(50% - 10px);

                            @include mq(false, $screen-m) {
                                width: 100%;
                            }
                        }

                        .upload-info {
                            span {
                                font-size: $footer__sidebar-font-size-extra-small;
                                line-height: $footer__sidebar-font-line-height-low;
                                color: $footer__sidebar-gray-darker;
                                display: block;

                                &.span-title {
                                    font-weight: 600;
                                }
                            }

                        }

                        .fs-upload-target {
                            min-height: auto;
                            margin-top: $footer__sidebar-spacer--base;
                            padding: 0;
                            border: none;

                            .dz-message {
                                margin: $footer__sidebar-spacer--small 0;
                                font-size: $footer__sidebar-font-size-medium;
                                background: $footer__sidebar-gray-lighter;
                                border: 1px solid $footer__sidebar-border;
                                border-radius: $footer__sidebar-border-radius;
                                height: $footer__sidebar-button-height;
                                display: block;
                                line-height: $footer__sidebar-font-line-semi-large;

                                &:hover {
                                    opacity: $footer__sidebar-opacity;
                                }

                                &:before {
                                    content: '';
                                    background-image: url("../images/icons/upload_icon.svg");
                                    width: $footer__sidebar-button-icon-width;
                                    height: $footer__sidebar-button-icon-height;
                                    display: inline-block;
                                    margin-right: $footer__sidebar-spacer--medium-low;
                                    transform: translateY($footer__sidebar-spacer--extra-small);
                                }
                            }

                            .dz-image,
                            .dz-error-message,
                            .dz-success-mark,
                            .dz-error-mark {
                                display: none;
                            }

                            .dz-preview {
                                display: flex;
                                margin: 0;
                                justify-content: space-between;
                                min-height: auto;
                                max-width: 100%;

                                .dz-details {
                                    position: static;
                                    padding: 0;
                                    display: flex;
                                    min-width: auto;
                                    opacity: 1;
                                    width: 70%;
                                    overflow: hidden;

                                    .dz-filename {
                                        max-width: 70%;
                                        text-overflow: ellipsis;
                                        overflow: hidden;

                                        &:not(:hover) span {
                                            border: none;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            padding: 0 5px;
                                        }

                                        &:hover span {
                                            border: none;
                                            background: none;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            padding: 0 5px;
                                        }
                                    }

                                    .dz-size {
                                        width: 30%;

                                        span {
                                            padding: 0;
                                        }
                                    }
                                }

                                .dz-remove {
                                    position: static;
                                    opacity: 1;
                                }

                                .dz-progress {
                                    position: absolute;
                                    right: 0;
                                    left: auto;
                                    top: 0;
                                    margin-top: $footer__sidebar-spacer--extra-small;
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    &.button-field {
                        margin-bottom: 0;
                    }

                    .contact-us__button {
                        height: 60px;
                        width: 100%;
                        font-size: 20px;
                        font-weight: 300;
                        margin-top: 0;

                        @include mq(false, $screen-m) {
                            height: 40px;
                        }
                    }
                }

                .row {
                    .col-md-2 {
                        display: none;
                    }

                    .col-md-8 {
                        flex-basis: 100%;
                        max-width: 100%;
                    }

                    .text-center {
                        text-align: left;
                    }

                    h6 {
                        font-size: $footer__sidebar-font-size-middle;
                        line-height: $footer__sidebar-line-height;
                        font-weight: 600;
                        color: $footer__sidebar-brew-text;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .messages {
            padding: 30px 15px 0;

            @include mq($screen-m) {
                padding: 35px 30px 0;
            }

            .alert {
                &-success {
                    h3,
                    p {
                        color: $footer__sidebar-green;
                    }
                }

                &-error {
                    color: $footer__sidebar-red;

                    h3,
                    p {
                        color: $footer__sidebar-red;
                    }
                }
            }
        }
    }
}

#modal-feedback {
    .pccform .form-group {
        textarea {
            min-height: 140px;
        }

        .control-label {
            margin-bottom: 10px;
        }
    }
    .form-group.input.input-text.required-control {
        width: 100%;
        margin-bottom: 30px !important;
    }
    .upload-info {
        padding-top: 12px;
    }

    .form-label {
        p {
            margin-bottom: 0 !important;
            font-weight: 600 !important;
        }
    }
}
