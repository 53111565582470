$dropdown-list__width                                  : 100% !default;
$dropdown-list__bg-color                               : $white !default;
$dropdown-list__transition                             : $transition-base !default;
$dropdown-list__padding                                : 0 !default;

// List item
$dropdown-list__item-color                             : $gray-darker !default;
$dropdown-list__item-bg-color                          : $white !default;
$dropdown-list__item-border-bottom                     : $border-base !default;
$dropdown-list__item-width                             : 100% !default;
$dropdown-list__item-font-size                         : $font-size-base !default;

// List
$dropdown-list__list-width                             : 100% !default;
$dropdown-list__list-padding                           : 0 !default;
$dropdown-list__list-margin                            : 0 !default;
$dropdown-list__list-style-type                        : none !default;

// Label
$dropdown-list__label-font-size                        : $font-size-large !default;
$dropdown-list__label-text-transform                   : uppercase !default;
$dropdwon-list__label-font-famility                    : $font-family-base !default;
$dropdown-list__label-color                            : $color-primary !default;
$dropdown-list__label-margin                           : 0 !default;
$dropdown-list__label-padding-left                     : 0 !default;
$dropdown-list__label-padding-right                    : 48px !default;
$dropdown-list__label-border-radius                    : 0 !default;
$dropdown-list__label-width                            : 100% !default;
$dropdown-list__label-height                           : 64px !default;
$dropdown-list__label-bg-color                         : $white !default;
$dropdown-list__label-font-weight                      : $font-weight-bold !default;
$dropdown-list__label-color-hover                      : $gray-darker !default;
$dropdown-list__label-bg-color-hover                   : $white !default;
$dropdown-list__label-text-decoration                  : none !default;
$dropdown-list__label-text-decoration-hover            : none !default;
$dropdown-list__label-text-align                       : left !default;
$dropdown-list__label-border                           : 0 !default;
$dropdown-list__label-cursor                           : pointer !default;
$dropdown-list__label-color--open                      : $color-primary !default;
$dropdown-list__label-bg-color--open                   : $white !default;
$dropdwon-list__label-line-height                      : $font-line-height !default;

// List icon
$dropdown-list__icon-width                             : 24px !default;
$dropdown-list__icon-height                            : 24px !default;
$dropdown-list__icon-fill                              : $color-secondary !default;
$dropdown-list__icon-fill-hover                        : $color-primary !default;
$dropdown-list__icon-fill--open                        : $color-primary !default;
$dropdown-list__icon-rotate--open                      : rotate(180deg) !default;
$dropdown-list__icon-margin                            : auto !default;
$dropdown-list__icon-right                             : $spacer--small !default;

// List content
$dropdown-list__content-transition                     : height 0.3s !default;

// Secondary variant
$dropdown-list__bg-color--secondary                    : $dropdown-list__bg-color !default;
$dropdown-list__label-font-size--secondary             : $font-size-large !default;
$dropdown-list__label-height--secondary                : 48px !default;
$dropdown-list__label-padding-right--secondary         : 48px !default;
$dropdown-list__label-padding-left--secondary          : 0 !default;
$dropdown-list__icon-width--secondary                  : 24px !default;
$dropdown-list__icon-height--secondary                 : 24px !default;
$dropdown-list__label-text-transform--secondary        : uppercase !default;
$dropdown-list__icon-right--secondary                  : $dropdown-list__icon-right !default;

// With nested variant
$dropdown-list__label-font-size--with-nested           : $font-size-base !default;
$dropdown-list__label-font-weight--with-nested         : $font-weight-medium !default;
$dropdown-list__label-height--with-nested              : 56px !default;
$dropdown-list__label-padding-left--with-nested        : 0 !default;
$dropdown-list__label-padding-right--with-nested       : 56px !default;

$dropdown-list__label-color--with-nested               : $color-primary !default;
$dropdown-list__icon-fill--with-nested                 : $color-secondary !default;
$dropdown-list__icon-width--with-nested                : 24px !default;
$dropdown-list__icon-height--with-nested               : 24px !default;
$dropdown-list__icon-right--with-nested                : $spacer--medium !default;

// Inner variant
$dropdown-list__padding--inner                         : 0 0 $spacer--medium $spacer--medium !default;
$dropdown-list__label-color--inner                     : $color-secondary !default;
$dropdown-list__label-text-transform--inner            : capitalize !default;
$dropdown-list__label-font-weight--inner               : $font-weight-base !default;
$dropdown-list__label-border-bottom--inner             : 0 !default;
$dropdown-list__label-font-size--inner                 : $font-size-base !default;
$dropdown-list__label-height--inner                    : 48px !default;
$dropdown-list__label-padding-right--inner             : 48px !default;
$dropdown-list__label-padding-left--inner              : 0 !default;

// with-breakpoint list variables
$dropdown-list__screen--breakpoint                     : $screen-m !default;
$dropdown-list__content-margin--breakpoint             : $spacer $spacer--medium !default;
$dropdown-list__item-bg-color--breakpoint              : transparent !default;

// detailed-content list variables
$dropdown-list__width--detailed-content                : auto !default;
$dropdown-list__label-text-transform--detailed-content : capitalize !default;
$dropdown-list__label-text-decoration--detailed-content: underline !default;
$dropdown-list__label-color--detailed-content          : $color-primary !default;
$dropdown-list__label-font-size--detailed-content      : $font-size-base !default;
$dropdown-list__label-font-weight--detailed-content    : $font-weight-base !default;
$dropdown-list__label-height--detailed-content         : 48px !default;
$dropdown-list__label-padding-right--detailed-content  : 36px !default;
$dropdown-list__label-padding-left--detailed-content   : 0 !default;
$dropdown-list__item-border-bottom--detailed-content   : 0 !default;
$dropdown-list__icon-right--detailed-content           : $spacer--small !default;
$dropdown-list__label-width--detailed-content          : auto !default;

// buttons list variables
$dropdown-list__label-height--buttons                  : 56px !default;
$dropdown-list__label-padding--buttons                 : $spacer--extra-small $spacer !default;
$dropdown-list__label-background-color--buttons        : $gray-lighter !default;
$dropdown-list__label-background-color-active--buttons : $gray-darkest !default;
$dropdown-list__label-text-color-active--buttons       : $white !default;
$dropdown-list__icon-fill-active--buttons              : $white !default;
$dropdown-list__icon-fill--buttons                     : $gray-darkest !default;
$dropdown-list__icon-fill-hover--buttons               : $white !default;
$dropdown-list__text-font-size--buttons                : $font-size-base !default;
$dropdown-list__text-color--buttons                    : $gray-darkest !default;
$dropdown-list__text-color-hover--buttons              : $white !default;
$dropdown-list__content-left--buttons                  : 0 !default;
$dropdown-list__content-top--buttons                   : 100% !default;
$dropdown-list__content-width--buttons                 : 100% !default;
$dropdown-list__content-padding--buttons               : $spacer 0 0 !default;
$dropdown-list__content-background-color--buttons      : $white !default;
$dropdown-list__content-border--buttons                : 1px solid $gray-dark !default;
$dropdown-list__item-padding--buttons                  : $spacer--small $spacer--semi-medium !default;
$dropdown-list__item-font-size--buttons                : $font-size-medium !default;
$dropdown-list__item-font-line-height--buttons         : 1.3 !default;
$dropdown-list__item-background-active--buttons        : $gray-light !default;
