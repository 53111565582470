$gallery__width                             : 100%;
$gallery__padding                           : 0;
$gallery__margin                            : 0;

$gallery--icon-arrow-color                  : $color-primary;
$gallery__icon-arrow-color-hover            : $color-secondary;

$gallery__nav-margin-right--vertical\@medium: $spacer--medium;
$gallery__nav-margin--vertical\@medium      : 0 $gallery__nav-margin-right--vertical\@medium 0 0;
$gallery__nav-margin-right--vertical\@large : $spacer--semi-large;
$gallery__nav-margin--vertical\@large       : 0 $gallery__nav-margin-right--vertical\@large 0 0;

$gallery__stage-margin                      : 0 0 $spacer--medium 0;

$gallery__thumb-border                      : none;
$gallery__thumb-border--active              : $brew-standard-border;
$gallery__thumb-spacing                     : $spacer--medium;
$gallery__thumb-margin                      : 0 $gallery__thumb-spacing 0 0;
$gallery__thumb-margin--vertical            : 0 0 $gallery__thumb-spacing 0;
$gallery__thumb-width                       : 64px;
$gallery__thumb-width\@medium               : 64px;

// dots
$gallery__thumb-margin--dots                : 0 5px;
$gallery__thumb-dot-size--dots              : 6px;
$gallery__thumb-dot-bg-color--dots          : $gray;
$gallery__thumb-dot-bg-color-active--dots   : $color-primary;
$gallery__thumb-dot-border-radius--dots     : 100%;
$gallery__thumb-border-active--dots         : 0;

$gallery__placeholder-width                 : auto;
$gallery__placeholder-padding-bottom        : $spacer--medium;
$gallery__placeholder-padding-bottom\@medium: 0;
$gallery__placeholder-transform\@large      : translate(calc(-50% + #{$spacer}), -50%);
$gallery__placeholder-padding-right\@large  : calc(#{$gallery__thumb-width\@medium + 2px});
