@import 'contact-page-variables';

.contact-index-index {
    .page-main {
        max-width: $contact-page__max-width;
    }

    .heading {
        margin-bottom: 26px;
    }
}

#maincontent {
    .pccf.labelabove .panel-body {
        padding: 0;
    }

    .contact-page-columns .pagebuilder-column-line {
        column-gap: 4rem;
    }
}


.pccform {
    fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: none;
        padding: 0;
        margin: 0;
    }

    .form-group {
        margin: 0 0 $contact-page__spacer--medium-low !important;

        &.input-text {
            width: calc(50% - 10px);
        }

        &.textarea-field {
            width: 100%;
        }

        &.upload-field {
            width: 100%;
            justify-content: space-between;
            padding-bottom: 24px;

            & > div {
                width: calc(50% - 10px);
            }

            .upload-info {
                span {
                    font-size: $contact-page__font-size-extra-small;
                    line-height: $contact-page__font-line-height-low;
                    color: $contact-page__gray-darker;
                    display: block;

                    &.span-title {
                        font-weight: 600;
                    }
                }

            }

            .fs-upload-target {
                min-height: auto;
                margin-top: $contact-page__spacer--base;
                padding: 0;
                border: none;

                .dz-message {
                    margin: $contact-page__spacer--small 0;
                    font-size: $contact-page__font-size-medium;
                    background: $contact-page__gray-lighter;
                    border: 1px solid $contact-page__grey-light;
                    border-radius: $contact-page__border-radius;
                    height: $contact-page__button-height;
                    display: block;
                    line-height: $contact-page__font-line-semi-large;

                    &:hover {
                        opacity: $contact-page__opacity;
                    }

                    &:before {
                        content: '';
                        background-image: url("../images/icons/upload_icon.svg");
                        width: $contact-page__button-icon-width;
                        height: $contact-page__button-icon-height;
                        display: inline-block;
                        margin-right: $contact-page__spacer--medium-low;
                        transform: translateY($contact-page__spacer--extra-small);
                    }
                }

                .dz-image,
                .dz-error-message,
                .dz-success-mark,
                .dz-error-mark {
                    display: none;
                }

                .dz-preview {
                    display: flex;
                    margin: 0;
                    justify-content: space-between;
                    min-height: auto;
                    max-width: 100%;


                    .dz-details {
                        position: static;
                        padding: 0;
                        display: flex;
                        min-width: auto;
                        opacity: 1;
                        width: 70%;
                        overflow: hidden;

                        .dz-filename {
                            max-width: 70%;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            &:not(:hover) span {
                                border: none;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                padding: 0 5px;
                            }

                            &:hover span {
                                border: none;
                                background: none;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                padding: 0 5px;
                            }
                        }

                        .dz-size {
                            width: 30%;

                            span {
                                padding: 0;
                            }
                        }
                    }

                    .dz-remove {
                        position: static;
                        opacity: 1;
                    }

                    .dz-progress {
                        position: absolute;
                        right: 0;
                        left: auto;
                        top: 0;
                        margin-top: $contact-page__spacer--extra-small;
                        margin-left: 0;
                    }
                }
            }
        }

        &.multiple-checkboxes-field {
            width: 100%;

            label.col-md-3.control-label {
                display: none !important;
            }

            .checkbox {
                .checkbox__icon--checked {
                    &:after {
                        content: '';
                        background-image: url("../images/icons/checked.svg");
                        width: $contact-page__icon-width;
                        height: $contact-page__icon-height;
                        display: inline-block;
                        margin: $contact-page__checkbox-margin;
                    }
                }

                .checkbox__icon--unchecked {
                    &:after {
                        content: '';
                        width: $contact-page__icon-width;
                        height: $contact-page__icon-height;
                        display: inline-block;
                        border: 1px solid $contact-page__gray-dark;
                    }
                }
            }

            & > div {
                display: flex;
                flex-wrap: wrap;

                .checkbox-inline {
                    width: 100%;
                    padding-left: $contact-page__spacer--larger;
                    font-size: $contact-page__font-size-base;
                    line-height: $contact-page__font-line-height;
                    color: $contact-page__gray-dark;
                    font-style: italic;

                    a {
                        color: $contact-page__blue;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &.button-field {
            width: 100%;
            margin: 0;

            label {
                display: none !important;
            }

            .button {
                width: 100%;
                margin-top: 0;
            }
        }

        textarea {
            min-height: $contact-page__textarea-min-height;
        }

        & > label.col-md-3.control-label {
            max-width: 100%;
            padding: 0;
            flex-basis: 100%;
            color: $contact-page__gray-dark-label;
            display: block;

            &::after {
                margin-left: 5px;
                color: $contact-page__gray-dark-label;
            }
        }
    }
}

.pccf_loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -90px;
    margin-top:-80px;
}

.pccformwrapper {
    position: relative;
    visibility: hidden;

    .panel-heading {
        text-align: center;
    }

    .panel-body {
        padding-top: 14px;
    }

    legend {
        text-align: center;
    }

    .messages .alert-danger {
        color: red;
        padding-bottom: 2rem;
    }
}

.contact-info-block {

    .map-link {
        display: block;
        margin: 0 15px;
    }

    .map-container {
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        width: 100%;
        margin: 2.5rem auto;
        padding: 1.5rem;
        max-width: 700px;
    }
}

.contact-bubble-wrapper {
    width: $contact-page__bubble-width;
    margin: 14px auto 0;
    padding: 16px 24px 16px 20px;
    background: $contact-page__gray-lighter;
    border: 1px solid $contact-page__grey-light ;
    box-shadow: $contact-page__box-shadow $contact-page__grey-light ;
    position: relative;
    border-radius: 3px;

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -$contact-page__triangle;
        width: 0;
        border-top: $contact-page__triangle solid $contact-page__gray-lighter;
        border-left: $contact-page__triangle solid transparent;
        border-right: $contact-page__triangle solid transparent;
    }

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -$contact-page__triangle-large;
        width: 0;
        border-top: $contact-page__triangle-large solid $contact-page__grey-light ;
        border-left: $contact-page__triangle-large solid transparent;
        border-right: $contact-page__triangle-large solid transparent;
    }
}

.contact-bubble {
    & > p {
        font-size: $contact-page__font-size-medium;
        line-height: $contact-page__font-line-height-larger;
        color: #333333;
        margin-bottom: 35px;
        font-weight: bold;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            margin-bottom: $contact-page__spacer--large;

            p {
                margin-bottom: 0;

                a {
                    font-size: $contact-page__font-size-medium;
                    line-height: $contact-page__font-line-height;
                    color: #333333;
                    text-decoration: none;
                    font-weight: 600;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                span {
                    display: block;
                    font-size: 12px;
                    line-height: $contact-page__font-line-height-large;
                    color: #333333;
                    font-weight: normal;
                }
            }

            .contact-page-icons {
                display: block;
                min-width: 50px;
                width: 50px;
                height: 50px;
                margin: 0 58px 0 34px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                &.icon-phone {
                    background-image: url("../images/icons/icon_phone.svg");
                    margin: 0 43px 0 20px;
                }

                &.icon-chart {
                    background-image: url("../images/icons/icon_chat.svg");
                    width: 60px;
                    height: 60px;
                    margin: 0 39px 0 15px;
                }

                &.icon-mail {
                    background-image: url("../images/icons/icon_mail.svg");
                    margin: 0 43px 0 20px;
                }
            }

            &:first-child {
                .contact-page-icons {
                    margin-top: 0;
                }
            }

            &:last-child {
                margin: -22px 0 0 0;
            }
        }
    }
}

@include mq(false, $screen-l) {
    .pccform {
        padding-right: $contact-page__spacer--medium-low-plus;

        .form-group.upload-field {
            & > div {
                width: 100%;
            }
        }
    }

    .contact-index-index {
        .contact-bubble-wrapper {
            max-width: 100%;
        }

        .contact-bubble {
            ul {
                li {
                    .contact-page-icons {
                        margin: 0 27px 0 35px;
                    }
                }
            }
        }

        .contact-info-block {
            padding-left: $contact-page__spacer--base;
        }
    }
}

@include mq(false, $screen-m + 1) {
    .contact-index-index {
        .heading {
            margin-bottom: 6px;
        }

        .pagebuilder-column-line {
            flex-wrap: wrap;
        }

        .contact-info-block {
            padding-left: 0;
            margin-left: -15px;
            width: calc(100% + 30px);
        }

        .contact-bubble-wrapper {
            width: calc(100% - 30px);
        }
    }

    .pccform {
        padding-right: 0;

        .form-group.input-text {
            width: 100%;
        }

        .form-group {
            margin: 0 0 15px !important;
        }
    }
}
