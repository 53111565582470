$message__padding               : $spacer $spacer--medium $spacer--medium;
$message__padding\@medium       : $spacer $spacer--extra-large;
$message__margin                : $spacer auto $spacer--medium + 4;
$message__font-size             : $font-size-small;
$message__transition            : $transition-base;
$message__background            : $white;
$message__background--success   : $white;
$message__background--error     : $white;
$message__color                 : $color-primary;
$message__color--success        : $color-primary;
$message__color--error          : $color-primary;
$message__border-width          : 1px 1px $spacer--extra-small 1px;
$message__border-color          : $border-color-base $border-color-base $color-info $border-color-base;
$message__border-style          : solid;
$message__border-color--success : $border-color-base $border-color-base $color-success $border-color-base;
$message__border-color--error   : $border-color-base $border-color-base $color-error $border-color-base;
$message__border-radius         : 0;
$message__icon-spacer           : $spacer--semi-medium;
$message__icon-width            : $spacer--semi-medium + $spacer--small;
$message__icon-order            : -1;
$message__icon-order--before     : 1;
$message__icon-margin           : 0 $spacer 0 0;
$message__icon-margin--before   : 0 0 0 $spacer;
$message__icon-fill             : $color-info;
$message__icon-fill--success    : $color-success;
$message__icon-fill--error      : $color-error;
$message__icon-fill--close      : $gray-dark;
$message__icon-bg-image         : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill}" d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm.667 10H6.333V9h1.334v1.333zm0-2.666H6.333v-4h1.334v4z"/></svg>');
$message__icon-bg-image--success: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill--success}" d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm-1.333 10L2.333 7l.94-.94 2.394 2.387 5.06-5.06.94.946-6 6z"/></svg>');
$message__icon-bg-image--eror   : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill--error}" d="M.667 13h14.666L8 .333.667 13zm8-2H7.333V9.667h1.334V11zm0-2.667H7.333V5.667h1.334v2.666z"/></svg>');
$message__icon-bg-image--close  : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path fill="#{$message__icon-fill--close}" d="M3.647 2.059L2.059 3.647 6.412 8 2.059 12.353 3.647 13.941 8 9.588l4.353 4.353 1.588-1.588L9.588 8l4.353-4.353-1.588-1.588L8 6.412z"/></svg> ');
