@import 'dashboard-variables';

.dashboard {
    &__actions {
        justify-content: space-between;
    }

    &__handler {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: $dashboard__handler-margin;
    }

    &__status {
        display: inline-block;
        padding: $dashboard__status-padding;
        border: $dashboard__status-border;
        border-radius: $dashboard__status-border-radius;
        font-size: $dashboard__status-font-size;
        vertical-align: middle;
    }

    &__order-date {
        color: $brew-text;

        span {
            font-weight: $dashboard__order-date-font-weight;
        }
    }

    &-form {
        margin-top: 0px !important;
    }

    &__form {
        padding: $dashboard__form-padding;
        margin-top: 0px !important;

        &:first-child {
            padding-left: $dashboard__form-padding-left--first-child;
        }

        &:last-child {
            padding-right: $dashboard__form-padding-right--last-child;
        }
    }

    &__product {
        padding: $dashboard__product-padding;
        border: $dashboard__product-border;
    }

    &__form {
        &--hidden {
            display: none;
        }
    }

    &__sidebar {
        @include mq($screen-l) {
            border: $dashboard__sidebar-border;
            border-width: $dashboard__sidebar-border-width;
            padding: $dashboard__sidebar-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $dashboard__sidebar-padding\@extra-large;
        }
    }

    &__content {
        @include mq($screen-l) {
            padding: $dashboard__content-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $dashboard__content-padding\@extra-large;
        }

        .list.margin-bottom-l {
            display: none;
        }
    }

    &__content-heading {
        margin: $dashboard__content-heading-padding;

        &--with-status {
            display: inline-block;
            margin: 0 $spacer--large 0 0;
            vertical-align: middle;
        }
    }

    &__title,
    &__title--wrapped,
    &__subtitle  {
        font-family: $dashboard__title-font-family;
    }

    &__title,
    &__title--wrapped {
        font-size: $dashboard__title-font-size;
        font-weight:  $dashboard__title-font-weight;
    }

    &__title {
        margin: $dashboard__title-margin;
        padding: $dashboard__title-padding;
        background: $dashboard__title-background;
        border: $dashboard__title-border;
        border-width: $dashboard__title-border-width;
    }

    &__subtitle {
        font-size:  $dashboard__subtitle-font-size;
    }

    &__link {
        display: inline-flex;
    }

    a.button:hover {
        color: $white;
    }

    .order-details-items.ordered {
        margin-bottom: 40px;

        .dashboard-table__title {
            display: none;
        }
    }

    .my-orders-search-advanced {
        margin-bottom: 20px;
        margin-top: 0px !important;
    }

    &#maincontent {
        @include mq(false, $screen-l) {
            margin-top: 0;

            #dashboard-navigation {
                margin-bottom: 0;
            }

            .actions-group.margin-bottom-xl {
                margin-bottom: 0;
            }

            .dashboard-form.contact-info {
                margin-bottom: 32px;
            }

            #customer_sku_csv {
                margin-bottom: 12px;
            }
        }
    }

    .info-block {
        color: $brew-text;
    }

    .block-addbysku {
        input {
            color: $brew-text;
        }
    }
}

#dashboard-navigation {
    margin-bottom: 50px;
}

.block {
    .block-content {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;

        .block-title {
            strong {
                font-size: $dashboard__subtitle-font-size;
                font-weight: 600;
            }
        }

        .box-content {
            font-size: $dashboard__subtitle-font-size;
        }
    }
}
