@import 'filter-variables';

.filter {
    &--checkbox {
        .filter__content {
            max-height: $filter__content-max-height--checkbox;
        }

        .checkbox__label {
            color: $color-primary;
            font-size: $filter__label-font-size--checkbox;
        }
        .checkbox--link {
            margin: $filter__margin--checkbox-link;
            @include mq($screen-m) {
                margin: $filter__margin--checkbox-link\@medium;
            }
        }
        .checkbox--link-checked {
            .checkbox__label {
                font-weight: $filter__label-font-wieght-checked--checkbox;
            }
        }
    }

    &--category {
        @include mq($screen-l) {
            padding: $filter__padding--category\@large;
            border: $filter__border--category\@large;
            border-width: $filter__border-width--category\@large;
        }
    }

    &__heading {
        margin: $filter__heading-margin;
        font-family: $filter__heading-font-family;
        font-size: $filter__heading-font-size;
        text-transform: $filter__heading-text-transform;
    }

    &__content {
        position: relative;
        overflow-y: $filter__content-overflow-y;
        padding: $filter__content-padding;
        @include mq($screen-m) {
            padding: $filter__content-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $filter__content-padding\@large;
        }
    }

    &__label-wrapper {
        display: flex;
        @include mq($screen-m) {
            padding: $filter__label-wrapper-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $filter__label-wrapper-padding\@large;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__swatch-item {
        margin: $filter__swatch-margin;

        @include mq($screen-l) {
            display: flex;
            justify-content: flex-start;
            flex-basis: $filter__swatch-size\@large;
            margin: $filter__swatch-margin\@large;
        }

        &.selected {
            .filter__swatch-option {
                border-color: $filter__swatch-option-selected-border-color;
            }
        }
    }

    &__swatch-option {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $filter__swatch-option-size;
        min-height: $filter__swatch-option-size;
        border: $filter__swatch-option-border;
        background-color: $filter__swatch-option-background;
        color: $filter__swatch-option-color;
        transition: $filter__transition;
        cursor: pointer;
        text-decoration: none;

        &:hover,
        &.focus-visible {
            text-decoration: none;
            border-color: $filter__swatch-option-border-color-hover;
            font-weight: $font-weight-bold;
            color: $color-primary;
        }

        &--active {
            font-weight: $font-weight-bold;
            border-color: $filter__swatch-option-border-color--active;
        }
    }

    &__color-item {
        padding: $filter__color-padding;

        @include mq($screen-l) {
            display: flex;
            justify-content: flex-start;
            flex-basis: $filter__color-size\@large;
        }

        &.selected {
            .filter__color-option {
                &:after {
                    border-color: $filter__color-option-after-border-color;
                }
            }
        }
    }

    &__color-option {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: $filter__color-option-width;
        width: $filter__color-option-width;
        height: $filter__color-option-height;
        max-height: $filter__color-option-height;
        text-decoration: none;
        margin: $filter__color-option-margin;

        &:after {
            content: '';
            position: absolute;
            top: $filter__color-option-after-top;
            left: $filter__color-option-after-left;
            width: $filter__color-option-after-size;
            height: $filter__color-option-after-size;
            background-color: transparent;
            border: $filter__color-option-border;
            border-radius: $filter__color-option-after-border-radius;
            transition: $filter__transition;
        }

        &--active,
        &:hover,
        &.focus-visible {
            text-decoration: none;

            &:after {
                border-color: $filter__color-option-after-border-color;
            }
        }
    }

    &__color-sample {
        width: $filter__color-sample-size;
        height: $filter__color-sample-size;
        margin: $filter__color-sample-margin;
        border: $filter__color-sample-border;
        border-radius: $filter__color-sample-border-radius;
    }

    &__color-label {
        max-width: 100%;
        font-size: $filter__color-label-font-size;
        color: $filter__color-label-color;
        word-break: break-all;
        text-align: center;

        @include visually-hidden();
    }
}
@include mq(false, $screen-s) {
    .dropdown-list__content--right .filter__content {
        left: auto !important;
        right: 0;
    }
}
