@import 'minicart-product-variables';

.minicart-product {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    padding: $minicart-product__padding;

    &__item {
        display: flex;
        flex-flow: row nowrap;
    }

    &:nth-child(even) {
        background: $brew-section-midtone;
    }

    &__image {
        flex-basis: $minicart-product__image-flex-basis;
        max-height: $minicart-product__image-flex-basis;
        flex-shrink: 0;

        img {
            width: $minicart-product__image-width;
            max-width: $minicart-product__image-max-width;
            height: auto;
        }
    }

    &__image-link {
        display: flex;
        align-items: center;
    }

    &__data {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        padding: $minicart-product__data-padding;
        max-height: 94px;

        @include mq(false, $screen-xl) {
            overflow: hidden;
        }
    }

    &__info {
        margin: $minicart-product__info-margin;
        display: flex;
        justify-content: space-between;
    }

    &__name {
        margin-bottom: $minicart-product__spacer;
        font-size: $minicart-product__name-font-size;
        line-height: $minicart-product__name-line-height;
        text-transform: $minicart-product__name-text-transform;
        font-weight: $font-weight-semibold;
        max-width: 140px;

        @include mq($screen-s) {
            max-width: 240px;
        }
    }

    &__link {
        text-decoration: $minicart-product__link-text-decoration;
        font-weight: $minicart-product__link-font-weight;
        color: $minicart-product__link-color;
        hyphens: auto;

        &:hover {
            text-decoration: $minicart-product__link-text-decoration;
        }
    }

    &__attributes {
        margin-bottom: $minicart-product__spacer;

        display: none; //hidden due to lack of design TO DO
    }

    &__attributes-button {
        display: flex;
        align-items: center;
        margin: $minicart-product__attributes-button-margin;
        padding: $minicart-product__attributes-button-padding;
        font-size: $minicart-product__attributes-button-font-size;

        .dropdown-list__icon {
            position: static;
            margin: $minicart-product__attributes-list-icon-margin;
            fill: $minicart-product__attributes-button-color;
        }

        &.dropdown-list__label {
            color: $minicart-product__attributes-button-color;
            background-color: $minicart-product__attributes-button-bg-color;

            &.focus-visible,
            &:hover {
                background-color: $minicart-product__attributes-button-bg-color;
            }
        }
    }

    &__attributes-list {
        padding: $minicart-product__attributes-list-padding;

        .list__label,
        .list__value {
            color: $minicart-product__attributes-color;
            font-size: $minicart-product__attributes-font-size;
            line-height: $minicart-product__attributes-line-height;
        }

        .list__label {
            padding: $minicart-product__attributes-label-padding;
            font-weight: $minicart-product__attributes-label-font-weight;
        }

        .list__value {
            padding: $minicart-product__attributes-value-padding;
        }
    }

    &__price {
        display: flex;
        font-size: $minicart-product__price-font-size;
        font-weight: $minicart-product__price-font-weight;
        color: $brew-text;
        flex-direction: column;
        align-items: flex-end;

        @include mq($screen-l) {
            justify-content: flex-end;
        }

        .price__value {
            display: block;
            font-size: $minicart-product__price-font-size;

            @include mq($screen-l) {
                text-align: right;
            }

            &--old {
                font-size: $minicart-product__price-font-size--old;

                @include mq($screen-l) {
                    margin: 0;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        @include mq(false, $screen-l) {
            align-items: baseline;
        }

        .minicart-product__label {
            margin-bottom: 0;
            color: $minicart-product__text-color;
            font-size: 13px;
            padding-bottom: 1px;

            @include mq(false, $screen-l) {
                margin-right: 0;
                font-size: 13px;
            }
        }
    }

    &__actions {
        display: flex;

        .minicart-product__button {
            background-color: transparent;
            height: 40px;
            padding-left: 0;
            padding-right: 0;
            margin-right: -5px;
            min-width: 20px;

            &.button__icon {
                width: 25px;
                min-width: 25px;
            }

            &.delete {
                position: relative;
                width: 10px;
                height: 10px;
                padding-bottom: 1px;

                &::after,
                &::before {
                    content: '';
                    display: block;
                    background-color: $minicart-product__remove-color;
                    width: 14px;
                    height: 3px;
                    position: absolute;
                    transition: $transition-base;
                }

                &::after {
                    transform: rotate(45deg);
                }

                &::before {
                    transform: rotate(-45deg);
                }

                &:hover {
                    &::after,
                    &::before {
                        background-color: $minicart-product__remove-hover-color;
                    }
                }
            }

            @include mq(false, $screen-l) {
                height: 20px;
                width: 14px;
            }
        }
    }

    &__field {
        padding: 0;
        background-color: transparent;
        border: none;
        color: $brew-titles;
        pointer-events: none;
        font-size: 13px;
    }

    &__qty {
        display: flex;
        margin: $minicart-product__qty-margin;

        @include mq($screen-l) {
            margin: $minicart-product__qty-margin\@medium;
        }
    }

    &__name-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-l) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            max-width: 240px;
        }
    }

    &__update {
        max-height: $minicart-product__update-btn-max-height;
        padding: $minicart-product__update-btn-padding;

        @include mq($screen-l) {
            padding: $minicart-product__update-btn-padding\@medium;
        }
    }
}
