.ct-listing-individuelle-produktion {

    .heading--page-full-width {
        margin-bottom: 50px;
    }

    .filter-area {
        display: flex;
        align-content: space-between;
        flex-flow: wrap;
        border-bottom: 1px solid $brew-border-dark;

        .filter-section {
            margin: 0;
            width: auto;
            justify-content: flex-start;

            .select2-container .select2-selection--single .select2-selection__rendered {
                background-color: $brew-section-midtone;
                color: $brew-text;
            }

            @include mq($screen-xl) {
                margin-left: auto;
            }
        }

        .search-section {
            width: 100%;
            display: flex;
            align-items: baseline;
            margin-bottom: 15px;

            @include mq(false, $screen-l) {
                flex-direction: column-reverse;

                #total-count {
                    margin-bottom: 15px;
                }
            }

            #total-count {
                display: flex;
                color: $brew-text;
                font-size: 20px;
                font-weight: 300;

                #visible-count {
                    margin-right: 2px;
                }
            }

            @include mq($screen-l) {
                width: 50%;
                padding-right: 24px;

                .search-bar {
                    width: 564px;
                    margin-right: 24px;
                    position: relative;
                }
            }
        }

        .filter-option {
            .checkbox__icon {
                width: 14px;
                height: 14px;
            }
            .checkbox__label {
                padding: 0 0 0 23px;
                font-size: $filters__middle-font-size;
                line-height: $filters__middle-line-height;
                color: $filters__grey-dark;
                width: max-content;
                margin-right: 20px;
                max-width: 325px;
                margin-bottom: 15px;
            }

            &.checked .checkbox__icon--checked {
                background-color: $brew-text;
                opacity: 1;
            }
        }
    }

    .widget-content-grid {
        margin-top: 40px;
        margin-bottom: 100px;
        grid-gap: 40px 20px;
        display: grid;
        list-style: none;
        padding-left: 0;
        grid-template-columns: repeat(2, 1fr);

        @include mq($screen-m) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($screen-xl) {
            grid-template-columns: repeat(5, 1fr);
        }

        .contenttype-listing-readmore {
            width: 100%;
        }

        .content-item {
            .tile-img {
                width: 100%;
                border-radius: 5px;
                aspect-ratio: 1;
                object-fit: cover;

                @include mq($screen-xl-plus) {
                    height: 220px;
                }
            }
            .tile-title {
                margin-top: 10px;
                color: #253746;
            }
            .ct-link:hover {
                text-decoration: none;
            }
        }
    }

    .filters__dropdown ul {
        .dropdown-list__label {
            padding: 4px 28px 5px 8px;

            span {
                font-size: 15px;
            }
        }

        .dropdown-list__icon {
            margin-right: 0;
        }

        .dropdown-list__item:last-child {
            margin-right: 0;
        }
    }
}
