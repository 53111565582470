$radio__size                    : 21px;
$radio__dot-size                : 11px;
$radio__margin                  : 0 0 $spacer--medium 0;
$radio__label-font-size         : $font-size-base;
$radio__label-color             : $color-secondary;
$radio__label-color-active      : $color-primary;
$radio__label-font-weight-active: $font-weight-normal;
$radio__label-line-height       : $font-line-height;
$radio__label-margin            : $spacer--small 0;
$radio__icon-margin-right       : $spacer;
$radio__icon-border             : 2px solid $brew-text;
$radio__icon-background         : $brew-text;
$radio__icon-transition         : $transition-base;
$radio__legend-margin           : $radio__margin;
$radio__text-width              : calc(100% - #{$radio__icon-margin-right + $radio__size});
