$cart-list-item__padding                           : $spacer--semi-medium $spacer--medium - 1;
$cart-list-item__padding\@medium                   : 19px 15px;
$cart-list-item__padding\@large                    : 25px 34px 25px 26px;
$cart-list-item__border-bottom                     : $border-base;
$cart-list-item__right-margin                      : 0 0 0 64px;
$cart-list-item__right-padding                     : 0 0 0 $spacer--medium;
$cart-list-item__right-margin\@medium              : 0;
$cart-list-item__right-padding\@medium             : 0;
$cart-list-item__image-width                       : 90px;
$cart-list-item__image-width\@medium               : 110px;
$cart-list-item__image-width\@x-large              : 120px;
$cart-list-item__content-padding                   : 0 0 0 $spacer--medium + 2;
$cart-list-item__content-padding\@x-large          : 0 0 0 $spacer--semi-large;
$cart-list-item__content-flex-basis                : calc(100% - 90px);
$cart-list-item__content-flex-basis\@medium        : calc(100% - 110px);
$cart-list-item__content-flex-basis\@x-large       : calc(100% - 120px);
$cart-list-item__name-font-family                  : $font-family-base;
$cart-list-item__name-font-size                    : $font-size-base;
$cart-list-item__name-font-size\@large             : $font-size-medium;
$cart-list-item__name-font-weight                  : $font-weight-semibold;
$cart-list-item__name-line-height                  : 20px;
$cart-list-item__name-line-height\@large           : 25px;
$cart-list-item__name-text-transform               : none;
$cart-list-item__attribute-color                   : $color-secondary;
$cart-list-item__label-color                       : $font-color-base;
$cart-list-item__label-line-height                 : 18px;
$cart-list-item__label-font-size                   : 16px;
$cart-list-item__label-font-weight                 : $font-weight-semibold;
$cart-list-item__price-font-size\@medium           : $font-size-medium;
$cart-list-item__qty-margin                        : 0;
$cart-list-item__qty-flex-direction\@medium        : column;
$cart-list-item__qty-padding\@x-large              : 0 $spacer--medium 0 0;
$cart-list-item__input-width                       : 110px;
$cart-list-item__input-height                      : 42px;
$cart-list-item__input-margin                      : 0 $spacer 0 0;
$cart-list-item__total-font-size                   : $font-size-medium;
$cart-list-item__total-line-height                 : 18px;
$cart-list-item__total-font-weight                 : $font-weight-semibold;
$cart-list-item__action-margin-right               : 8px;
$cart-list-item__actions-display                   : flex;
$cart-list-item__actions-display\@medium           : flex;
$cart-list-item__gift-dropdown-label-margin\@medium: 0 0 0 auto;
