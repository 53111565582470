@mixin add-to-button($iconDisplay) {
    @if ($iconDisplay == block) {
        padding: 0 $spacer;
        &:after {
            display: none;
            content: url("../images/icons/plus-light.svg");
        }
        .button__icon {
            display: $iconDisplay;
        }
        .button__text {
            display: $iconDisplay;
        }
    }
    @else {
        font-size: $button-font-size-large;
        padding: 0 $spacer--large;
        &:after {
            content: url("../images/icons/icon_shopping-cart.svg");
        }
        .button__icon {
            display: $iconDisplay;
            filter: brightness(0) invert(1);
        }
        .button__text {
            display: block;
        }
    }
}
