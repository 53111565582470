@import "slider-variables";

.slider {
    display: block;
    position: relative;
    width: $slider__width;
    height: auto;
    margin: $slider__margin;

    &__heading {
        display: flex;
        justify-content: $slider__heading-justify-content;
        align-items: start;
        font-size: 20px;
        margin: $slider__heading-margin;
    }

    &--loading {
        .slider__navigation,
        .slider__dots {
            display: none;
        }
    }

    &--products,
    &--brands {
        .slider__slides {
            display: flex;

            &.slick-initialized {
                display: block;
            }
        }

        .slider__item {
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    &--products {
        .slider__heading {
            margin: $slider__heading-margin--products;
        }

        .slider__item {
            padding: $slider__item-padding--products;
            background-color: $white;
            height: auto;
            min-width: $slider__item-min-width;
            margin-right: 10px;
            border-radius: $brew-standard-border-radius;
            margin-top: 8px;
            margin-bottom: 24px;

            @include mq($slider__navigation-display-breakpoint) {
                margin-right: 20px;
            }

            &:nth-child(n + 2) {
                display: flex;
            }

            &:nth-child(n + #{$slider__hidden-item-number--products}) {
                display: none;

                @include mq($screen-m) {
                    display: flex;
                }
            }

            &:nth-child(n + #{$slider__hidden-item-number\@medium--products}) {
                @include mq($screen-m) {
                    display: none;
                }

                @include mq($screen-l) {
                    display: flex;
                }
            }

            &:nth-child(n + #{$slider__hidden-item-number\@large--products}) {
                @include mq($screen-l) {
                    display: none;
                }
            }

            &:hover {
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
                transition: box-shadow 125ms;
            }
        }
    }

    &--brands {
        .slider__container {
            width: $slider__handler-max-width--brands;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            @include mq($screen-m) {
                width: $slider__handler-max-width\@medium--brands;
            }

            @include mq($screen-l) {
                width: $slider__handler-max-width\@large--brands;
            }

            @include mq($max-content-width) {
                width: $slider__handler-max-width\@max-width--brands;
            }

            &:before,
            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                z-index: $z-index-low;

                @include mq($slider__navigation-display-breakpoint) {
                    display: none;
                }
            }

            &:before {
                background: $slider__container-before-bg;
                right: 100%;
            }

            &:after {
                background: $slider__container-after-bg;
                left: 100%;
            }
        }

        .slider__handler {
            overflow: hidden;

            @include mq($slider__navigation-display-breakpoint) {
                overflow: visible;
            }
        }

        .slick-list {
            overflow: visible;

            @include mq($slider__navigation-display-breakpoint) {
                overflow: hidden;
            }
        }

        .slider__slides {
            justify-content: center;
        }

        .slider__item {
            max-width: $slider__item-width--brands;
            &:nth-child(n + 2) {
                display: block;

                .slick-initialized & {
                    display: flex;
                }
            }

            @include mq($screen-m) {
                max-width: $slider__item-width\@medium--brands;
            }

            @include mq($screen-l) {
                max-width: $slider__item-width\@large--brands;
            }

            @include mq($max-content-width) {
                max-width: $slider__item-width\@max-width--brands;
            }
        }
    }

    &--nested {
        .slider__slides {
            display: block;
        }
    }

    &--full-width {
        width: calc(100vw - #{$spacer} / 2);
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    &--catalog-events {
        max-width: $slider__max-width--catalog-events;
        margin: $slider__margin--catalog-events;
    }

    &__handler {
        position: relative;
    }

    &__slides {
        height: auto;

        .slick-list {
            @include mq(false, $slider__navigation-display-breakpoint) {
                padding-right: 10%;
                padding-left:  10%;
            }
        }
    }

    &__item {
        height: auto;
        display: flex;
        border: $brew-standard-border-dark;

        &:nth-child(n + 2) {
            display: none;

            .slick-initialized & {
                display: flex;
            }
        }
        .product-grid-item {
            border: 0;

            &__image-wrapper {
                max-width: 245px;

                .ratio-container {
                    padding-bottom: 100% !important;
                }
            }
            &__name {
                margin-top: 24px;
                color: $brew-text;
                font-size: $font-size-base;
                font-weight: $slider__link-font-weight;
                width: $slider__width;
                line-height: 19px;

                @include mq($slider__navigation-display-breakpoint) {
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }

    &__navigation {
        display: $slider__navigation-display;
        position: absolute;
        width: $slider__navigation-width;
        top: $slider__navigation-top;
        margin-top: -$slider__nav-button-size / 2;
        padding: 0;
        z-index: $z-index-low;

        @include mq($screen-m) {
            display: block;

            @include isIE() {
                top: $slider__navigation-IE-width;
            }
        }
    }

    &__nav-button {
        width: $slider__nav-button-size;
        height: 110px;
        min-width: $slider__nav-button-size;
        border-radius: $slider__nav-button-border-radius;
        background-color: $white;
        padding: 0;
        position: absolute;
        border: none;
        opacity: 0.85;

        &:hover {
            background-color: $white;
            opacity: 1;

            .slider__nav-icon {
                width: $slider__nav-icon-size + 10px;
                height: $slider__nav-icon-size + 10px;
            }
        }
    }

    &__nav-icon {
        width: $slider__nav-icon-size;
        height: $slider__nav-icon-size;
        fill: $brew-border-gray;
        transition: $transition-base;
    }

    &__prev {
        @include mq($screen-xxl) {
            left: $slider__prev-left;
        }

        &:before {
            transform-origin: 100% 0;
        }
    }

    &__next {
        left: auto;
        right: 0;
        @include mq($screen-xxl) {
            right: $slider__next-right;
        }

    }

    &__dots {
        display: block;
        position: relative;
        list-style: none;
        margin: $slider__dots-margin;
        padding: $slider__dots-padding;
        text-align: center;
        width: 100%;
        left: 0;
        bottom: $slider__dots-bottom;

        li {
            display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;

            &.slick-active {
                &:before {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    content: '';
                    width: $slider__dot-size;
                    height: $slider__dot-size;
                    padding: 0;
                    background: $slider__dot-background-color--active;
                    border-radius: 50%;
                    top: 50%;
                    margin-top: $slider__dot-size;
                }
            }

            &:only-child {
                display: none;
            }
        }

        button {
            display: block;
            position: relative;
            width: $slider__dot-size;
            height: $slider__dot-size;
            padding: 0;
            background-color: $slider__dot-background-color;
            border: 1px solid $slider__dot-background-color--active;
            border-radius: 50%;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            margin: $slider__dots-margin;
        }
    }

    &__link {
        align-items: center;
        font-size: $slider__link-font-size;
        font-weight: $slider__link-font-weight;
        min-height: $slider__link-min-height;
        text-align: $slider__link-text-align;

        &--button,
        &--button-secondary {
            @extend .button;
        }

        &:hover {
            text-decoration: none;
        }
    }
    &__after {
        padding: $slider__after-padding;
        text-align: $slider__after-text-align;
    }
    .product-grid-item:hover {
        box-shadow: none;
    }

    @include mq($screen-m) {
        .slick-track {
            margin-left: 4px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // Applies for Related Products, Cross-Sell and Up-Sell Products carousels
    &--promotions {
        margin: -8px -3px 0;

        .slider__item {
            min-height: 499px;
            border-radius: 3px;
        }
        .product-grid-item {
            max-width: 240px;
        }
        .slider__item .product-grid-item__image-wrapper {
            height: 230px;

            .ratio-container {
                padding-bottom: 145% !important;
            }
        }
        .product-grid-item__name {
            margin-top: 10px;
            margin-bottom: 15px;
        }
        .price-note {
            font-size: $font-size-small;
            line-height: 21px;
            color: $brew-text--light-grey2;
            white-space: normal;
            padding-left: 1ch;
        }
        .product-grid-item__price .price__value:not(.price__value--between) {
            flex-direction: row;
        }
        .price-box {
            display: flex;
            align-items: flex-end;

            .price__value {
                margin-right: 10px;
            }
            .price {
                display: flex;
                align-items: flex-start;
                flex-flow: column;
            }
            .sales-unit {
                white-space: nowrap;
            }

            .bottom-price-note, a {
                display: none;
            }
            .product-grid-item__link {
                align-items: baseline;
                flex-flow: row;
                display: flex !important;
            }
        }

        .product-item-details .badges {
            margin-left: -15px;
            width: calc(100% + 30px);
            top: -6px;
        }

        .product-grid-item__delivery-info {
            width: 100%;
            min-height: 19px;
            color: $brew-text;
        }

        @include mq(false, $screen-xl) {
            .slider__container {
                margin-left: 7px;
            }
        }
    }

    .price-note-shipping {
        display: none;
    }

    &--products--magazin {
        @include mq(false, $screen-m) {
            margin: 0 8px;
        }

        .button--add-to {
            display: none;
        }

        .slider__item {
            padding-top: 45px;

            .product-grid-item__name {
                margin-top: 90px;
            }
        }
    }
}
// fix height of recently viewed products item
// fix position of recently viewed product name
.recently-viewed {
    .slider {
        &--products {
            .slider__item {
                height: $slider__recently-viewed-min-height;
            }
        }
        &--products--magazin {
            .slider__item {
                .product-grid-item__name {
                    margin-top: 45px;
                }
            }
        }
    }
}

.magazin-footer__carousel {
    background-color: $brew-section-midtone;
    margin-top: 50px;

    @include mq($screen-l) {
        margin-top: 90px;
    }

    .section__content--products {
        padding-top: 8px;

        @include mq($screen-m) {
            padding-bottom: 50px;
        }
    }

    .slider__heading {
        @include mq($screen-m) {
            margin: 42px 0 38px;
        }
    }

    .slider--products {
        .slider__dots {
            margin-top: 0;
            margin-bottom: 33px;
        }
    }

    .product-grid-item:hover .product-grid-item__image-wrapper:after,
    .product-grid-item:focus-within .product-grid-item__image-wrapper:after {
        background: transparent;
    }

    .product-grid-item__link:hover {
        text-decoration: none;
    }

    .section__content--products {
        margin: 0 8px;
    }

    .slider__container {
        margin-left: 0;

        .slider__item {
            .product-grid-item__image-wrapper {
                margin: 0 auto;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
