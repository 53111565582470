$minicart-product__text-color                   : $brew-titles;
$minicart-product__remove-color                 : #A8A8A8;
$minicart-product__remove-hover-color           : $brew-text;

$minicart-product__spacer                       : $spacer;
$minicart-content__padding-sides                : 18px;
$minicart-product__padding                      : $minicart-content__padding-sides;

$minicart-product__image-width                  : auto;
$minicart-product__image-max-width              : 100%;
$minicart-product__image-flex-basis             : 85px;

$minicart-product__data-padding                 : 0 0 0 $spacer--medium;

$minicart-product--info-margin                  : 0 0 $spacer;

$minicart-product__name-line-height             : 19px;
$minicart-product__name-font-size               : $font-size-base;
$minicart-product__name-text-transform          : none;

$minicart-product__link-text-decoration         : none;
$minicart-product__link-font-weight             : $font-weight-semibold;
$minicart-product__link-color                   : $brew-titles;

$minicart-product__attributes-button-margin     : 0;
$minicart-product__attributes-button-padding    : 0;
$minicart-product__attributes-button-color      : $blue;
$minicart-product__attributes-button-bg-color   : $white;
$minicart-product__attributes-button-font-size  : $font-size-small;

$minicart-product__attributes-color             : $brew-text;
$minicart-product__attributes-font-size         : $font-size-small;
$minicart-product__attributes-line-height       : 16px;
$minicart-product__attributes-label-padding     : 0 $spacer 0 0;
$minicart-product__attributes-label-font-weight : $font-weight-bold;
$minicart-product__attributes-value-padding     : 0;
$minicart-product__attributes-list-padding      : 0;
$minicart-product__attributes-list-icon-margin  : 0 0 0 $spacer;

$minicart-product__price-font-size              : $font-size-medium;
$minicart-product__price-font-weight            : $font-weight-bold;
$minicart-product__price-font-size--old         : $font-size-small;
$minicart-product__price-align                  : center;

$minicart-product__action-icon-color            : $brew-icon-gray;
$minicart-product__action-icon-color--active    : $white;

$minicart-product__field-max-width              : 26px;

$minicart-product__qty-margin                   : 0 0 $spacer;
$minicart-product__qty-margin\@medium           : 0;

$minicart-product__update-btn-padding           : 0 $spacer;
$minicart-product__update-btn-padding\@medium   : 0 $spacer--large;
$minicart-product__update-btn-max-height        : 48px;
