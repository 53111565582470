$magazin-widget__padding: 37px 7px;
$magazin-widget__padding\@large: 60px 0;
$magazin-widget__title-indent: 30px;
$magazin-widget__title-font-size: 18px;
$magazin-widget__title-font-size\@large: 20px;

$magazin-widget__main-size: 61%;
$magazin-widget__main-padding: 0 20px 32px 0;
$magazin-widget__main-border: 1px solid $brew-border;

$magazin-widget__main-item-padding: 14px 20px 17px 16px;
$magazin-widget__main-item-margin: 56px;
$magazin-widget__main-item-margin\@large: 59px;
$magazin-widget__main-item-image-size: 32%;

$magazin-widget__sidebar-size: 36.6%;
$magazin-widget__sidebar-padding: 4%;

$magazin-widget__item-padding: 19px 15px;

$magazin-widget__hover-shadow: 0 3px 10px rgba(0, 0, 0, 0.19);
