// ----------------------------------------------------
// @author    Alex Mischarin <Alex.Mischarin@brewes.de>
// @copyright 2023 Brewes GmbH
// @link      https://www.brewes.de/
// ----------------------------------------------------
// CSS for Magento2 Page Builder Content-Type `Tile`
// ----------------------------------------------------

@import 'brewes-tile-variables';

#html-body .pagebuilder-column--wide[data-content-type="column"] {
   width: calc(40% + #{$brew-tile-column-gap} + 1px);
}

.brewes_homepage--top-category-row {

   .pagebuilder-column-group {
      margin-top: 25px;
   }
}

.brewes_tile {

   &-column-line {
      column-gap: $brew-tile-column-gap;

      .pagebuilder-column {
         row-gap: $brew-tile-row-gap;
         margin-bottom: $brew-tile-row-gap;
      }
   }

   &--image {
       position: absolute;
       background-size: cover;
       height: 100%;
       max-width: unset;
       min-width: 100%;
       min-height: 100%;
       transition: transform 100ms ease-in-out;
   }

   &--container {
      position: relative;
      height: 0;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;

       &:hover {
           .brewes_tile--image {
               transform: scale(1.2);
           }
       }

      a {
         display: block;
         width: 100%;
         height: 100%;
      }
   }

   &--filler {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
   }

   &--text {
      position: absolute;
      bottom: 0;
      background-color: $brew-tile-title-background;
      width: 100%;
      padding: 9px 16px;
      color: $brew-tile-text-color;
   }

   &--fulltext {
      height: 100%;
      background-color: #EFEFEF;
      display: flex;
      align-items: center;

      .brewes_tile--text {
         position: unset;
         background-color: unset;
         font-size: 30px;
         text-align: center;
         font-weight: 600;
         color: #5C6166;
      }
   }

   &--header-container {
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: $brew-tile-header-background;
      padding: 10px 10px 20px 10px;

      .brewes_tile {

         &--header,
         &--text {
            color: $brew-tile-header-color;
            text-align: center;
            font-style: italic;
         }

         &--header {
            font-size: 45px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 10px;
         }

         &--text {
            position: initial;
            background-color: unset;
            padding: 0;
         }
      }
   }

   &--indicator-container {
      display: none;
   }

   &--container:hover {

      .brewes_tile--image {
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
      }

      .brewes_tile--header,
      .brewes_tile--text {
         color: $brew-tile-text-hover;
      }

      .brewes_tile--header-container {
         .brewes_tile--header,
         .brewes_tile--text {
            color: $brew-tile-header-hover;
         }
      }

      .brewes_tile--fulltext .brewes_tile--text {
         color: $brew-tile-header-hover;
      }
   }
}

.pagebuilder-column-group--mobile {
   display: none;
}

@media screen and (max-width: 1200px) {

   #html-body .brewes_homepage--top-category-row,
   #html-body .brewes_homepage--world-theme {
      padding-left: 15px;
      padding-right: 15px;

      .row-full-width-inner[data-element="inner"] {
         width: 100% !important; // fix critical css
         margin: 0 auto !important;
      }

      .pagebuilder-column-group {
         overflow-y: hidden;
         scrollbar-width: none;
      }

      .pagebuilder-column-group::-webkit-scrollbar {
         display: none;
      }

      .brewes_tile {

         &-column-line {
            flex-direction: row;
            flex-wrap: nowrap;
            width: auto;
            column-gap: 10px;
            touch-action: auto;

            .pagebuilder-column {
               width: auto !important; // fix layout css
               flex-direction: row;
               flex-wrap: nowrap;
               column-gap: 10px;
               margin-bottom: 0;

               .brewes_tile--container {
                  width: 270px;
                  height: 270px;
                  padding-bottom: 0;
               }
            }

            .pagebuilder-column--wide {
               display: none;
            }
         }

         &--indicator-container {
            width: 100%;
            margin: 20px 0 30px 0;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 10px;
            align-items: center;
            justify-content: center;
         }

         &--indicator {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            border: 1px solid #5C6166;
         }

         &--indicator.active {
            background-color: #5C6166;
         }
      }

      .pagebuilder-column-group--mobile {
         display: block;
         width: 100%;

         .pagebuilder-column {
            width: 50%;
            margin: 0 auto;
         }
      }
   }

}

@media screen and (max-width: 1080px) {

   #html-body .brewes_homepage--top-category-row {

      .brewes_tile {
         &-column-line {

            .pagebuilder-column--wide {
               width: 50% !important;
               margin: 0 auto;
            }
         }
      }

      .pagebuilder-column-group--mobile {

         .pagebuilder-column {
            width: 560px;
         }
      }
   }

}

@media screen and (max-width: 768px) {

   #html-body .brewes_homepage--top-category-row {

      .pagebuilder-column-group--mobile {

         .pagebuilder-column {
            width: 100%;
         }
      }
   }

}
