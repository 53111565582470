@import 'active-filters-variables';

.active-filters {
    padding: $active-filters__padding;
    margin-bottom: 20px;
    order: 1;

    @include mq($screen-l) {
        padding: $active-filters__padding\@large;
        border-width: $active-filters__border-width\@large;
    }

    &__heading {
        font-family: $active-filters__heading-font-family;
        font-weight: $active-filters__heading-font-weight;
        font-size: $active-filters__font-size-middle;
        line-height: $active-filters__line-height-small;
        color: $active-filters__grey-dark;
        margin: 0;
    }

    &__header {
        display: none;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
    }

    &__item {
        display: flex;
        align-items: center;
        background: rgba(54, 131, 197, 0.1);
        border: 1px solid $active-filters__blue;
        border-radius: 3px;
        margin: $active-filters__remove-margin;
        padding: 0 15px;
        transition: $transition-base;

        @include mq($screen-m) {
            margin: $active-filters__remove-margin\@large;
        }

        &:hover {
            background: rgba(54, 131, 197, 0.3);
        }
    }

    &__description {
        display: flex;
    }

    &__individual-description{
        display: flex;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;

        &:hover{
            cursor: pointer;
        }
    }

    &__remove {
        background-color: transparent;
        display: block;
        height: auto;
        line-height: 40px;
        padding: 0;

        &:hover {
            text-decoration: none;
        }

        strong {
            font-size: $active-filters__font-size-big;
            color: $active-filters__blue;
            font-weight: 700;
            margin-left: 8px;
        }
    }

    &__label {
        font-size: $active-filters__font-size-middle;
        color: $active-filters__blue;
        font-weight: 400;
    }

    &__value {
        font-size: $active-filters__font-size-middle;
        color: $active-filters__blue;
        font-weight: $active-filters__value-font-weight;
        text-transform: $active-filters__value-text-transform;
    }

    &__clear-all {
        color: $active-filters__blue-2;
        font-size: $active-filters__font-size-middle;
        line-height: $active-filters__line-height-small;

        @include mq($screen-m) {
            max-width: $active-filters__clear-all-max-width\@medium;
        }

        &:hover {
            color: $active-filters__blue-2;
        }
    }

    .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.filter-anchor {
    background: $active-filters__blue;
    transition: $transition-base;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    color: $active-filters__white;
    font-size: $active-filters__font-size-small;
    padding: 0 14px;
    position: fixed;
    bottom: 25vh;
    z-index: 2;
    right: 15px;

    &:hover {
        background-color: $active-filters__blue-3;
        color: $active-filters__white;
        text-decoration: none;
    }

    .icon--filter {
        width: 14px;
        height: 14px;
        margin-right: 9px;
    }

    span {
        background-color: $active-filters__white;
        color: $active-filters__blue;
        font-size: $active-filters__font-size-small;
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-left: 10px;
        text-align: center;
        line-height: $active-filters__line-height-middle;
    }

    &.fix-sidebar-promo-position {
        bottom: calc(25vh + 63px);
    }

    @include mq($screen-l) {
        bottom: 50vh;
    }

    @include mq($screen-m) {
        right: 26px;
    }
}
