$footer__background-color                                  : $brew-footer;
$footer__flex-shrink                                       : 0;
$footer__border-width                                      : 1px 0 0 0;
$footer__background-color--email                           : $white;
$footer__padding                                           : 3px 0 15px;
$footer__padding\@large                                    : 58px 0 41px;
$footer__brew-padding                                      : 0 15px;
$footer__brew-padding\@large                               : 0 5px;
$footer__top-padding-bottom                                : 16px;
$footer__top-padding-bottom\@large                         : 33px;
$footer__top-margin-bottom                                 : 21px;
$footer__top-margin-bottom\@large                          : 38px;
$footer__border                                            : 1px solid $brew-border;
$footer__item-content-padding                              : 0 25px 20px;
$footer__item-content-padding\@large                       : 0;

$footer__handler-position                                  : relative;
$footer__handler-display                                   : flex;
$footer__handler-flex-direction                            : column;
$footer__handler-padding                                   : $spacer--medium $spacer--semi-medium;
$footer__handler-padding\@medium                           : $spacer--medium $spacer--semi-large;
$footer__handler-padding\@large                            : $spacer--medium 0;
$footer__handler-flex-flow\@large                          : row wrap;

$footer__newsletter-width\@large                           : 75%;
$footer__newsletter-margin                                 : 0 $spacer--medium;
$footer__newsletter-padding\@large                         : $spacer--medium $spacer--extra-large $spacer--extra-large $spacer--semi-medium;
$footer__newsletter-margin\@large                          : 0;
$footer__newsletter-border                                 : $spacer--extra-small solid $gray-light;
$footer__newsletter-border-width                           : 0 0 $spacer--extra-small 0;

$footer__link-display                                      : block;
$footer__link-font-size                                    : $font-size-medium;
$footer__link-color                                        : $gray-darker;
$footer__link-color-hover                                  : $color-primary;
$footer__link-font-weight                                  : $font-weight-base;
$footer__link-margin                                       : 0;
$footer__link-margin\@medium                               : 0;
$footer__link-text-decoration                              : none;

$footer__links-width                                       : 100%;
$footer__links-padding                                     : 0;
$footer__links-padding\@large                              : 0 0 $spacer--large;
$footer__links-dropdown-background                         : $gray-lighter;
$footer__links-dropdown-padding\@medium                    : 0;
$footer__links-label-background                            : $gray-lighter;
$footer__links-label-color                                 : $color-primary;
$footer__links-content-margin                              : 0;

$footer__links-dropdown-list-margin                        : 0 0 $spacer--semi-medium;
$footer__links-dropdown-list-padding\@medium               : $spacer--large 0 0;
$footer__links-dropdown-list-padding\@large                : $spacer--large $spacer--extra-large 0;
$footer__links-dropdown-list-padding\@extra-large          : $spacer--large $spacer--semi-medium 0;
$footer__links-dropdown-list-border                        : $spacer--extra-small solid $gray-light;
$footer__links-dropdown-list-border-width                  : 0 0 $spacer--extra-small 0;
$footer__links-dropdown-list-border-width\@large           : 0;
$footer__links-dropdown-list-item-width                    : 100%;
$footer__links-dropdown-list-item-width-first-child\@medium: 50%;
$footer__links-dropdown-list-item-border                   : $border-base;
$footer__links-dropdown-list-item-border\@medium           : none;
$footer__links-dropdown-list-item-border-last-child        : none;
$footer__links-dropdown-icon-color                         : $color-secondary;
$footer__links-dropdown-icon-color-hover                   : $color-primary;
$footer__links-dropdown-icon-color-open                    : $color-primary;

$footer__links-list-item-min-width\@medium                 : 204px;
$footer__links-list-padding                                : 0 0 $spacer--medium 0;

$footer__social-order                                      : 1;
$footer__social-order\@large                               : 0;

$footer__social-handler-display                            : flex;
$footer__social-handler-flex-direction                     : column;
$footer__social-handler-flex-direction\@medium             : row;
$footer__social-handler-flex-direction\@large              : column;
$footer__social-handler-align-items                        : flex-start;
$footer__social-handler-align-items\@medium                : center;
$footer__social-handler-flex\@large                        : 1;
$footer__social-handler-margin                             : $spacer 0;
$footer__social-handler-margin\@large                      : 0;
$footer__social-handler-padding                            : 0 $spacer--medium;
$footer__social-handler-padding\@large                     : 56px $spacer--large;
$footer__social-handler-position\@large                    : relative;
$footer__social-handler-before-position\@large             : absolute;
$footer__social-handler-before-left\@large                 : 0;
$footer__social-handler-before-top\@large                  : 0;
$footer__social-handler-before-width\@large                : 1px;
$footer__social-handler-before-height\@large               : 90%;
$footer__social-handler-before-background\@large           : $gray;

$footer__social-list-title-display                         : flex;
$footer__social-list-title-align-items                     : center;
$footer__social-list-title-font-size                       : $font-size-large;
$footer__social-list-title-font-family                     : $font-family-base;
$footer__social-list-title-margin                          : 0 0 $spacer--medium 0;
$footer__social-list-title-margin\@medium                  : 0 $spacer--large 0 0;
$footer__social-list-title-margin\@large                   : 0 0 $spacer--large 0;
$footer__social-list-title-font-weight                     : $font-weight-bold;
$footer__social-list-title-color                           : $color-primary;
$footer__social-list-title-align-self\@large               : flex-start;

$footer__bottom-bar-background                             : $color-primary;
$footer__bottom-bar-color                                  : $gray-lighter;
$footer__bottom-min-height                                 : 80px;
$footer__bottom-padding\@large                             : 0 $spacer--semi-large;
$footer__bottom-padding\@xl                                : 0;
$footer__bottom-display                                    : flex;
$footer__bottom-align                                      : center;
$footer__bottom-flex-direction                             : column;
$footer__bottom-flex-direction\@medium                     : row;
$footer__bottom-justify-content\@medium                    : space-between;
$footer__bottom-flex-wrap\@medium                          : wrap;

$footer__copywrite-display                                 : block;
$footer__copywrite-order                                   : 2;
$footer__copywrite-order\@medium                           : 1;
$footer__copywrite-padding                                 : $spacer 0;
$footer__copywrite-padding\@medium                         : $spacer--medium 0;
$footer__copywrite-text-align                              : center;
$footer__copywrite-font-size                               : $font-size-medium;
$footer__copywrite-color                                   : $gray-lighter;

$footer__payments-list-justify-content                     : space-around;
$footer__payments-list-margin                              : 0 $spacer--semi-medium;
$footer__payments-list-margin\@medium                      : 0 0 0 $spacer--semi-medium;
$footer__payments-list-flex-flow\@medium                   : row wrap;
$footer__payments-list-order\@medium                       : 2;
$footer__payments-list-icon-width                          : 64px;
$footer__payments-list-icon-height                         : 48px;
$footer__payments-list-icon-width--visa                    : 68px;
$footer__payments-list-icon-width--paypal                  : 96px;
$footer__payments-list-icon-fill                           : $gray-lighter;

$footer__scroll-top-position                               : absolute;
$footer__scroll-top-top                                    : -49px;
$footer__scroll-top-right                                  : 0;
$footer__scroll-top-before-transform                       : scaleX(1);
$footer__scroll-top-before-background                      : none;

$footer__scroll-top-icon-fill                              : $white;
$footer__scroll-top-bg                                     : $color-primary;
$footer__scroll-top-bg-hover                               : $color-primary;

$footer__social-list-align-self\@large                     : flex-start;
$footer__social-list-justify-content\@large                : flex-start;
$footer__social-list-flex-wrap\@large                      : wrap;

$footer__social-list__item-padding                         : 0;
$footer__social-list__item-margin                          : 0 $spacer 0 0;
$footer__social-list__item-margin-last-child               : 0;

$footer__social-list__icon-link-size                       : 48px;
$footer__social-list__icon-link-padding                    : 0;
$footer__social-list__icon-link-margin\@large              : 0 0 $spacer;

$footer__switchers-display                                 : flex;
$footer__switchers-align-items                             : center;
$footer__switchers-flex-direction                          : column;
$footer__switchers-flex-direction\@small                   : row;
$footer__switchers-justify-content\@small                  : center;
$footer__switchers-padding                                 : $spacer 0;
$footer__switchers-width\@small                            : 100%;
$footer__switchers-width\@xl                               : auto;
$footer__switchers-order                                   : 3;

$footer__switcher-width                                    : auto;
$footer__switcher-background-color                         : transparent;

$footer__switcher-label-background-color                   : $gray-darkest;
$footer__switcher-label-padding                            : $spacer--small $spacer--semi-large $spacer--small $spacer--small;
$footer__switcher-label-color                              : $white;
$footer__switcher-label-white-space                        : nowrap;

$footer__switcher-content-position                         : absolute;
$footer__switcher-content-bottom                           : 100%;
$footer__switcher-content-width                            : 100%;
$footer__switcher-content-padding                          : $spacer--small $spacer--small 0;

$footer__switcher-button-font-weight                       : $font-weight-normal;
$footer__switcher-button-min-height                        : 0;
$footer__switcher-button-text-decoration                   : none;
$footer__switcher-button-hover-text-decoration             : underline;

// Footer Checkout variant
$footer__border--checkout                                  : $border-secondary;
$footer__border-width--checkout                            : 0;
$footer__background-color--checkout                        : $white;

$footer__bottom-bar-handler-padding--checkout              : $spacer--semi-medium 0 0 0;
$footer__bottom-bar-handler-padding--checkout\@medium      : 0;
$footer__bottom-bar-handler-padding--checkout\@large       : 0;
$footer__bottom-bar-handler-padding--checkout\@extra-large : 0;
