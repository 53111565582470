@import 'order-summary-variables';

.order-summary {
    padding: $order-summary__padding;
    background: $order-summary__color-midtone;
    border: 1px solid $order-summary__color-light-grey;
    margin: $order-summary__margin;
    border-radius: $order-summary__radius;
    display: flex;
    flex-direction: column;

    @include mq(false, $screen-l) {
        border-radius: 0;
        padding: $order-summary__padding-m;
        margin-left: -22px;
        width: calc(100% + 44px);
    }

    @include mq(false, $screen-m) {
        margin-left: -7px;
        width: calc(100% + 14px);
    }

    &__title {
        margin: $order-summary__title-margin;
        font-family: $order-summary__title-font-family;
        font-size: $order-summary__title-font-size;
        font-weight: $order-summary__title-font-weight;
        text-transform: $order-summary__title-text-transform;
    }

    &__title-total {
        margin: $order-summary__title-total-margin;
        font-family: $order-summary__title-total-font-family;
        font-size: $order-summary__title-total-font-size;
        line-height: $order-summary__label-light-height;
        font-weight: $order-summary__title-total-font-weight;
        text-transform: $order-summary__title-total-text-transform;
        color: $order-summary__color-titles;
    }

    &__amount {
        margin: $order-summary__amount-margin;
        font-family: $order-summary__amount-font-family;
        font-size: $order-summary__amount-font-size;
        line-height: $order-summary__label-light-height;
        font-weight: $order-summary__amount-font-weight;
        text-transform: $order-summary__amount-text-transform;
        color: $order-summary__color-titles;
    }

    &__subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $order-summary__subtitle-margin;
        font-family: $order-summary__subtitle-font-family;
        font-size: $order-summary__subtitle-font-size;
        font-weight: $order-summary__subtitle-font-weight;
        text-transform: $order-summary__subtitle-text-transform;
        cursor: pointer;
    }

    &__list {
        margin: $order-summary__list-margin;
        padding: 0;
        list-style: $order-summary__list-style;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;

        &--secondary {
            justify-content: flex-start;
        }

        .discount.coupon,
        .order-summary__label.shipping-title span {
            margin-right: -4px;
        }

        .excl-shipping-costs-block {
            display: block;
        }
    }

    &__label,
    &__value {
        font-size: $order-summary__label-font-size;
        line-height: $order-summary__label-light-height;
        color: $order-summary__color-text;
    }

    &__label,
    &__title-total {
        text-align: right;
        padding-right: 15px;

        @include mq(false, $screen-l) {
            width: 128px;
            padding-right: 8px;
        }

        &::after {
            content: ':';
        }
    }

    &__link {
        padding: $order-summary__link-padding;
        font-weight: $order-summary__link-font-weight;
    }

    &__divider {
        margin: $order-summary__divider-margin;
        border: $order-summary__divider-border;
        border-width: $order-summary__divider-border-width;
        width: $order-summary__divider-width;
        margin-left: $order-summary__divider-margin-left;

        @include mq(false, $screen-l) {
            width: $order-summary__divider-width-m;
            margin-left: $order-summary__divider-margin-left-m;
        }
    }

    &__list-column {
        display: flex;
        flex-direction: column;
    }

    .active {
        .shipping-estimation__title-icon {
            transform: rotate(180deg);
        }
    }

    &__tab {
        &.active {
            .order-summary__tab-icon {
                transform: rotate(180deg);
            }
        }
    }

    &__tab-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $order-summary__tab-title-padding;
        border-top: $order-summary__tab-title-border-top;
        border-bottom: $order-summary__tab-title-border-bottom;
        cursor: pointer;
    }

    &__tab-text {
        font-weight: $order-summary__tab-text-font-weight;
    }

    &__tab-icon {
        width: $order-summary__tab-icon-width;
        height: $order-summary__tab-icon-height;
        transition: $order-summary__tab-icon-transition;
    }

    &__products {
        display: $order-summary__products-display;
    }

    &__product-item {
        display: flex;
        padding: $order-summary__product-item-padding;
        border-bottom: $order-summary__product-item-border-bottom;
    }

    &__product-image {
        height: $order-summary__product-image-height;
        width: $order-summary__product-image-width;
        max-width: $order-summary__product-image-max-width;
        margin-right: $order-summary__product-image-margin-right;
    }

    &__product-info {
        display: flex;
        justify-content: space-between;
        width: $order-summary__product-info-width;
    }

    &__product-wrapper {
        width: $order-summary__product-wrapper-width;
    }

    &__product-details {
        padding-right: $order-summary__product-details-padding-right;
        @include mq(false, $screen-m) {
            flex-wrap: wrap;
        }
    }

    &__product-name {
        font-size: $order-summary__product-name-font-size;
        font-weight: $order-summary__product-name-font-weight;
        @include mq(false, $screen-m) {
            padding-bottom: 20px;
        }
    }

    &__product-qty {
        color: $order-summary__product-qty-color;
    }

    &__product-price {
        font-size: $order-summary__product-price-font-size;
    }

    &__dropdown-list {
        background-color: $order-summary__background;

        .dropdown-list__label {
            background-color: $order-summary__background;
        }
    }

    &__cards {
        order: 1;
        border-top: 1px solid $order-summary__color-light-grey;
        padding: $order-summary__cards-padding;
        width: $order-summary__cards-width;
        margin-left: $order-summary__cards-margin-left;

        @include mq(false, $screen-l) {
            width: calc(100% + 30px);
            margin-left: -15px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            width: 100%;
            justify-content: space-between;

            li {
                display: flex;
                align-items: center;

                svg {
                    width: $order-summary__cards-svg-width;
                    height: $order-summary__cards-svg-height;
                }
            }
        }
    }

    button[data-role="proceed-to-checkout"] {
        margin-bottom: 20px;
    }

    th.order-summary__label {
        font-weight: $font-weight-normal;
    }
}

.shopping_cart-contact_box-container {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.cart-banner {
    padding: 0 15px;
    background: #f3f3f3;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    @include mq($screen-m) {
        padding: 16px 20px 7px 30px;
    }

    .banner-title {
        font-size: $order-summary__font-size-big;
        line-height: $order-summary__line-height-medium;
        font-weight: 700;
        color: $order-summary__color-titles;
        display: block;
        margin-bottom: 30px;
        margin-top: 7px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        row-gap: 0.8rem;
        padding-left: 10px;

        @include mq($screen-l) {
            flex-direction: column-reverse;
        }

        @include mq($screen-xxl) {
            flex-direction: column;
        }
    }

    li {
        @include mq(false, $screen-l) {
            display: flex;
            flex-direction: column;
        }

        @include mq($screen-xxl) {
            flex-direction: column;
        }

        @include mq($screen-l) {
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            display: inline-flex;

            @include mq(false, $screen-l) {
                flex-direction: column;
            }

            @include mq($screen-xxl) {
                flex-direction: row;
            }
        }

        svg {
            width: 37px;
            height: 37px;
            margin: 0 auto;
            vertical-align: middle;

            @include mq(false, $screen-l) {
                width: 38px;
                height: 38px;
                margin-bottom: 8px;
            }

            @include mq($screen-l) {
                margin: 0 15px 0 0;
            }
        }

        img {
            width: 42px;
            margin: 0 auto;

            @include mq(false, $screen-l) {
                width: auto;
                height: 38px;
                margin-bottom: 8px;
            }

            @include mq($screen-l) {
                margin: 0 15px 0 0;
            }
        }

        span {
            font-size: $order-summary__font-size-small;
            line-height: $order-summary__line-height-medium;
            font-weight: 600;
            color: $order-summary__color-titles;
            padding-left: 10px;

            @include mq($screen-m) {
                font-size: 16px;
            }

            &.timetable {
                font-size: $order-summary__font-size-medium;
                line-height: $order-summary__line-height-big;
                font-weight: 400;
                color: $order-summary__color-text;
                display: block;
                padding-left: 42px;

                @include mq(false, $screen-l) {
                    padding-left: 0;
                    font-size: $order-summary__font-size-small;
                    line-height: $order-summary__line-height-small;
                }

                &-small {
                    font-size: 14px;
                    font-weight: normal;
                    color: $order-summary__color-text;
                }
            }
        }

        &:first-child {
            margin-right: 24px;
        }
    }
}

.checkout-cart-totals-container {
    background: $order-summary__color-light;
    border: 1px solid $order-summary__color-light-grey;
    border-radius: $order-summary__radius;
    padding: 27px 25px 9px;
    margin-bottom: 20px;

    @include mq(false, $screen-l) {
        padding: 27px 25px 9px 17px;
    }

    > .order-summary__list-item {
        margin-bottom: 20px;
    }

    .shipping-info {
        position: absolute;
        margin-left: 5px;

        .shipping-info-tooltip-text {
            right: -45px;
            padding: $order-summary__tooltip-text-padding;
            top: 32px;
            min-width: 232px;

            @include mq(false, $screen-l) {
                right: -10px;
            }

            &::before,
            &::after {
                right: 41px;

                @include mq(false, $screen-l) {
                    right: 6px;
                }
            }
        }
    }

    .zero-tax-info-icon {
        display: inline-block;
        background: $brew-text--accent;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        transform: translateY(2px);

        &::after,
        &::before {
            content: '';
            position: absolute;
            display: block;
            background-color: $brew-section-bright;
            width: 2px;
            left: 50%;
            margin-left: -1px;
        }

        &::after {
            height: 5px;
            top: 6px;
        }

        &::before {
            height: 2px;
            top: 3px;
        }
    }

    .zero-tax-info {
        position: absolute;
        right: -24px;

        &:hover {
            .shipping-info-tooltip-text,
            .zero-tax-info-tooltip-text {
                pointer-events: all;
                opacity: 1;
            }
        }
    }

    .zero-tax-info-tooltip-text {
        color: #333;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        right: -157px;
        top: 33px;
        z-index: 1;
        background-color: #FFF;
        padding: 12px 12px 10px;
        border: 1px solid #ACACAC;
        box-shadow: 0 3px 16px rgba(#000, 0.3);
        min-width: 232px;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        text-align: start;

        &::before,
        &::after {
            content: "";
            position: absolute;
            right: 153px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        &:before {
            top: -13.5px;
            border-bottom: 13px solid #ACACAC;
        }

        &:after {
            top: -11.5px;
            border-bottom: 13px solid #FFFFFF;
        }
    }

    .zero-tax-info-container {
        position: relative;
    }
}

.cart-discount {
    margin-bottom: 20px;


    small {
        font-size: 85%;
    }

    .note-bold {
        font-weight: bold;
        color: #666666;
    }

    .cart-discount__content-field {
        &::placeholder {
            font-style: italic;
            font-size: 15px;
            font-weight: normal;
            color: $brew-text--light-grey;
        }

        &.focus-visible {
            &::placeholder {
                color: $brew-text--light-grey;
            }
        }
    }
}

@include mq(false, $screen-l) {
    .cart {
        &.row {
            align-items: flex-start;
        }

        &__summary {
            position: sticky;
            top: 120px;
            margin-left: -3px;
            padding: 0;
            max-width: calc(100% + 6px);
            flex-basis: calc(100% + 6px);
        }
    }
}

.cart__summary {
    .cart-main-container-wrapper {
        @include mq($screen-l) {
            position: sticky;
            top: 160px;
        }
    }
}

.sidebar-checkout {
    @include mq(false, $screen-l) {
        &.opc-sidebar {
            display: none;
        }
    }
}

.opc-block-summary {
    padding: 0;
    border: none;
    background: none;
    margin: 0;

    .order-summary {
        &__title {
            margin: 0 0 $spacer--semi-large;
            font-weight: $font-weight-semibold;

            @include mq(false, $screen-m) {
                display: none;
            }
        }

        &__qty-text {
            margin-right: auto;
        }

        &__qty-title,
        &__total-title,
        &__product-qty {
            display: inline-flex;
            align-items: flex-start;
        }

        &__total-title {
            width: 115px;
            flex: 0 0 115px;
        }

        &__qty-title,
        &__product-qty {
            width: 55px;
            flex: 0 0 55px;
        }

        &__product-qty {
            color: $brew-text;
        }

        &__product-item {
            padding: 20px 0 20px 20px;
            border: none;
            flex-wrap: wrap;

            &:nth-child(even) {
                background-color: $brew-section-dark;

                .options {
                    background-color: $brew-section-bright;
                }
            }

            &:nth-child(odd) {
                .options {
                    background-color: $brew-section-dark;
                }
            }
        }

        &__product-details {
            display: flex;
            padding: 0;
            width: 100%;
        }

        &__product-name {
            padding-right: 29px;
            flex-grow: 1;
            line-height: 25px;
            font-weight: $font-weight-semibold;
            color: $brew-text;
        }

        &__product-image {
            width: inherit;
            max-width: unset;
            margin-right: 0;
        }

        &__product-wrapper {
            display: flex;
            align-items: center;
            flex: 0 0 calc(100% - 140px);
        }

        &__list {
            display: flex;
            flex-direction: column;
            padding: 20px;
            background-color: $brew-section-midtone;
            border: 1px solid $order-summary__color-light-grey;
            border-bottom: none;

            @include mq(false, $screen-m) {
                border-top: none;
                margin: 0 -15px;
                padding: 0 15px 20px;
            }

            &-item {
                padding: 9px 40px 9px 30px;
                background-color: $brew-section-bright;
                border-left: 1px solid $brew-border;
                border-right: 1px solid $brew-border;
                margin: 0;

                &--shipping {
                    position: relative;
                    padding-top: 29px;
                    border-top: 1px solid $brew-border;
                    order: -1;
                }

                &:nth-last-of-type(3) {
                    padding-bottom: 20px;
                }

                &:nth-last-of-type(2) {
                    padding-bottom: 19px;
                    border-top: 1px solid $brew-border;
                    border-bottom: 1px solid $brew-border;
                }
            }
        }

        &__divider {
            display: none;
        }

        &__label {
            width: 50%;

            @include mq($screen-l) {
                width: auto;
                text-align: left;
            }
        }

        &__title-total {
            width: 50%;
            font-size: 15px;
            font-weight: 600;

            @include mq($screen-l) {
                width: 60%;
                text-align: left;
            }
        }

        &__amount {
            font-size: 15px;
            font-weight: 600;
        }

        &__products-toggle {
            display: block;
            font-size: 16px;
            line-height: 27px;
            color: $brew-text--accent;
            border: none;
            padding: 0;
            background-color: transparent;
            cursor: pointer;

            @include mq($screen-m) {
                margin: 15px 0 0 auto;
            }

            > span {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            @include mq(false, $screen-m) {
                width: 100%;
                padding: 9px;
                line-height: 22px;
                color: $brew-text;
                background-color: $brew-section-bright;
                border: 1px solid $brew-border;
                border-radius: 3px;

                > span {
                    width: 100%;
                    justify-content: center;
                }
            }

            &:hover {
                @include mq($screen-m) {
                    text-decoration: underline;
                }
            }

            .icon {
                display: inline-block;
                fill: $brew-text--accent;
                margin-left: 5px;

                @include mq(false, $screen-m) {
                    display: none;
                }
            }
        }
    }

    .options {
        flex: 0 0 400px;
        margin: 0 15px 0 140px;
        padding: 16px 24px;
        max-width: calc(100% - 155px);

        &__title {
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
            color: $brew-text;
        }

        &__list {
            list-style-type: none;
            padding-left: 10px;
            margin: 0;
        }

        &__item {
            position: relative;

            &::before {
                content: '•';
                position: absolute;
                top: 2px;
                left: -10px;
                color: $brew-text;
                font-size: 14px;
            }

            &-value {
                font-size: 14px;
                line-height: 25px;
                color: $brew-text;

                &:first-child::after {
                    content: ': ';
                }
            }
        }
    }

    .subtotal,
    .cart-price {
        display: inline-flex;
        align-items: flex-start;
        width: 115px;
        flex: 0 0 115px;
        font-size: 16px;
        font-weight: $font-weight-semibold;
        line-height: 18px;
        color: $brew-text;
    }

    .subtotal {
        > span::after {
            content: attr(data-label);
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: $brew-text;
            font-weight: $font-weight-normal;
        }
    }

    .product-image-container {
        margin-right: 50px;
    }

    .opc-title {
        display: flex;
        padding: 12px 0 13px 20px;
        background-color: $brew-section-dark;

        span {
            font-weight: $font-weight-semibold;
            font-size: 14px;
            line-height: 19px;
            color: $brew-text;
        }
    }

    .order-summary__label,
    .order-summary__title-total {
        text-align: left;
    }

    .shipping-info-icon,
    .zero-tax-info-icon {
        display: inline-block;
        background: $brew-text--accent;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        transform: translateY(2px);

        &::after,
        &::before {
            content: '';
            position: absolute;
            display: block;
            background-color: $brew-section-bright;
            width: 2px;
            left: 50%;
            margin-left: -1px;
        }

        &::after {
            height: 5px;
            top: 6px;
        }

        &::before {
            height: 2px;
            top: 3px;
        }
    }

    .shipping-info,
    .zero-tax-info {
        position: absolute;

        &:hover {
            .shipping-info-tooltip-text,
            .zero-tax-info-tooltip-text {
                pointer-events: all;
                opacity: 1;
            }
        }
    }

    .shipping-info-tooltip-text,
    .zero-tax-info-tooltip-text {
        color: #333;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        top: 33px;
        z-index: 1;
        background-color: #FFF;
        padding: 12px 12px 10px;
        border: 1px solid #ACACAC;
        box-shadow: 0 3px 16px rgba(#000, 0.3);
        min-width: 232px;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        opacity: 0;

        &::before,
        &::after {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        &:before {
            top: -13.5px;
            border-bottom: 13px solid #ACACAC;
        }

        &:after {
            top: -11.5px;
            border-bottom: 13px solid #FFFFFF;
        }
    }

    .shipping-info {
        right: 20px;

        .shipping-info-tooltip-text {
            right: -25px;

            &::before,
            &::after {
                right: 21px;
            }
        }
    }

    .zero-tax-info {
        right: -24px;

        .zero-tax-info-tooltip-text {
            right: -190px;

            &::before,
            &::after {
                right: 186px;
            }
        }
    }

    .zero-tax-info-container {
        position: relative;
    }
}

.checkout-logos {
    display: none;
    align-items: center;
    justify-content: flex-start;
    list-style-type: none;
    padding: 30px 20px 0;
    margin: 0;

    &__item {
        &:not(:last-child) {
            margin-right: 60px;
        }
    }

    &__image {
        width: 85px;
        height: auto;
    }
}

.checkout-index-index {
    @include mq(false, $screen-m) {
        .page-wrapper {
            margin-bottom: 0;
        }
    }

    .opc-sidebar {
        [data-role="checkout-agreements"] {
            padding: 10px 20px 20px;
            background-color: $brew-section-midtone;
            border-left: 1px solid $brew-border;
            border-right: 1px solid $brew-border;

            @include mq(false, $screen-m) {
                margin: 0 -15px;
                padding: 10px 15px 20px;
                width: calc(100% + 30px);
            }

            .checkbox__field,
            .checkbox__icon {
                top: 0;
                transform: none;
                color: $brew-text;
            }

            .checkbox__label {
                padding: 0 0 0 32px;
                font-style: italic;
                color: $brew-text;

                &:after {
                    top: 30%;
                    color: $brew-text;
                    background-color: transparent;
                }
            }

            .link {
                color: $brew-text;
                display: contents;
            }
        }
    }

    &.payment-step {
        @include mq($screen-l) {
            .checkout-logos {
                display: flex;
            }
        }

        .items-in-cart {
            margin-bottom: 40px;

            @include mq(false, $screen-m) {
                margin: 0 -15px;
                padding: 19px 15px;
                background-color: $brew-section-midtone;
            }

            &:not(.active) {
                .less {
                    display: none;
                }

                .opc-title {
                    display: none;

                    @include mq($screen-m) {
                        display: flex;
                    }
                }

                .order-summary__product-item {
                    display: none;
                }

                @include mq($screen-m) {
                    .order-summary__product-item {
                        display: flex;

                        &:nth-child(2) ~ .order-summary__product-item {
                            display: none;
                        }
                    }
                }
            }

            &.active {
                .more {
                    display: none;
                }
            }
        }
    }

    &.shipping-step {
        .opc-block-summary {
            .order-summary__products-toggle {
                display: none;
            }
        }
    }

    .order-summary {
        @include mq(false, $screen-l) {
            margin-left: -30px;
            width: calc(100% + 60px);
            margin-top: 30px;

            .order-summary__title {
                display: none;
            }
        }

        @include mq(false, $screen-m) {
            margin-left: 0;
            width: 100%;
        }
    }

    [data-role="checkout-agreements"],
    .actions-toolbar-trigger {
        @include mq(false, $screen-l) {
            margin-left: -30px;
            width: calc(100% + 60px);
        }

        @include mq(false, $screen-m) {
            margin-left: 0;
            width: 100%;
        }
    }

    .page-wrapper {
        @include mq(false, $screen-l) {
            margin-bottom: 0;
        }
    }

    .actions-toolbar-trigger {
        @include mq(false, $screen-m) {
            margin-left: -15px;
            width: calc(100% + 30px);
        }

        .place-order-message {
            p {
                font-size: $order-summary__font-size-small;
                color: $order-summary__color-red;
                font-style: italic;
                line-height: $order-summary__line-height-small;
            }
        }
    }

    .checkout-agreements .checkbox__icon--checked {
        background-color: $gray-darkest;
    }
}

.actions-toolbar-trigger {
    padding: 20px 20px 46px;
    background-color: $brew-section-midtone;
    border: 1px solid $brew-border;
    border-top: none;

    @include mq(false, $screen-m) {
        margin: 0 -15px;
        padding: 20px 15px 46px;
    }

    .button {
        width: 100%;
    }
}

.payment-method-content {
    .actions-toolbar {
        display: none;
    }
}

.block.proline-action {
    order: 1;
}

.order-save {
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 1rem;
        margin-bottom: 0.75rem;
    }

    &__title {
        flex-grow: 1;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 1rem;
    }

    &__tooltip {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        background-color: white;
        border: 1px solid #acacac;
        white-space: nowrap;
        padding: 5px 10px;
        font-size: 11px;
        color: #333333;
        box-shadow: 0 3px 16px rgba(0, 0, 0, 0.19);
        transition: all 0.3s ease-in-out;
        top: 33px;
    }

    &__tooltip.hidden {
        display: none;
    }

    &__tooltip:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 13px 10px;
        border-color: transparent transparent #ACACAC transparent;
        transition: all 0.3s ease-in-out;
        top: -13px;
    }

    &__tooltip::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 13px 10px;
        border-color: transparent transparent #FFFFFF transparent;
        transition: all 0.3s ease-in-out;
        top: -12px;
    }

    &__action {
        position: relative;

        & > .action {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
            flex-wrap: nowrap;
            align-items: center;
        }

        & > .action:hover + .order-save__tooltip {
            visibility: visible;
            opacity: 1;
        }

        &--dropdown {
            list-style-type: none;
            margin: 3px 0 0 0;
            padding: 0;
            position: absolute;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            left: calc(14px + 0.5rem);
            background-color: white;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);

            a {
                padding: 6px 14px;
                white-space: nowrap;
            }
        }

        &--dropdown.visible {
            visibility: visible;
            opacity: 1;

            li:hover {
                background-color: #f3f3f3;
            }
        }

        &--save {
            .order-save__action--icon {
                img {
                    width: 18px;
                }
            }

            .order-save__tooltip {
                left: -129px;

                &:before,
                &:after {
                    right: 165px;
                }
            }
        }

        &--forward {
            .order-save__action--icon {
                img {
                    width: 20px;
                }
            }

            .order-save__tooltip {
                left: -124px;

                &:before,
                &:after {
                    right: 177px;
                }
            }
        }
    }

    &__modal--forward.modal-slide.modal-popup {
        margin: 0 auto;
        max-width: 640px;
        height: 100%;

        h2.heading {
            font-size: 17px;
            font-weight: 800;
        }

        .modal-header {
            padding-top: 38px;
            border: none;
            box-shadow: none;
        }

        .modal-content {
            padding-bottom: 1rem;

            .req-note {
                font-size: 13px;
            }
        }

        .modal-footer {
            border: none;
            padding-top: 0;
            display: flex;
            flex-direction: row;
            column-gap: 1.5rem;
            align-items: center;

            .button {
                width: 100%;
            }
        }

        .input {

            label {
                font-size: 13px;
            }

            label.required:after {
                content: '*';
                font-size: 13px;
            }

            textarea {
                height: auto;
                padding: 10px 16px;
            }
        }

        .modal-inner-wrap {
            margin: 0;
            width: 100%;
            transform: translateY(-50%) !important;
            top: 50%;
        }
    }

    &__modal--forward-popup .modal-inner-content {
        display: none;
    }

    &__modal--forward-popup .modal-inner-content.visible {
        display: block;
    }
}
