@import 'table-variables';

$dashboard-table__link-font-size: $font-size-base;
$dashboard-table__link-font-size\@medium: $font-size-base;

.dashboard-table {
    overflow: hidden;

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: $dashboard-table__header-margin;

        @include mq($screen-m) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__content {
        margin: $dashboard-table__content-margin;
        overflow-x: auto;
    }

    &__title {
        @extend .heading--arrow-red;
        text-transform: uppercase;
    }

    &__link {
        display: inline-flex;
        font-size: $dashboard-table__link-font-size;
        font-weight: $dashboard-table__link-font-weight;
        text-decoration: $dashboard-table__text-decoration;
        margin-left: auto;

        @include mq($screen-m) {
            font-size: $dashboard-table__link-font-size\@medium;
        }
    }

    &__divider {
        padding: $dashboard-table__divider;

        @include mq($screen-m) {
            padding: 0;
        }
    }

    .order-details-items.ordered {
        .grand_total {
            .amount .price {
                font-weight: $font-weight-bold;
            }
        }
    }

    .subtotal,
    .discount,
    .totals-tax,
    .shipping {
        th {
            font-weight: 400;
        }
    }
}
