@import 'toolbar-variables';

.toolbar {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: $toolbar__padding;

    @include mq(false, $screen-m) {
        padding-top: $toolbar__mobile-padding-top;
    }

    @include mq($screen-m) {
        align-items: center;
        justify-content: flex-end;
        padding: $toolbar__padding\@medium;
    }

    @include mq($screen-l) {
        padding: $toolbar__padding\@large;
    }

    .pager {
        display: none;
    }

    &--secondary {
        justify-content: $toolbar__justify-content--secondary;
        flex-wrap: wrap;
        padding: $toolbar__padding--secondary;

        .pager {
            display: block;
        }

        .toolbar__amount {
            display: flex;
            order: $toolbar__amount-order--secondary;
            flex-grow: $toolbar__amount-flex-grow--secondary;
        }

        .toolbar__pager {
            order: $toolbar__pager-order--secondary;
            flex-grow: $toolbar__pager-flex-grow--secondary;
            flex-basis: $toolbar__pager-flex-basis--secondary;
            margin: $toolbar__pager-margin--secondary;
        }

        .toolbar__limiter {
            order: $toolbar__sorter-limiter-order--secondary;
            flex-grow: $toolbar__sorter-limiter-flex-grow--secondary;
        }
    }

    &__mode,
    &__limiter,
    &__amount,
    &__sorter {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 0;

        @include mq($screen-m) {
            margin-left: $toolbar__element-spacer-left;
        }
    }

    &__mode {
        display: flex;
        align-items: center;

        .mode-switcher {
            width: $toolbar__mode-switcher-width;
            height: $toolbar__mode-switcher-height;
            background: $toolbar__mode-background;
            border-radius: $toolbar__mode-switcher-radius;
            position: relative;
            margin: $toolbar__mode-switcher-margin;
            z-index: -1;

            span {
                display: block;
                width: $toolbar__mode-switcher-spacer;
                height: $toolbar__mode-switcher-spacer;
                background: $toolbar__white;
                border: 1px solid $toolbar__gray;
                border-radius: 50%;
                position: absolute;
                top: -4px;
                transition: right 300ms ease-in-out;

                &.grid-on {
                    right: 17px;
                }

                &.list-on {
                    right: 3px;
                }
            }
        }
    }

    &__mode-button {
        min-width: auto;
        padding: 0;

        .icon {
            height: $toolbar__mode-button-icon-size;
            fill: $toolbar__mode-button-icon-fill;
        }

        &--active {
            .icon {
                fill: $toolbar__mode-button-icon-fill--active;
            }
        }

        &[data-value="grid"] {
            width: 16px;
        }

        &[data-value="list"],
        &[data-value="grid"] {
            &:not(.toolbar__mode-button--active) {
                &::after {
                    content: '';
                    display: block;
                    width: 60px;
                    height: 20px;
                    position: absolute;
                }
            }
        }

        &[data-value="list"] {
            &:not(.toolbar__mode-button--active) {
                &::after {
                    left: -60px;
                }
            }
        }

        &[data-value="grid"] {
            &:not(.toolbar__mode-button--active) {
                &::after {
                    right: -60px;
                }
            }
        }
    }

    &__amount {
        color: $toolbar__gray-darker;
        font-size: $toolbar__font-size-medium;

        @include mq($screen-m) {
            display: flex;
        }

        &--visible {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: flex-start;
            padding: $toolbar__amount-padding--visible;

            @include mq($screen-m) {
                flex: 1 0 0;
            }

            @include isIE() {
                flex-grow: 0;

                @include mq($screen-m) {
                    flex-grow: 1;
                }
            }
        }
    }

    &__amount-content {
        white-space: nowrap;
    }

    &__sorter {
        order: $toolbar__sorter-order;

        @include mq($screen-m) {
            order: $toolbar__sorter-order\@medium;
            width: $toolbar__sorter-width\@medium;
            padding: $toolbar__sorter-padding\@medium;
            margin: 0 0 0 $toolbar__element-spacer-left;
            border-width: $toolbar__sorter-border-width\@medium;
        }

        @include mq(false, $screen-m) {
            position: relative;

            label {
                display: flex;
                align-items: center;
                position: absolute;
                right: 100px;
                z-index: 1;
                background: $toolbar__white;
                margin-right: 0;
                border: 1px solid $toolbar__border-color;
                border-radius: $toolbar__mobile-sorter-radius;
                padding: 9px 10px;
                pointer-events: none;

                span {
                    display: none;
                }

                &::after {
                    content: attr(data-label);
                }

                &::before {
                    content: '';
                    display: inline-block;
                    width: $toolbar__mobile-sorter-icon-size;
                    height: $toolbar__mobile-sorter-icon-size;
                    margin-right: 10px;
                    background-image: url("../images/icons/sortieren.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            select {
                display: none;
            }

            span.selection {
                opacity: 0;
            }
        }
    }

    .select {
        .select2 {
            flex-grow: 1;

            &-container {
                .select2-selection--single {
                    border: none;
                    height: $toolbar__select-height;
                    outline: none;

                    .select2-selection__arrow {
                        height: $toolbar__select-height;
                        right: 0;

                        b {
                            border-color: $toolbar__gray-darker;
                        }
                    }

                    .select2-selection__rendered {
                        color: $toolbar__gray-darker;
                        padding: 0 $toolbar__select-spacer-right 0 0;
                        font-size: $toolbar__font-size-medium;
                        font-weight: $toolbar__font-weight-bold;
                        line-height: $toolbar__font-line-height-middle;
                    }
                }

                .select2-dropdown {
                    border: 1px solid $toolbar__border-color;
                }
            }
        }
    }


    &__sorter-label,
    &__limiter-label {
        white-space: nowrap;
        margin: $toolbar__sorter-label-margin;
        font-size: $toolbar__font-size-medium;
        color: $toolbar__gray-darker !important;
    }

    .button--link, span {
        color: $toolbar__gray-darker !important;
    }

    &__sorter-link {
        padding: $toolbar__sorter-link-padding;
        font-weight: $toolbar__sorter-link-font-weight;
        &:hover,
        &.focus-visible {
            background-color: $toolbar__sorter-link-bg-hover;
        }
    }

    &__mode,
    &__limiter {
        @include mq(false, $screen-m) {
            display: none;
        }
    }

    ~ .toolbar {
        border-bottom: 0;

        .pager {
            display: block;
        }

        .toolbar {
            &__mode,
            &__sorter,
            &__amount,
            &__limiter {
                display: none;
            }
        }
    }

    .sorter-action {

        svg {
            fill: $brew-text;
        }
        &:hover {
            color: $brew-text;

            svg {
                fill: $brew-text;
            }
        }

        @include mq($screen-l) {
            .sort-mobile {
                display: none;
            }
        }

        @include mq(false, $screen-m) {
            border: 1px solid $brew-border;
            padding-left: 10px;
            padding-right: 10px;
            height: 45px;
        }
    }
}

.catalog-category-view {
    .select2-container {
        .select2-dropdown {
            width: auto !important;
        }
    }

    @include mq(false, $screen-m) {
        .toolbar {
            .select2.select2-container {
                width: 125px;
            }
        }
    }
}
