@import 'datepicker-variables';
@import 'base';

.ui-datepicker {
    border: $datepicker__border;
    background-color: $datepicker__background-color;

    .ui-datepicker-prev {
        top: $datepicker__arrow-top;

        .ui-icon {
            border-color: $datepicker__prev-icon-border-color;
        }
    }

    .ui-datepicker-next {
        top: $datepicker__arrow-top;

        .ui-icon {
            border-color: $datepicker__next-icon-border-color;
        }
    }

    .ui-datepicker-title {
        display: $datepicker__title-display;
        justify-content: $datepicker__title-justify-content;
        align-items: $datepicker__title-align-items;
        flex-direction: $datepicker__title-flex-direction;

        .ui-datepicker-month,
        .ui-datepicker-year {
            background-color: $datepicker__select-background-color;
            height: $datepicker__select-height;
            margin-right: $datepicker__select-margin-right;
        }
    }

    .ui-datepicker-calendar {
        border-collapse: $datepicker__calendar-border-collapse;
        border: $datepicker__calendar-border;

        .ui-datepicker-today {
            background-color: $datepicker__today-background-color;

            .ui-state-default {
                color: $datepicker__today-color;

                &.ui-state-hover {
                    background-color: $datepicker__today-background-color--active;
                    color: $datepicker__today-color--active;
                }

                &.ui-state-active {
                    &.ui-state-hover {
                        background-color: $datepicker__today-background-color--active;
                        color: $datepicker__today-color--active;
                    }
                }
            }
        }

        .ui-datepicker-current-day {
            border: $datepicker__current-day-border;
            background-color: $datepicker__current-day-background-color;

            .ui-state-active {
                background-color: $datepicker__current-day-background-color--active;
                color: $datepicker__current-day-color--active;
            }
        }

        .ui-state-default {
            text-align: $datepicker__ui-state-text-align;

            &.ui-state-hover {
                background-color: $datepicker__ui-state-background-color--active;
                color: $datepicker__ui-state-color--active;
            }
        }
    }

    .ui-datepicker-buttonpane {
        border: 0;
    }
}
