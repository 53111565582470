$select__margin-bottom                   : $spacer--medium;
$select__padding                         : 0 $spacer--extra-large 0 $spacer--medium;
$select__border                          : $form-elements-border;
$select__height                          : 40px;
$select__min-width                       : 72px;
$select__border-radius                   : $form-elements-radius;
$select__line-height                     : $select__height;
$select__font-family                     : $font-family-base;
$select__field-background                : $white;
$select__field-color                     : $brew-text;
$select__field-font-size                 : $font-size-base;
$select__label-color-focus               : $color-secondary;
$select__transition                      : $transition-base;
$select__background-disabled             : $gray-light;
$select__white-space--inline             : nowrap;

$select__label-margin--inline            : 0 $spacer--medium 0 0;

//native
$select__field-bg-image--native          : url('../images/icons/expand-down.svg');
$select__field-bg-repeat--native         : no-repeat;
$select__field-bg-position--native       : calc(100% - #{$spacer}) center;

// Select 2
$select2__color-gray                     : $brew-text;
$select2__color-white                    : $white;
$select2__font-size-medium               : $font-size-medium;
$select__option-list-shadow              : 0 3px 6px #ccc;
$select__border-radius                   : 3px;
$select__field-padding                   : 0 $spacer--extra-large 0 $spacer--medium;
$select__arrow-position-top              : 0;
$select__arrow-position-right            : $spacer--small;
$select__arrow-padding                   : $spacer--extra-small;
$select__arrow-bg-color                  : $brew-text;
$select__arrow-border-width              : 0 2px 2px 0;
$select__arrow-margin-top                : -7px;
$select__arrow-transform                 : rotate(45deg);
$select__arrow-transform--open           : rotate(225deg);
$select__arrow-transition                : $transition-base;
$select__option-font-size                : $font-size-base;
$select__option-color--highlighted       : $brew-text;
$select__option-bg-color--highlighted    : $gray-light;
$select__option-selected-bg              : $white;
$select__option-overflow                 : hidden;
$select__option-text-overflow            : ellipsis;
$select__option-white-space              : nowrap;
$select__dropdown-border-width           : 1px;
$select__dropdown-margin-top             : 10px;
$select__dropdown-padding                : 12px 0;
$select__option-padding                  : 5px 9px;
