@import 'newsletter-variables';

.newsletter {
    display: flex;
    flex-direction: column;
    margin: $newsletter__margin;
    padding: $newsletter__padding\@medium;
    background: #F7F7F7;
    background-image: url("../images/hintergrund_newsletter.png");
    background-repeat: no-repeat;
    background-position: left bottom;

    @include mq($screen-m) {
        padding: $newsletter__padding;
    }

    &__heading {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        margin: 0 auto;

        .main-heading {
            font-size: 35px;
            line-height: 45px;
            font-weight: 600;
            font-style: italic;
            margin-bottom: 0;
            color: #666666;

            @include mq($screen-m) {
                font-size: 60px;
                line-height: 82px;
            }
        }

        .secondary-heading {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
            font-weight: 400;
            color: #666666;

            @include mq($screen-m) {
                font-size: 25px;
                line-height: 30px;
            }
        }
    }

    &__title {
        margin: $newsletter__title-margin;
        font-family: $newsletter__title-font-family;
        font-size: $newsletter__title-font-size;
        font-weight: $newsletter__title-font-weight;
        line-height: $newsletter__title-line-height;
        color: $newsletter__title-color;
    }

    &__subtitle {
        color: $newsletter__subtitle-color;
        font-family: $newsletter__subtitle-font-family;
    }

    &__controls {
        display: flex;
        margin: $newsletter__controls-margin;
        padding: 38px 0 42px;
        max-width: 857px;

        @include mq(false, $screen-m) {
            flex-direction: column;
            width: 100%;
            padding: 35px 0 30px;
        }
    }

    &__button {
        width: 100%;
        padding: $newsletter__button-padding;
        align-self: flex-start;
        height: 44px;
        font-size: 16px;
        line-height: 18px;

        @include mq($screen-m + 1) {
            height: 60px;
            font-size: 20px;
            line-height: 60px;
            width: auto;
            min-width: $newsletter__button-min-width;
            margin-left: 30px;
        }
    }

    &__input {
        flex-grow: 1;
        margin: 0;
    }

    &__field {
        height: 44px;
        font-size: 16px;
        line-height: 18px;
        padding: 0 20px;
        margin-bottom: 20px;

        @include mq($screen-m + 1) {
            height: 60px;
            font-size: 22px;
            padding: 0 27px;
            line-height: 60px;
            margin-bottom: 0;
        }

        &:focus,
        &:focus-within,
        &:active {
            font-size: 16px !important;
            outline: none !important;
            line-height: 44px;
            color: #666666 !important;

            @include mq($screen-m) {
                font-size: 22px !important;
                line-height: 60px;
            }

            &::placeholder {
                color: #CCC !important;
            }
        }

        &::placeholder {
            font-style: italic;
            font-size: 16px !important;
            line-height: 44px !important;

            @include mq($screen-m + 1) {
                font-size: 22px !important;
                line-height: 60px !important;
            }
        }
    }

    &__agreements {
        max-width: $newsletter__agreements-max-width;
        margin: $newsletter__agreements-margin;
    }

    &__form {
        flex-grow: 1;
        max-width: $max-content-width;
        margin: 0 auto;

        .hidden {
            display: none;
        }
    }

    &_note {
        p {
            font-size: 14px;
            line-height: 18px;
            color: #666666;
            margin-bottom: 0;

            @include mq($screen-m) {
                font-size: 15px;
            }
        }
    }
}
