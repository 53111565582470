// Main image
$magazin__image-height: 220px;
$magazin__image-height\@medium: 300px;
$magazin__image-height\@large: 400px;

$magazin__image-indent: 20px;
$magazin__image-indent\@large: 25px;

// Breadcrumbs
$magazin__breadcrumbs-margin: 0 0 20px;
$magazin__breadcrumbs-margin\@medium: 0 0 40px;
$magazin__breadcrumbs-margin\@large: 0 0 64px;

// Main title
$magazin__main-title-font-color: $brew-titles;
$magazin__main-title-font-size: 20px;
$magazin__main-title-font-size\@medium: 23px;
$magazin__main-title-font-size\@large: 25px;

$magazin__main-title-line-height: 25px;
$magazin__main-title-line-height\@medium: 33px;
$magazin__main-title-line-height\@large: 42px;

$magazin__main-title-margin: 25px;
$magazin__main-title-margin\@medium: 32px;
$magazin__main-title-margin\@large: 37px;

// Content
$magazin__content-font-color: $brew-text;

$magazin__content-margin: 43px;
$magazin__content-margin\@medium: 55px;
$magazin__content-margin\@large: 68px;

$magazin__content-padding: 0 15px;
$magazin__content-padding\@large: 0;

$magazin__content-template-columns\@medium: 2% 56% 40% 2%;
$magazin__content-template-columns\@large: 2% 56% 40% 2%;
$magazin__content-template-columns\@extralarge: 12% 45% 31% 12%;

// Info
$magazin__info-margin-bottom: 40px;
$magazin__info-margin-bottom\@large: 46px;

$magazin__info-padding-bottom: 16px;
$magazin__info-padding-bottom\@large: 32px;

$magazin__info-title-font-size: 16px;
$magazin__info-title-line-height: 18px;
$magazin__info-title-margin-bottom: 7px;
$magazin__info-title-font-style: italic;

$magazin__info-content-font-size: 16px;
$magazin__info-content-line-height: 18px;

// Sidebar
$magazin__sidebar-content-background: $brew-section-midtone;
$magazin__sidebar-content-padding: 20px 21px 26px;
$magazin__sidebar-content-padding\@large: 30px 42px 53px;
$magazin__sidebar-content-top: 129px;
$magazin__sidebar-left: 6%;
$magazin__sidebar-title-margin: 35px;
