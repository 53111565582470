// colors
$product-view__base-background-color                        : $brew-section-midtone;
$product-view__base-border-color                            : $brew-border;
$product-view__base-text-color                              : $brew-text;
$product-view__base-white                                   : #FFF;
$product-view__base-blue                                    : #3683C5;
$product-view__base-blue-2                                  : #367FBE;
$product-view__base-black                                   : #000000;
$product-view__base-red                                     : #FF0000;
$product-view__base-yellow                                  : #FFEC04;
$product-view__base-green                                   : $brew-green;
$product-view__base-dark-grey                               : $brew-dark-grey;
$product-view__base-dark                                    : #253746;
$product-view__base-grey                                    : #ACACAC;
$product-view__base-light-grey                              : #D1D1D1;
$product-view__base-light-grey-border                       : $brew-text--light-grey;
$product-view__base-background-color-individual             : $brew-yellow;
// fonts
$product-view__font-size-small                              : 10px;
$product-view__font-size-smaller                            : 12px;
$product-view__font-size-medium-smaller                     : 13px;
$product-view__font-size-medium                             : 15px;
$product-view__font-size-base                               : 16px;
$product-view__font-size-more-base                          : 18px;
$product-view__font-size-huge                               : 20px;
$product-view__font-size-more-huge                          : 25px;

$product-view__line-height-medium-smaller                   : 14px;
$product-view__line-height-medium                           : 17px;
$product-view__line-height-more-medium                      : 18px;
$product-view__line-height-more-medium-2                    : 19px;
$product-view__line-height-huge-smaller                     : 20px;
$product-view__line-height-huge-smaller-2                   : 22px;
$product-view__line-height-huge-small                       : 24px;
$product-view__line-height-huge                             : 25px;
$product-view__line-height-huge-more                        : 27px;
$product-view__line-height-more-huge                        : 30px;
$product-view__line-height-more-very-huge                   : 34px;

$product-view__semibold                                     : $font-weight-semibold;
$product-view__bold                                         : $font-weight-bold;

// container
$product-view__max-width\@large                             : 1471px;
$product-view__padding                                      : $spacer--medium 0 0 0;
$product-view__padding\@medium                              : $spacer--extra-large 0 0 0;
$product-view__padding\@xl                                  : $spacer--extra-large;
$product-view__radius-base                                  : 3px;

// wrapper / flex & grid
$product-view__wrapper-grid-template-columns\@medium        : 50% 50%;
$product-view__wrapper-grid-template-rows\@medium           : auto auto auto;
$product-view__wrapper-margin                               : 0 auto;
$product-view__wrapper-max-width                            : 1471px;
$product-view__column-gap\@medium                           : $spacer--medium;
$product-view__column-gap\@large                            : $spacer--extra-large;
$product-view__column-gap\@xl                               : #{$spacer--extra-large * 3};
$product-view__gallery-order                                : 1;
$product-view__gallery-grid-column-start\@medium            : 1;
$product-view__gallery-grid-row-start\@medium               : 1;
$product-view__gallery-grid-row-end\@medium                 : 3;
$product-view__desc-order                                   : 2;
$product-view__details-order                                : 3;
$product-view__details-desc-grid-column-start\@medium       : 2;
$product-view__details-grid-row-start\@medium               : 2;
$product-view__details-desc-width\@medium                   : calc(100% - #{$product-view__column-gap\@medium});
$product-view__details-desc-width\@large                    : calc(100% - #{$product-view__column-gap\@large});
$product-view__details-desc-width\@xl                       : calc(100% - #{$product-view__column-gap\@xl});

// gallery
$product-view__gallery-margin                               : 0 0 $spacer--medium;
$product-view__gallery-right-padding                        : 110px;

//badges
$product-view__badges-z-index                               : $z-index-low;
$product-view__badges-top                                   : $spacer--medium;
$product-view__badges-top\@medium                           : $spacer--semi-medium;
$product-view__badges-top\@large                            : $spacer--semi-medium;
$product-view__badges-left                                  : 0;
$product-view__badges-left\@medium                          : 0;
$product-view__badges-left\@large                           : 82px;
$product-view__badges-left\@xl                              : 114px;

// title
$product-view__title-margin                                 : 0 0 $spacer--semi-medium;
$product-view__title-margin\@medium                         : 0;
$product-view__title-text-transform                         : initial;
$product-view__title-font-size                              : $font-size-large;
$product-view__title-font-size\@medium                      : $font-size-extra-large;
$product-view__title-font-size\@large                       : $font-size-super-extra-large;

// sku
$product-view__sku-display                                  : none;
$product-view__sku-color                                    : $gray-dark;
$product-view__sku-font-weight                              : $font-weight-normal;

// stock
$product-view__stock-min-height                             : 22px;
$product-view__stock-padding                                : 0;
$product-view__stock-font-weight                            : $font-weight-bold;
$product-view__stock-text-transform                         : uppercase;
$product-view__stock-white-space                            : nowrap;
$product-view__stock-font-size                              : $font-size-small;
$product-view__stock-font-size\@medium                      : $font-size-base;
$product-view__stock-color                                  : inherit;
$product-view__stock-min-width                              : 60px;
$product-view__stock-color-hover                            : $color-primary;
$product-view__stock-text-decoration                        : none;
$product-view__stock-text-decoration-hover                  : underline;

// rating
$product-view__rating-padding                               : 0 0 $spacer;
$product-view__rating-border                                : $border-base;
$product-view__rating-border-width                          : 0 0 $border-width-base;

// price
$product-view__price-display                                : none;
$product-view__price-display\@display-mq                    : block;
$product-view__price-display-mq                             : $screen-m;
$product-view__price-padding                                : $spacer--medium 0;

$product-view__price-display--between                       : flex;
$product-view__price-flex-direction--between                : column;
$product-view__price-line-height--between                   : 1.2;
$product-view__price-text-display--between                  : inline-flex;
$product-view__price-text-min-width--between                : 50px;

// short description
$product-view__short-description-heading-font-family        : $font-family-base;
$product-view__short-description-heading-font-size          : $font-size-base;
$product-view__short-description-heading-font-weight        : $font-weight-bold;
$product-view__short-description-heading-color              : $color-secondary;
$product-view__short-description-heading-text-transform     : none;

// options
$product-view__select-option-height                         : 56px;
$product-view__options-margin                               : 0 0 $spacer--semi-medium;

// buttons
$product-view__buttons-mq-display                           : $screen-m;

// button
$product-view__button-width                                 : 48%;
$product-view__button-width\@medium                         : auto;
$product-view__button-margin\@medium                        : 0 0 0 $spacer;
$product-view__button-min-size                              : 56px;
$product-view__button-bg                                    : $gray-lighter;

$product-view__button-wishlist-filled-bg-color-hover        : $color-primary;
$product-view__button-wishlist-filled-bg-image-after-hover  : linear-gradient(to right, $white 50%, transparent 50%);
$product-view__button-wishlist-width                        : 100%;
$product-view__button-wishlist-width\@medium                : auto;
$product-view__button-wishlist-margin\@medium               : 0 0 0 $spacer;
$product-view__button-wishlist-min-width                    : 56px;
$product-view__button-wishlist-min-height                   : 56px;
$product-view__button-wishlist-background-color             : $gray-lighter;

$product-view__dropdown-wishlist-width                      : 100%;
$product-view__dropdown-wishlist-margin                     : $spacer 0 0;
$product-view__dropdown-wishlist-margin\@medium             : $spacer--medium 0 0;

$product-view__dropdown-wishlist-button-width               : 100%;
$product-view__dropdown-wishlist-button-after-left          : calc(50% - 63px);
$product-view__dropdown-wishlist-content-min-width\@medium  : 360px;
$product-view__dropdown-wishlist-content-min-width\@large   : 200%;

$product-view__requistion-dropdown-order                    : 5;
$product-view__requistion-dropdown-width\@medium            : 55%;
$product-view__requistion-dropdown-width\@large             : 50%;
$product-view__requistion-dropdown-content-right            : 0;

$product-view__multiwishlist-dropdown-order                 : 4;
$product-view__multiwishlist-dropdown-width\@medium         : 44%;
$product-view__multiwishlist-dropdown-width\@large          : 50%;
$product-view__multiwishlist-dropdown-item-padding\@large   : 0 $spacer 0 0;

$product-view__update-wishlist-width                        : 100%;
$product-view__update-wishlist-height                       : 56px;
$product-view__update-wishlist-margin                       : $spacer 0 0;
$product-view__update-wishlist-margin\@medium               : $spacer--medium 0 0;
$product-view__update-wishlist-order                        : 2;

$product-view__extra-actions-order                          : 3;
$product-view__extra-actions-width                          : 100%;
$product-view__extra-actions-width\@medium                  : auto;

// add to cart button
$product-view__add-to-cart                                  : none;
$product-view__order                                        : 1;
$product-view__flex-grow                                    : 1;
$product-view__add-to-cart-height                           : 56px;
$product-view__add-to-cart-padding                          : 0 $spacer--medium;
$product-view__add-to-cart\@buttons-mq-display              : inline-flex;

// update requisition list button
$product-view__update-requisition-list-height               : 56px;
$product-view__update-requisition-list-margin               : 0 0 $spacer--medium;
$product-view__update-requisition-list-width                : 100%;
$product-view__update-requisition-list-button-width         : 100%;

// qty
$product-view__qty-margin                                   : 0 15px 0 0;

// stock status
$product-view__stock-status-margin                          : $spacer--medium 0 0 0;
$product-view__stock-status-margin\@medium                  : 0;

// brief
$product-view__brief-margin                                 : $spacer--large 0;
$product-view__brief-margin\@medium                         : $spacer--medium 0 $spacer--semi-medium;
$product-view__brief-title-text-transform                   : capitalize;
$product-view__brief-title-font-weight                      : $font-weight-bold;
$product-view__brief-title-font-size                        : $font-size-base;
$product-view__brief-subtitle-font-size                     : $font-size-small;
$product-view__brief-item-margin                            : 0 0 $spacer;

// form
$product-view__form-margin                                  : 15px 0 0;
$product-view__form-padding                                 : 20px 0 0;
$product-view__form-border                                  : $border-base;
$product-view__form-border-width                            : 0 0 $border-width-base;

// summary
$product-view__summary-padding                              : $spacer--medium 0 0;
$product-view__summary-margin                               : 0 0 $spacer--large;
$product-view__summary-margin\@medium                       : 0;
$product-view__summary-width                                : 100%;
$product-view__summary-title-font-family                    : $font-family-base;
$product-view__summary-title-font-size                      : $font-size-medium;
$product-view__summary-title-font-weight                    : $font-weight-bold;
$product-view__summary-title-text-transform                 : uppercase;
$product-view__summary-title-margin                         : 0 0 $spacer--medium;
$product-view__summary-list-font-size                       : $font-size-medium;
$product-view__summary-list-item-title-font-weight          : $font-weight-medium;
$product-view__summary-list-item-title-text-transform       : uppercase;
$product-view__summary-list-item-value-color                : $gray-dark;
$product-view__summary-price-margin                         : 0 0 $spacer--medium;
$product-view__summary-price-font-size                      : $font-size-extra-large;
$product-view__summary-price-font-weight                    : $font-weight-bold;
$product-view__summary-unit-padding                         : 0 0 0 $spacer--extra-small;
$product-view__summary-unit-font-size                       : $font-size-extra-large;

// custamization
$product-view__customization-border                         : $border-base;
$product-view__customization-border-width                   : 0 0 1px;
$product-view__customization-padding                        : 0 0 $spacer--medium;
$product-view__customization-margin                         : 0 0 $spacer;

// tabs
$product-view__tabs-margin                                  : 0 auto;
$product-view__tabs-margin\@medium                          : 64px auto 0;
$product-view__tabs-padding\@medium                         : 0 $spacer--semi-medium;
$product-view__tabs-padding\@large                          : 0;
$product-view__tabs-title-height                            : 56px;
$product-view__tabs-title-padding                           : 0 0 0 $spacer;
$product-view__tabs-title-padding\@medium                   : 0 0 0 $spacer--semi-medium;
$product-view__tabs-title-padding\@large                    : 0;
$product-view__tabs-title-text-align                        : left;
$product-view__tabs-title-text-align\@large                 : center;

$product-view__tabs-sticky-bg                               : $white;
$product-view__tabs-sticky-border                           : $border-base;
$product-view__tabs-sticky-border-width                     : 0 0 $border-width-base 0;
$product-view__tabs-sticky-shadow                           : $shadow;
$product-view__tabs-sticky-show-mq                          : $screen-m;
$product-view__tabs-sticky-max-width                        : $product-view__max-width\@large;
$product-view__tabs-sticky-margin                           : 0 auto;
$product-view__tabs-sticky-z-index                          : $z-index-high;
$product-view__tabs-sticky-label-height                     : 48px;
$product-view__tabs-sticky-label-border-width               : 0;
$product-view__tabs-sticky-label-decoration                 : none;

// product slider
$product-view__slider-margin                                : $spacer--extra-large 0;

// grouped table
$product-view__grouped-table-margin                         : 0 0 $spacer--medium;
$product-view__grouped-table-border-width                   : 0;
$product-view__grouped-cell-border-right                    : 0;
$product-view__grouped-th-padding                           : $spacer 0;
$product-view__grouped-th-font-size                         : $font-size-base;
$product-view__grouped-th-font-weight                       : $font-weight-base;
$product-view__grouped-th-bg-color                          : $white;
$product-view__grouped-th-align                             : right;
$product-view__grouped-th-align\@medium                     : left;
$product-view__grouped-td-padding                           : $spacer--medium 0;
$product-view__grouped-td-vertical-align                    : top;
$product-view__grouped-td-qty-padding                       : 0 0 0 $spacer--medium;
$product-view__grouped-td-name-font-weight                  : $font-weight-medium;
$product-view__grouped-td-name-margin\@medium               : 0 0 $spacer 0;
$product-view__grouped-qty-field-max-width                  : 112px;
$product-view__grouped-qty-field-border-color               : $gray;
$product-view__grouped-qty-field-align                      : center;

// sticky EE version
$product-view__add-to-cart-ee-wrapper-padding               : $spacer--small $spacer $spacer;
$product-view__add-to-cart-ee-wrapper-padding\@medium       : $spacer--semi-medium $spacer;
$product-view__add-to-cart-ee-wrapper-padding\@large        : $spacer--semi-medium $spacer--semi-large;

$product-view__add-to-cart-ee-action-bottom                 : $spacer;
$product-view__add-to-cart-ee-action-right                  : $spacer;

$product-view__add-to-cart-ee-price-box-padding             : $spacer--small 0 0;
$product-view__add-to-cart-ee-price-box-padding\@large      : $spacer--extra-small 0 0;

// recaptcha
$product-view__grecaptcha-badge-bottom                      : 112px;
$product-view__grecaptcha-badge-bottom\@medium              : 0;


$product-view__table-th-border-color                        : #FFFFFF;

// Top block
$product-view__conteiner-padding                            : 0 15px;

// details
$product-view__details-width                                : 352px;
$product-view__section-midtone                              : $brew-section-midtone;
$product-view__border                                       : $brew-border;
$product-view__right-padding                                : 88px;
$product-view__left-padding                                 : 86px;
$product-view__top-padding                                  : 67px;
$product-view__details-margin                               : 83px auto 0;
$product-view__details-padding                              : 60px 15px 0;
$product-view__details-mobile-margin-top                    : 40px;
$product-view__details-mobile-padding-top                   : 40px;

// description
$product-view__description-width                            : 564px;

// summary
$product-view__summary-padding                              : 15px 15px 20px 15px;
$product-view__summary-padding-top                          : 20px;
$product-view__summary-margin-top                           : 20px;
// show more
$product-view__show-more-margin                             : 63px;
$product-view__show-more-arrow-size                         : 8px;

// shipping info
$product-view__shipping-info-icon-size                      : 14px;
$product-view__shipping-info-tooltip-padding                : 23px 10px 17px 23px;
$product-view__shipping-info-min-width                      : 260px;
$product-view__shipping-info-tooltip-arrow-right            : 76px;

// bubble
$product-view__information-bubble-padding                   : 16px 20px 7px;
$product-view__information-bubble-margin-bottom             : 32px;
$product-view__information-bubble-margin-page-icons-margin  : 0 43px 0 10px;
$product-view__information-bubble-p-margin-bottom           : 28px;

// banner
$product-view__product-banner-padding                       : 24px 24px 68px;
$product-view__product-banner-margin-titel-bottom           : 20px;
$product-view__product-banner-margin-bottom                 : 0 0 50px;
// grouped
$product-view__grouped-gallery-width                        : 400px;
$product-view__grouped-gallery-padding                      : 50px;
$product-view__grouped-filter-padding                       : 26px 15px;
$product-view__grouped-filter-margin                        : 20px auto 30px;
$product-view__grouped-filter-padding-m                     : 40px 15px 42px 65px;
$product-view__grouped-filter-margin-m                      : 80px auto 60px;
$product-view__grouped-filter-header-margin                 : 38px;
$product-view__grouped-filter-body-margin                   : 20px;
$product-view__grouped-filter-body-margin-m                 : 37px;
$product-view__grouped-filter-body-width                    : 220px;
$product-view__grouped-actions-width                        : 310px;
$product-view__grouped-no-products-message-margin           : 60px;
