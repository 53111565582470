$tooltip__border: 1px solid #ACACAC;

$tooltip__icon-size: 13px;
$tooltip__icon-color: $brew-text--accent;
$tooltip__icon-font-color: $brew-text--light;
$tooltip__icon-pseudo-size: 15px;
$tooltip__icon-pseudo-bottom: -20px;

$tooltip__content-color: $brew-section-bright;
$tooltip__content-shadow: 0 3px 16px rgba(0, 0, 0, 0.19);
