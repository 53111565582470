$product-list-item__padding                               : 20px;
$product-list-item__transition                            : $transition-base;

$product-list-item__image-width                           : 100px;
$product-list-item__image-width\@medium                   : 260px;
$product-list-item__image-margin-right                    : $spacer;
$product-list-item__image-margin\@xl                      : 0 32px 0 0;
$product-list-item__image-link-decoration                 : none;

$product-list-item__image-margin                          : 0 32px 0 0;
$product-list-item__main-width                            : calc(100% - #{$product-list-item__image-width} - #{$product-list-item__image-margin-right});

$product-list-item__badges-z-index                        : $z-index-low;

$product-list-item__name-font-size                        : $font-size-base;
$product-list-item__name-margin                           : 0 0 20px;
$product-list-item__name-text-transform                   : none;
$product-list-item__name-decoration                       : none;
$product-list-item__name-decoration-hover                 : none;
$product-list-item__name-color-hover                      : $color-primary;

$product-list-item__sku-font-size                         : $font-size-extra-small;
$product-list-item__sku-font-size\@medium                 : $font-size-base;
$product-list-item__sku-color                             : $color-secondary;
$product-list-item__sku-margin                            : 0 0 $spacer--medium;

$product-list-item__description-margin                    : 0 0 $spacer;
$product-list-item__description-font-size                 : $font-size-extra-small;
$product-list-item__description-font-size\@medium         : $font-size-base;
$product-list-item__description-color                     : $color-secondary;

$product-list-item__list-display                          : block;
$product-list-item__list-color                            : $color-secondary;
$product-list-item__list-font-size                        : $font-size-base;
$product-list-item__list-margin                           : 0;
$product-list-item__list-label-padding                    : 0 $spacer $spacer $spacer--medium;

$product-list-item__price-width                           : 100%;
$product-list-item__price-margin\@medium                  : 7px 0 32px 0;
$product-list-item__price-align                           : left;
$product-list-item__price-font-size\@medium               : $font-size-extra-large;
$product-list-item__price-old-font-size\@medium           : $font-size-small;
$product-list-item__price-old-margin\@medium              : 0;

$product-list-item__action-icon-fill                      : $gray-darker;
$product-list-item__action-margin                         : 0 $spacer--extra-small 0 0;
$product-list-item__action-margin\@medium                 : 0 $spacer 0 0;
$product-list-item__action-margin-individual-background   : $brew-yellow;
$product-list-item__action-margin-individual-color        : $brew-dark-grey;

$product-list-item__add-to-action-flex-grow               : 0;
$product-list-item__add-to-action-max-width               : 250px;
$product-list-item__add-to-action-min-width\@large        : 160px;

$product-list-item__add-to-icon-display                   : block;
$product-list-item__add-to-icon-display\@medium           : block;
$product-list-item__add-to-icon-display\@xl               : none;

$product-list-item__stock-width                           : 100%;
$product-list-item__stock-display                         : none;
$product-list-item__stock-display\@medium                 : inline-flex;
$product-list-item__stock-decoration                      : none;
$product-list-item__stock-padding                         : 0;
$product-list-item__stock-text-aligment                   : flex-start;
$product-list-item__stock-text-aligment\@medium           : flex-end;
$product-list-item__stock-text-transform                  : uppercase;
$product-list-item__stock-text-decoration-hover           : underline;

$product-list-item__swatch-loader-icon-margin             : 0 $spacer--semi-large 0 0;

$product-list-item__wishlist-filled-bg-color-hover        : $color-primary;
$product-list-item__wishlist-filled-bg-image-after-hover  : linear-gradient(to right, $white 50%, transparent 50%);

// EE Version
$product-list-item-ee__tocompare-background-color         : $gray-lighter;
$product-list-item-ee__towishlist-background-color        : $gray-lighter;
$product-list-item-ee__multiwishlist-width                : 48px;
$product-list-item-ee__multiwishlist-margin               : 0 $spacer 0 0;
$product-list-item-ee__dropdown-list-content-top          : auto;
$product-list-item-ee__dropdown-list-content-bottom       : 100%;
$product-list-item-ee__dropdown-list-content-width        : 180px;
$product-list-item-ee__dropdown-list-content-width\@medium: 210px;
$product-list-item-ee__dropdown-list-label-width          : 48px;
$product-list-item-ee__dropdown-list-label-height         : 48px;
