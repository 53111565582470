$dashboard__handler-margin                : 0 0 $spacer--large;

$dashboard__title-margin                  : 0 0 $spacer--semi-medium;
$dashboard__title-padding                 : $spacer--medium;
$dashboard__title-border                  : $border-secondary;
$dashboard__title-background              : $bg-color-secondary;
$dashboard__title-font-family             : $font-family-base;
$dashboard__title-font-size               : $font-size-large;
$dashboard__title-font-weight             : $font-weight-bold;
$dashboard__title-border-width            : 0 0 $border-width-secondary;

$dashboard__subtitle-font-size            : $font-size-medium;

$dashboard__status-padding                : $spacer $spacer--medium;
$dashboard__status-border                 : $border-base;
$dashboard__status-border-radius          : $border-radius;
$dashboard__status-font-size              : $font-size-small;

$dashboard__order-date-font-weight        : $font-weight-bold;

$dashboard__form-padding                  : 0 $spacer--medium;
$dashboard__form-padding-left--first-child: $spacer;
$dashboard__form-padding-right--last-child: $spacer;

$dashboard__product-padding               : 0;
$dashboard__product-border                : none;

$dashboard__sidebar-border                : $border-secondary;
$dashboard__sidebar-border-width          : 0 $border-width-secondary 0 0;
$dashboard__sidebar-padding\@large        : 0 $spacer--medium 0 $spacer;
$dashboard__sidebar-padding\@extra-large  : 0 64px 0 $spacer;

$dashboard__content-padding\@large        : 0 $spacer 20px $spacer--extra-large;
$dashboard__content-padding\@extra-large  : 0 $spacer 30px 64px;
$dashboard__content-heading-padding       : 0 0 $spacer--extra-large;
