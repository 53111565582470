$toolbar__gray                                  : #D1D1D1;
$toolbar__gray-darker                           : #666;
$toolbar__border-color                          : #E5E5E5;
$toolbar__white                                 : $white;
$toolbar__mode-background                       : $form-elements-border-color;
$toolbar__font-weight-bold                      : $font-weight-bold;

$toolbar__select-height                         : 22px;
$toolbar__font-line-height-middle               : 22px;
$toolbar__mode-switcher-spacer                  : 20px;
$toolbar__mode-switcher-width                   : 40px;
$toolbar__mode-switcher-height                  : 12px;
$toolbar__mode-switcher-radius                  : 6px;
$toolbar__mode-switcher-margin                  : 0 10px;
$toolbar__mobile-padding-top                    : 27px;
$toolbar__mobile-sorter-icon-size               : 23px;
$toolbar__mobile-sorter-radius                  : 4px;

$toolbar__padding                               : $spacer 0;
$toolbar__padding\@medium                       : $spacer--semi-medium 0;
$toolbar__padding\@large                        : 0 0 $spacer--medium;
$toolbar__border-style                          : solid;
$toolbar__border-color                          : $gray;
$toolbar__border-width                          : 0 0 1px 0;

$toolbar__mode-margin                           : 0 $spacer--medium 0 0;
$toolbar__mode-button-icon-fill                 : $color-secondary;
$toolbar__mode-button-icon-fill--active         : $color-primary;
$toolbar__mode-button-icon-size                 : 16px;

$toolbar__amount-color                          : $color-secondary;
$toolbar__amount-padding--visible               : $spacer 0;

$toolbar__sorter-width                          : 100%;
$toolbar__sorter-width\@medium                  : auto;
$toolbar__sorter-select-min-width               : 160px;
$toolbar__sorter-border-width                   : 1px 0 0 0;
$toolbar__sorter-border-width\@medium           : 0;
$toolbar__sorter-padding                        : $spacer 0 0;
$toolbar__sorter-padding\@medium                : 0;
$toolbar__sorter-margin                         : $spacer 0 0;
$toolbar__sorter-margin\@medium                 : 0;
$toolbar__sorter-order                          : 2;
$toolbar__sorter-order\@medium                  : 0;
$toolbar__sorter-link-padding                   : 0 $spacer;
$toolbar__sorter-link-font-weight               : $font-weight-normal;
$toolbar__sorter-link-bg-hover                  : $color-primary;

$toolbar__sorter-label-margin                   : 0 $spacer--small 0 0;

$toolbar__select-spacer-right                   : 25px;
$toolbar__element-spacer-left                   : 30px;

$toolbar__font-size-medium                      : $font-size-medium;

//toolbar--secondary
$toolbar__justify-content--secondary            : space-between;
$toolbar__padding--secondary                    : $spacer--semi-medium 0;

$toolbar__amount-order--secondary               : 1;
$toolbar__amount-flex-grow--secondary           : 0;

$toolbar__pager-order--secondary                : 1;
$toolbar__pager-flex-grow--secondary            : 2;
$toolbar__pager-flex-basis--secondary           : 100%;
$toolbar__pager-margin--secondary               : 0 0 $spacer--medium;

$toolbar__sorter-limiter-order--secondary       : 3;
$toolbar__sorter-limiter-flex-grow--secondary   : 0;
