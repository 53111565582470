$breadcrumbs__display\@mobile        : none;
$breadcrumbs__display-mq             : $screen-l;
$breadcrumbs__margin                 : 4px;
$breadcrumbs__margin-bottom          : 10px;
$breadcrumbs__padding                : 14px 10px 11px;
$breadcrumbs__min-height             : 40px;

$breadcrumbs__color                  : #D1D1D1;
$breadcrumbs__color-hover            : #333333;
$breadcrumbs__color--active          : $color-primary;

$breadcrumbs__font-family            : $font-family-base;
$breadcrumbs__font-family--active    : $font-family-base;
$breadcrumbs__font-size              : $font-size-small;
$breadcrumbs__line-height            : 17px;


$breadcrumbs__list-padding           : 0;
$breadcrumbs__list-margin            : 0;
$breadcrumbs__list-flow              : row wrap;

$breadcrumbs__item-padding           : 0;

$breadcrumbs__link-padding           : 0;

$breadcrumbs__text-decoration        : none;
$breadcrumbs__text-decoration--active: none;
