// ------------------------------------------------------------------
// @author    Sarah Lee Mandolla <SarahLee.Mandolla@brewes.de>
// @author    Alex Mischarin <Alex.Mischarin@brewes.de>
// @copyright 2023 Brewes GmbH
// @link      https://www.brewes.de/
//
// ------------------------------------------------------------------
// CSS for Brewes individual pages built by the Magento2 Page Builder
// ------------------------------------------------------------------

@import "../../Atoms/variables/brewes-variables";

// variables
$main-image-with-gap-width: 563px;
$collapsed-explanation-text-max-height: 449px;
$main-image-max-size: 533px;
$inline-image-max-size: 171px;
$block-gap-xl: 78px;
$block-gap: 68px;
$block-gap-s: 52px;
$block-gap-xs: 21px;
$column-gap: 29px;
$column-gap-s: 13px;
$image-gap: 10px;
$image-gap-s: 5px;
$annotation-gap: 34px;
$property-border-width: 2px;

// first content block: `row` element class
.text-and-pictures {
    margin-top: $block-gap-xs !important;

    // left text block: `column` element class
    .text-column {
        flex-grow: 1;
        padding-right: $column-gap;
        margin-right: 0 !important;
        width: auto !important;
    }

    //right pictures block: `column` element class
    .pictures {
        max-width: $main-image-max-size !important;
        display: block !important;
    }

    // first (main) image: `image` element class
    .main-image {
        img {
            min-width: $main-image-max-size !important;
            display: block;
            margin-bottom: $image-gap;
        }
    }

    // other images: `image` element class
    .inline-images {
        width: $inline-image-max-size !important;
        margin-right: $image-gap;
        display: inline-block;
        img {
            width: inherit;
        }
    }
    .inline-images:last-child {
        margin-right: 0;
    }

    // left text block: `text` element class
    .text {
        overflow: hidden;
        position: relative;
        height: $collapsed-explanation-text-max-height;
    }
    .text:after { // gradient fadeout overlay for text when its collapsed
        position: absolute;
        bottom: 0;
        min-height: $collapsed-explanation-text-max-height;
        width: 100%;
        content: "";
        background: linear-gradient(to top, white 0, rgba(255, 255, 255, 0) 106px);
        pointer-events: none; // so the text is still selectable
    }

    // trigger to expand the explanation text: `text` element class
    // + additional classes: heading heading--arrow-gray heading--third-level uppercase
    .show-more-text {
        cursor: pointer;
        color: $brew-text;
        font-size: 16px !important;
        font-style: italic;
        padding-top: $column-gap;
        h3 {
            font-size: inherit;
            color: inherit;
            font-style: inherit;
        }
    }

    // class for explanation text when it is expanded
    // sets by JS
    .unfolded {
        height: 100%;

        &::after {
            background: unset;
            //height: 100%;
        }
    }
}

// second content block: `row` element class
.properties {
    padding: 0 !important;
    margin-bottom: 0 !important;
    margin-top: $block-gap !important;

    // block with header and list: `column` element class
    .property-solid {
        padding: 30px 33px !important;
        background-color: $brew-section-midtone;
        border-right: $property-border-width solid white;
        &:last-child {
            border-right: none;
        }
        ul {
            padding-left: 58px;
        }
    }

    // block with header and list: additional `column` element class
    // for blocks with full width
    .property-solid-fullwidth {
        &.border-bottom {
            border-right: none;
            border-bottom: $property-border-width solid white;
        }

        // additional `heading` element class
        // fix height of empty element
        .empty-heading {
            height: 1em;
        }
    }
}

.brewes-material {

    &-table {

        table {
            border: none;

            tr:nth-child(odd) {
                background-color: #ffffff;
            }

            tr:nth-child(even) {
                background-color: #f3f3f3;
            }

            td {
                border: none;
                padding: 10px;
                font-size: 12px;
                hyphens: auto;
            }

            td:first-child {
                width: 60% !important;
            }

            td:last-child {
                width: 40% !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {

    // first content block: `row` element class
    .text-and-pictures {

        // trigger to expand the explanation text: `text` element class
        // fix style for mobile layout
        .show-more-text {
            padding-top: $column-gap-s;
        }

        // right pictures block: `column` element class
        // rearrange images
        .pictures {
            width: 100% !important;
            max-width: unset !important;
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: wrap;
            margin: $column-gap-s auto 0 auto !important;

            // first (main) image: `image` element class
            // fix style for mobile layout
            .main-image {
                img {
                    min-width: 100% !important;
                    margin-bottom: 0;
                }
            }

            // other images: `image` element class
            // fix style for mobile layout
            .inline-images {
                margin-right: 0;
                img {
                    width: 100%;
                    display: block;
                }
            }

            // set width and gaps for all images
            figure,
            figure.inline-images {
                width: 50% !important;
            }
            figure:nth-child(odd) {
                padding: 0 $image-gap-s $image-gap 0;
            }
            figure:nth-child(even) {
                padding: 0 0 $image-gap $image-gap-s;
            }
        }
    }
    // second content block: `row` element class
    .properties {
        margin-top: $annotation-gap !important;

        // block with header and list: `column` element class
        // style for rearranged properties
        .property-solid {
            border-right: none;
            border-bottom: $property-border-width solid white;
        }

        // full width block with header and list: additional `column` element class
        // fix style for multiple linked lists
        .property-solid-fullwidth {
            .pagebuilder-column {
                h2 {
                    display: none;
                }
                ul {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            .pagebuilder-column:first-child {
                h2 {
                    display: flex;
                }
                ul {
                    margin-top: 1em;
                }
            }
            .pagebuilder-column:last-child {
                ul {
                    margin-bottom: 1em;
                }
            }

            // additional `heading` element class
            // fix height of empty element for mobile layout
            .empty-heading {
                height: 0;
            }
        }
    }

    // fix row style for mobile layout
    .properties[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
        margin: 0 !important;
        width: 100% !important;
    }

    // third content block (with form): `row` element class
    .form {

        // fix styles for mobile layout
        .show-more-form {
            margin-top: 0;
        }
        .pagebuilder-column {
            margin: 0 !important;
        }

        // set mobile elements visible
        .show-mobile {
            display: block;
        }

        // set desktop elements invisible
        .dontshow-mobile {
            display: none;
        }
    }

    #html-body .brewes-material[data-content-type="row"] {

        .pagebuilder-column-line {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .pagebuilder-column {
            width: calc(50% - 10px) !important;
        }
    }
}

@media screen and (max-width: 767px) {
    #html-body .brewes-material[data-content-type="row"] {

        .pagebuilder-column-line {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .pagebuilder-column {
            width: calc(50% - 10px) !important;
            flex-basis: auto;
        }
    }
}

@media screen and (max-width: $main-image-with-gap-width) {

    // first content block: `row` element class
    .text-and-pictures {

        //right pictures block: `column` element class
        // fix style for vertical rearrange
        .pictures {
            figure,
            figure.inline-images {
                width: 100% !important;
            }
            figure:nth-child(odd) {
                padding: 0 0 $column-gap-s 0;
            }
            figure:nth-child(even) {
                padding: 0 0 $column-gap-s 0;
            }
        }
    }

    #html-body .brewes-material[data-content-type="row"] {

        .pagebuilder-column-line {
            flex-direction: column;
            row-gap: 15px;
        }

        .pagebuilder-column {
            width: 100% !important;
        }
    }

}
