@import 'magazin-view-variables';

.magazin {
    margin-top: -44px;

    @include mq($screen-l) {
        margin-top: -40px;
    }

    &__wrapper {
        max-width: $max-content-width--full;
        margin: 0 auto;

        @include mq($screen-l) {
            display: grid;
            grid-template-columns: $magazin__content-template-columns\@medium;
            grid-template-areas:
            "image image       image       image"
            ".     breadcrumbs breadcrumbs ."
            ".     title       sidebar     ."
            ".     content     sidebar     ."
            ".     bottom      sidebar     .";
        }

        &--without-sidebar {
            @include mq($screen-l) {

                grid-template-areas:
                "image image       image       image"
                ".     breadcrumbs breadcrumbs ."
                ".     title       title       ."
                ".     content     content     ."
                ".     bottom      bottom      .";
            }
        }

        @include mq($screen-xl) {
            grid-template-columns: $magazin__content-template-columns\@large;
        }

        @include mq($screen-xxl) {
            grid-template-columns: $magazin__content-template-columns\@extralarge;
        }
    }

    &__main-image {
        grid-area: image;
        height: $magazin__image-height;
        margin-bottom: $magazin__image-indent;

        @include mq($screen-m) {
            height: $magazin__image-height\@medium;
        }

        @include mq($screen-l) {
            height: $magazin__image-height\@large;
            margin-bottom: $magazin__image-indent\@large;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__title {
        grid-area: title;
        font-size: $magazin__main-title-font-size;
        line-height: $magazin__main-title-line-height;
        margin-bottom: $magazin__main-title-margin;
        color: $magazin__main-title-font-color;
        padding: 0 15px;

        @include mq($screen-m) {
            font-size: $magazin__main-title-font-size\@medium;
            line-height: $magazin__main-title-line-height\@medium;
            margin-bottom: $magazin__main-title-margin\@medium;
        }

        @include mq($screen-l) {
            font-size: $magazin__main-title-font-size\@large;
            line-height: $magazin__main-title-line-height\@large;
            margin-bottom: $magazin__main-title-margin\@large;
            padding: 0;
        }
    }

    &__content {
        grid-area: content;
        margin-bottom: $magazin__content-margin;
        padding: $magazin__content-padding;
        color: $magazin__content-font-color;

        @include mq($screen-m) {
            margin-bottom: $magazin__content-margin\@medium;
        }

        @include mq($screen-l) {
            padding: $magazin__content-padding\@large;
            margin-bottom: $magazin__content-margin\@large;
        }

        p {
            color: $magazin__content-font-color;
        }

        a {
            color: $brew-text--accent;
            text-decoration: none;

            &:hover {
                color: $brew-accent--darker2;
                text-decoration: underline;
            }
        }

        img {
            &.position {
                &-left {
                    margin-right: 1em;
                    float: left;
                }
                &-right {
                    margin-left: 1em;
                    float: right;
                }
                &-center {
                    margin: 0 auto;
                }
            }
        }
    }

    &__bottom {
        grid-area: bottom;
    }

    &__sidebar {
        grid-area: sidebar;
        padding-left: $magazin__sidebar-left;
        display: none;

        @include mq($screen-l) {
            display: block;
            position: relative;
        }

        &-content {
            position: sticky;
            top: $magazin__sidebar-content-top;
            background-color: $magazin__sidebar-content-background;
            padding: $magazin__sidebar-content-padding;

            @include mq($screen-xxl) {
                padding: $magazin__sidebar-content-padding\@large;

            }
        }

        &-title {
            margin-bottom: $magazin__sidebar-title-margin;
        }

        &-post {
            padding: 28px 20px;
            background-color: #F9F9F9;
            margin-bottom: 13px;
            transition-duration: 100ms;
            width: 100%;

            &:hover {
                box-shadow: 0 3px 16px rgba(0, 0, 0, 0.29);
                text-decoration: none;

                .magazin__sidebar-author {
                    color: #5C6166;
                }
            }

            &-title {
                display: block;
                font-weight: 600;
                font-size: 17px;
                line-height: 19px;
                margin-bottom: 5px;
            }
        }

        &-author {
            font-weight: 600;
            font-size: 13px;
            line-height: 19px;
            color: $brew-text;
        }

        &-left {
            position: relative;
            display: inline-flex;
            width: 56px;
            height: 60px;
            margin-right: 39px;
            flex: 0 0 auto;

            .icon {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: 21px;

            &-row {
                color: #999999;
                line-height: 19px;

                &:first-child {
                    font-size: 20px;
                    font-weight: 300;
                }

                &:last-child {
                    font-size: 10px;
                }
            }
        }

        &-show-all {
            margin-top: 26px;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 18px;
            color: $brew-titles;
        }
    }

    .breadcrumbs {
        grid-area: breadcrumbs;
        padding: 10px;
        margin: $magazin__breadcrumbs-margin;
        border-bottom: 1px solid $brew-border;
        overflow: auto;

        @include mq($screen-m) {
            margin: $magazin__breadcrumbs-margin\@medium;
        }

        @include mq($screen-l) {
            display: block;
            margin: $magazin__breadcrumbs-margin\@large;
            border-bottom: none;
            padding: 10px 0;
        }

        &__list {
            display: flex;
            flex-wrap: nowrap;
        }

        &__item {
            white-space: nowrap;
        }
    }

    &__info {
        border-bottom: 1px solid $brew-border;
        margin-bottom: $magazin__info-margin-bottom;
        padding-bottom: $magazin__info-padding-bottom;

        @include mq($screen-l) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 0 $magazin__info-margin-bottom\@large;
            padding-bottom: $magazin__info-padding-bottom\@large;
        }

        &-item {
            @include mq(false, $screen-l) {
                padding: 0 15px;
            }

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            @include mq($screen-l) {
                max-width: 40%;

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            &--author {
                .magazin__info-text {
                    display: inline-block;
                    line-height: 25px;
                }
            }

            &--categories {
                .magazin__info-link:not(:last-child)::after {
                    content: ',';
                }
            }
        }

        &-title {
            color: $gray;
            font-size: $magazin__info-title-font-size;
            line-height: $magazin__info-title-line-height;
            margin-bottom: $magazin__info-title-margin-bottom;
            font-style: $magazin__info-title-font-style;
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            * {
                color: $brew-text;
                margin-right: auto;
                display: inline-block;
                font-size: $magazin__info-content-font-size;
                line-height: $magazin__info-content-line-height;
            }

            .magazin__info-text {
                line-height: 25px;
            }
        }

        &-link {
            line-height: 25px;
        }
    }

    &__related {
        padding: 0 15px;

        @include mq($screen-l) {
            padding: 0;
        }

        &-title {
            margin-bottom: 30px;

            @include mq($screen-l) {
                margin-bottom: 40px;
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
        }

        &-item {
            margin: 0 17px 10px 0;
        }
    }

    &__carousel {
        &.container {
            padding-left: 0;
            padding-right: 0;

            @include mq($screen-xxl) {
                max-width: 77%;
            }

            @include mq(false, $screen-xxl - 1) {
                max-width: 100%;
                margin: 0 15px;
            }

            @include mq(false, $screen-m) {
                margin: 0 0;
            }
        }
    }

    .breadcrumbs {
        display: flex;
    }
    figure {
        margin: 0 auto;
        width: 100%;
    }
}

.magazin-footer__carousel {
    .slider--products .slider__item {
        padding-top: 20px;

        .product-grid-item__image-wrapper {
            height: 246px;
        }
        .product-grid-item__name {
            margin-top: 43px;
        }
    }
}
