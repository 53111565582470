// Table
$table__width                                                : 100%;
$table__padding                                              : 0;
$table__background                                           : $white;
$table__margin                                               : 0;
$table__border-width-base                                    : 2px;
$table__border                                               : $table__border-width-base $border-style-base $brew-section-bright;
$table__border-radius                                        : $border-radius;
$table__border-collapse                                      : separate;
$table__border-spacing                                       : 0;
$table__color                                                : #666;
$table__line-height                                          : $font-line-height;
$table__font-size                                            : $font-size-base;
$table__font-size\@medium                                    : $font-size-base;

// Clean variant
$table__th-font-weight--clean                                : $font-weight-bold;
$table__th-background--clean                                 : transparent;
$table__background-odd--clean                                : $gray-lighter;
$table__background-even--clean                               : $white;

// Table heading
$table__th-background                                        : $gray-lightest;
$table__th-padding                                           : 9px 20px;
$table__th-padding\@medium                                   : $spacer--medium;
$table__th-border-bottom\@medium                             : $table__border;
$table__th-border-right\@medium                              : $table__border;
$table__th-text-align                                        : left;
$table__th-background-row                                    : $white;
$table__th-padding-row                                       : 9px 20px;
$table__th-border-width-row                                  : $table__border-width-base;
$table__th-text-align-row                                    : left;
$table__th-width-row                                         : 50%;

// Table data
$table__td-background                                        : $white;
$table__td-padding                                           : 9px 20px;
$table__td-padding\@medium                                   : 9px 20px;
$table__td-border-bottom\@medium                             : $table__border;
$table__td-border-right\@medium                              : $table__border;
$table__td-font-weight-before                                : normal;
$table__td-margin-right-before                               : $spacer;
$table__td-width-before                                      : 25%;
$table__td-max-width-before                                  : 25%;

// Table row
$table__tr-padding                                           : $spacer--medium 0;
$table__tr-padding\@medium                                   : 0;
$table__tr-border-bottom                                     : $table__border;
$table__tr-border-bottom\@medium                             : 0;

// Table footer
$table__tfoot-tr-border-top-first-child                      : $table__border;
$table__tfoot-td-padding                                     : $spacer $spacer--medium;
$table__tfoot-td-padding\@medium                             : $spacer--medium;
$table__tfoot-td-background                                  : $table__background;
$table__tfoot-td-background\@medium                          : $table__background;
$table__tfoot-td-border-right\@medium                        : $table__border;
$table__tfoot-td-border-top\@medium                          : $table__border;

// Odd even variant
$table__background-odd--odd-even                             : $white;
$table__background-even--odd-even                            : $gray-lighter;
$table__td-background--odd-even                              : transparent;
$table__border-top-left-radius-first-child--odd-even         : 0;
$table__border-top-left-radius-first-child--odd-even\@medium : 0;
$table__border-top-right-radius-first-child--odd-even        : 0;
$table__border-top-right-radius-first-child--odd-even\@medium: 0;

// Scope row variant
$table__tr-padding--scope-row                                : 0;
$table__border-width--scope-row                              : $table__border-width-base 0 0 0;
$table__tr-background-odd--scope-row                         : $brew-section-midtone;
$table__th-font-wieght--scope-row                            : $font-weight-normal;

// Table footer scope row variant
$table__tfoot-th-border-right-width--tfoot-scope-row         : $border-width-base;
$table__tfoot-th-border-top--tfoot-scope-row                 : $table__border;
