$banner__width                                              : 100%;
$banner__text-decoration                                    : none;

$banner__image-width                                        : 100%;

$banner__content-padding                                    : $spacer--medium $spacer--extra-small $spacer--small;
$banner__content-padding\@medium                            : $spacer--medium 0 $spacer--small $spacer--semi-medium;
$banner__content-border                                     : $spacer--extra-small solid $gray-light;
$banner__content-background                                 : $bg-color-base;
$banner__content-after-height                               : $spacer--extra-small;
$banner__content-after-background                           : $color-primary;
$banner__content-after-transition                           : $transition-base;
$banner__content-after-left                                 : $spacer;
$banner__content-after-width-hover                          : calc(100% - #{2 * $spacer});
$banner__content-after-min-width                            : 0;

$banner__text-margin                                        : 0;
$banner__text-color                                         : $color-primary;
$banner__text-font-size                                     : $font-size-base;
$banner__text-font-size\@medium                             : $font-size-large;
$banner__text-font-weight                                   : $font-weight-bold;
$banner__text-text-transform                                : uppercase;

$banner__icon-size                                          : 48px;

$banner__button-collapse-transform                          : rotate(180deg);
$banner__button-padding--category                           : 0;
$banner__button-text-color-hover--category                  : $color-primary;
$banner__button-icon-fill-hover--category                   : $color-primary;
$banner__button-text-decoration--category                   : underline;
$banner__button-collapse-position--category\@large          : absolute;
$banner__button-collapse-right--category\@large             : 0;
$banner__button-collapse-position--category-image\@large    : relative;
$banner__button-text-margin--category-image                 : 0 $spacer 0 0;

$banner__actions-wrapper-padding                            : $spacer--medium 0 0 0;

$banner__heading-wrapper-padding--category-image            : 0 0 0 $spacer--medium;
$banner__heading-wrapper-position--category-image\@medium   : absolute;
$banner__heading-wrapper-bottom--category-image\@medium     : 32px;
$banner__heading-wrapper-bottom--category-image\@large      : 40px;
$banner__heading-wrapper-bottom--category-image\@xl         : 48px;
$banner__heading-wrapper-left--category-image\@medium       : 32px;
$banner__heading-wrapper-left--category-image\@large        : 48px;
$banner__heading-wrapper-left--category-image\@xl           : 56px;
$banner__heading-wrapper-background--category-image\@medium : $white;
$banner__heading-wrapper-width--category-image\@medium      : 100%;
$banner__heading-wrapper-max-width--category-image\@medium  : 375px;
$banner__heading-wrapper-padding--category-image\@medium    : $spacer--semi-medium $spacer--large $spacer--medium;

$banner__heading-margin--category                           : 0;
$banner__heading-margin--category-image                     : $spacer--semi-medium 0 0;
$banner__heading-margin--category-image\@medium             : 0;
