@import "cart-list-item-variables";

.cart-list-item {
    padding: $cart-list-item__padding;
    margin: 0 -8px;

    &:nth-child(odd) {
        background-color: $brew-section-midtone;
    }

    &:nth-child(even) {
        .cart-list-item__attributes {
            background-color: $brew-section-midtone;
        }
    }

    @include mq($screen-m) {
        display: flex;
        flex-flow: row wrap;
        margin: 0;
        padding: $cart-list-item__padding\@medium;
    }

    @include mq($screen-m) {
        padding: $cart-list-item__padding\@large;
    }

    &__left {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include mq($screen-m) {
            width: calc(100% - 310px);
            padding-right: 30px;
            margin-bottom: 0;
        }
    }

    &__right {
        @include mq($screen-m) {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            width: 310px;
            margin: $cart-list-item__right-margin\@medium;
            padding: $cart-list-item__right-padding\@medium;
        }
    }

    &__image {
        width: $cart-list-item__image-width;

        @include mq($screen-m) {
            width: $cart-list-item__image-width\@medium;
        }

        @include mq($screen-xl) {
            width: $cart-list-item__image-width\@x-large;
        }
    }

    &__content {
        width: $cart-list-item__content-flex-basis;
        padding: $cart-list-item__content-padding;

        @include mq($screen-m) {
            width: $cart-list-item__content-flex-basis\@medium;
        }

        @include mq($screen-xl) {
            width: $cart-list-item__content-flex-basis\@x-large;
            padding: $cart-list-item__content-padding\@x-large;
        }
    }

    &__name {
        display: block;
        font-family: $cart-list-item__name-font-family;
        font-size: $cart-list-item__name-font-size;
        font-weight: $cart-list-item__name-font-weight;
        line-height: $cart-list-item__name-line-height;
        text-transform: $cart-list-item__name-text-transform;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 15px;
        color: $brew-text;

        @include mq($screen-l) {
            font-size: $cart-list-item__name-font-size\@large;
            line-height: $cart-list-item__name-line-height\@large;
            margin-bottom: 35px;
        }
    }

    &__sku {
        line-height: 25px;
        color: $brew-text;
        margin-bottom: 3px;

        @include mq($screen-l) {
            margin-bottom: 0;
        }
    }

    &__delivery {
        line-height: 18px;
        color: $brew-green;

        @include mq($screen-xxl) {
            white-space: nowrap;
        }
    }

    &__attributes {
        width: 100%;
        margin: 20px 0;
        padding: 10px 14px 3px;
        background-color: $white;

        @include mq($screen-l) {
            margin: 30px 0 0;
            padding: 16px 20px 3px;
            max-width: 381px;
        }

        &-title {
            font-weight: 600;
            line-height: 18px;
            color: $brew-text;
            margin-bottom: 10px;
        }
    }

    &__attribute {
        display: block;
        width: 100%;
        color: $cart-list-item__attribute-color;
    }

    &__label,
    &__price {
        color: $brew-text;
        line-height: $cart-list-item__label-line-height;
        font-size: $cart-list-item__label-font-size;
        font-weight: $cart-list-item__label-font-weight;
    }

    &__price {
        @include mq($screen-m) {
            font-size: $cart-list-item__price-font-size\@medium;
        }
    }

    &__label {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 19px;

        @include mq($screen-m) {
            @include visually-hidden;
        }
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;


        @include mq($screen-m) {
            align-items: flex-start;
        }
    }

    &__wrapper {
        width: 80px;

        @include mq($screen-m) {
            width: 100px;
        }
    }

    &__qty {
        flex-direction: column;
        margin: $cart-list-item__qty-margin;

        @include mq($screen-m) {
            flex-direction: $cart-list-item__qty-flex-direction\@medium;
            width: 110px;
        }

        .input__label {
            margin: 0 0 10px 0;

            @include mq(false, $screen-m) {
                text-align: left;
                width: 100%;
            }
        }
    }

    &__input {
        width: $cart-list-item__input-width;
        height: $cart-list-item__input-height;
        appearance: textfield;

        &::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
        &::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes
            appearance: none;
            margin: 0;
        }
    }

    &__total {
        font-size: $cart-list-item__total-font-size;
        line-height: $cart-list-item__total-line-height;
        font-weight: $cart-list-item__total-font-weight;
        color: $brew-text;

        @include mq($screen-m) {
            padding-left: 27px;
        }
    }

    &__price,
    &__total {
        display: block;

        > span:after {
            content: attr(data-label);
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: $brew-text;
            font-weight: 400;
        }
    }

    &__save-info {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 12px;
        line-height: 17px;
        color: $brew-text;
        margin: 10px auto 20px;

        @include mq($screen-m) {
            width: 110px;
            margin-left: 100px;
        }

        &-icon {
            display: inline-block;
            background: $brew-text--accent;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            position: relative;
            transform: translateY(3px);

            &::after,
            &::before {
                content: '';
                background-color: $brew-section-bright;
                display: block;
                width: 2px;
                position: absolute;
                left: 50%;
                margin-left: -1px;
            }

            &::after {
                height: 5px;
                top: 6px;
            }

            &::before {
                height: 2px;
                top: 3px;
            }
        }

        &-text {
            max-width: 87px;
            margin-left: 6px;

            span {
                font-weight: 700;
            }
        }
    }

    &__actions {
        display: $cart-list-item__actions-display;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 80px;
        margin-left: calc(100% - 80px);

        @include mq($screen-m) {
            width: 100px;
            padding-top: 29px;
            padding-left: 27px;
            margin-left: auto;
            display: $cart-list-item__actions-display\@medium;
        }

        .button--icon {
            background: none;
        }
    }

    &__remove {
        font-size: 12px;
        line-height: 17px;
        color: $brew-text;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: color 200ms ease-in-out;

        &:before {
            content: '+';
            display: inline-block;
            transform: rotate(45deg) translateY(1px) translateX(2px);
            font-weight: 600;
            color: #A8A8A8;
            line-height: 1;
            font-size: 20px;
            transition: color 200ms ease-in-out;
        }

        &:hover {
            color: $brew-titles;

            &::before {
                color: $brew-titles;
            }
        }
    }

    &__attributes-list {
        .list__label,
        .list__value {
            font-size: 14px;
            line-height: 25px;
            color: $brew-text;
            padding: 0;

            a {
                color: $brew-text;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .list__label {
            margin-right: 3px;

            &::before {
                content: '• ';
            }
        }
    }

    &__gift-dropdown {
        @include visually-hidden();
    }

    &__alert {
        @include visually-hidden();
    }
    .cart-list-item__save-info-icon:hover {
        cursor: default;
    }
}
