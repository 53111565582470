$active-filters__grey-dark                  : $brew-text;
$active-filters__white                      : $white;
$active-filters__blue                       : $brew-text--accent;
$active-filters__blue-2                     : #367FBE;
$active-filters__blue-3                     : #3073AC;

$active-filters__font-size-big              : 24px;
$active-filters__font-size-middle           : 16px;
$active-filters__font-size-small            : 14px;
$active-filters__line-height-small          : 22px;
$active-filters__line-height-middle         : 24px;

$active-filters__padding                    : 15px 0 0;
$active-filters__padding\@large             : 0;
$active-filters__border                     : $border-base;
$active-filters__border-width               : 0 0 1px 0;
$active-filters__border-width\@large        : 0;

$active-filters__heading-font-family        : $font-family-base;
$active-filters__heading-font-weight        : 600;
$active-filters__headin-margin              : $spacer 0 $spacer--small;

$active-filters__remove-size                : 48px;
$active-filters__remove-margin\@large       : 0 10px 20px 0;
$active-filters__remove-margin              : 0 10px 10px 0;

$active-filters__item-padding               : 0 0 $spacer 0;
$active-filters__description-width          : calc(100% - #{$active-filters__remove-size});

$active-filters__icon-fill                  : $red;
$active-filters__icon-fill--hover           : $gray-light;

$active-filters__label-color                : $color-primary;
$active-filters__label-font-weight          : $font-weight-bold;
$active-filters__label-text-transform       : uppercase;
$active-filters__label-margin               : 0 $spacer--extra-small 0 0;

$active-filters__value-color                : $color-primary;
$active-filters__value-font-weight          : $font-weight-normal;
$active-filters__value-text-transform       : none;

$active-filters__clear-all-max-width\@medium: 300px;
