$discount-code__margin                    : 0;
$discount-code__button-width              : 100%;
$discount-code__button-padding            : $spacer--medium 0;
$discount-code__button-border             : $border-base;
$discount-code__button-border-width       : 0 0 1px 0;
$discount-code__button-background         : none;
$discount-code__button-background--hover  : none;
$discount-code__button-text-color         : $color-primary;
$discount-code__content                   : $spacer--large 0;
$discount-code__button-icon-transition    : $transition-base;
$discount-code__button-text-margin        : 0 $spacer 0 0;
$discount-code__button-text-font-size     : $font-size-large;
$discount-code__button-text-font-weight   : $font-weight-normal;
$discount-code__button-text-text-transform: uppercase;
$discount-code__input-margin              : 0 0 $spacer--large 0;

.opc-block-summary {
    .items-in-cart {
        & + .discount-code {
            display: none;
        }
    }

    .form-discount {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0 20px 20px;
        background-color: $brew-section-midtone;
        border-left: 1px solid $brew-border;
        border-right: 1px solid $brew-border;

        @include mq(false, $screen-m) {
            margin: 0 -15px;
            padding: 0 15px 20px;
        }

        .payment-option-inner {
            width: 59%;
            margin-right: 4%;
        }

        .actions-toolbar {
            width: 37%;

            .primary:not(button) {
                button {
                    @include mq($screen-m) {
                        max-width: 100%;
                    }
                }
            }

            .action-apply {
                line-height: 1.2;
                height: 40px;
                padding: 0 5px;
            }
        }

        .discount-code__input {
            margin-bottom: 0;
        }
    }
}

.discount-code {
    margin: $discount-code__margin;

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: $discount-code__button-width;
        padding: $discount-code__button-padding;
        border: $discount-code__button-border;
        border-width: $discount-code__button-border-width;
        background: $discount-code__button-background;
        cursor: pointer;

        &:hover {
            background: $discount-code__button-background--hover;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &__content {
        display: none;
        padding: $discount-code__content;
    }

    &__button-icon {
        transition: $discount-code__button-icon-transition;
    }

    &__button-text {
        margin: $discount-code__button-text-margin;
        font-size: $discount-code__button-text-font-size;
        font-weight: $discount-code__button-text-font-weight;
        color: $discount-code__button-text-color;
        text-transform: $discount-code__button-text-text-transform;
    }

    &._active {
        .discount-code__button-icon {
            transform: rotate(180deg);
        }

        .discount-code__content {
            display: block;
        }
    }

    &__input {
        margin: $discount-code__input-margin;
    }

    &__codes-list {
        display: flex;
        flex-flow: column nowrap;
    }

    &__codes-list-item {
        margin: 0 0 $spacer--medium 0;
        padding: 0 0 $spacer 0;
        border: $border-base;
        border-width: 0 0 1px 0;
    }

    &__messages {
        display: none;

        &._active {
            display: block;
        }
    }
}
