@import 'badge-variables';

.badge {
    display: inline-flex;
    color: $badge__color;
    font-size: $badge__font-size;
    font-weight: $badge__font-weight;
    line-height: $badge__line-height;
    text-align: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-right: 68px solid transparent;
    margin-right: auto;
    order: 10;

    &__text {
        transform: rotate(-45deg);
        margin-left: 55px;
        margin-top: -60px;
        max-height: 32px;
        font-weight: $font-weight-semibold;
        width: 60px;
    }

    &--price-tip {
        border-top: 68px solid $brew-accent;

        .badge__text {
            margin-left: 40px;
        }
    }

    &--top-seller {
        border-top: 68px solid $badge__background--purple;
        border-top-left-radius: 4px;

        .badge__text {
            margin-left: 40px;
        }
    }

    &--new {
        border-top: 68px solid $brew-green;

        .badge__text {
            margin-top: -55px;
        }
    }

    &--sale {
        border-top: 68px solid $badge__background;

        .badge__text {
            margin-top: -55px;
        }
    }

    &--made-in-germany,
    &--sustainable,
    &--individual {
        margin-right: 20px;
    }

    &--sustainable {
        display: none;

        &:hover > .badge__info-box {
            display: block;
        }
    }

    &--made-in-germany {
        &::before {
            content: url("../images/icons/made-in-germany_icon.svg");
        }
        margin-top: 22px;
        float: right;
        display: inline-block;
        &:hover > .badge__info-box {
            display: block;
        }
        .badge__info-box {
            margin-left: -60px;
        }
    }

    &--sustainable {
        margin-top: 17px;

        &::before {
            content: url("../images/icons/sustainable_icon.svg");
        }

        .badge__info-box-hover {
            margin-top: 16px;
            margin-left: -30px;
        }
    }
}

.badge--individual {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    z-index: 9;
    display: inline-block;
    float: right;
    margin-top: 22px;

    &::before {
        content: url("../images/icons/individual_icon.svg");
    }

    &:hover > .badge__info-box {
        display: block;
    }

    .badge__info-box {
        margin-left: -70px;
    }
}

.badge__info-box {
    position: absolute;
    background-color: $white;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    margin-top: 22px;
    padding: 15px 7px;
    border: 1px solid $brew-border-gray;
    box-shadow: 0 3px 16px #000029;
    display: none;
    width: max-content;

    &:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $white;
        border-width: 16px;
        top: -32px;
        right: 25px;
    }
}

.badge__info-box-hover {
    @extend .badge__info-box;
}

.product-item-details {
    .badges {
        top: 0;
        z-index: $z-index-low;
        width: 100%;
        align-items: center;
        flex-direction: row-reverse;
        padding-right: 10px;
        min-height: 68px;
    }

    &:hover {
        .badge--sustainable {
            display: inline-block;
            float: right;
        }
    }

    &.product-list-item {
        .badge--individual {
            margin-right: 10px;
        }
    }
}
