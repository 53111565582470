$password-strength__font-size                        : 12px;
$password-strength__color                            : $color-secondary;
$password-strength__font-weight                      : $font-weight-normal;

$password-strength__indicator-width                  : 128px;
$password-strength__indicator-margin                 : 0 0 0 $spacer;
$password-strength__indicator-padding                : 3px 0;
$password-strength__indicator-border                 : 1px solid $gray-light;
$password-strength__indicator-border-radius          : 32px;
$password-strength__indicator-color                  : $color-primary;
$password-strength__indicator-text-align             : center;
$password-strength__transition                       : $transition-base;

$password-strength__indicator-background--none       : $gray-light;
$password-strength__indicator-color--none            : $color-primary;
$password-strength__indicator-width--none            : 100%;
$password-strength__indicator-background--weak       : $red;
$password-strength__indicator-color--weak            : $white;
$password-strength__indicator-width--weak            : 100%;
$password-strength__indicator-background--medium     : $color-secondary;
$password-strength__indicator-color--medium          : $white;
$password-strength__indicator-width--medium          : 100%;
$password-strength__indicator-background--strong     : $color-info;
$password-strength__indicator-color--strong          : $white;
$password-strength__indicator-width--strong          : 100%;
$password-strength__indicator-background--very-strong: $color-success;
$password-strength__indicator-color--very-strong     : $color-primary;
$password-strength__indicator-width--very-strong     : 100%;
