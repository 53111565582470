$border-magazin: 1px solid $brew-border;

.magazine-overview {
    &-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 17px;
        max-width: $max-content-width;
        margin: auto;

        @include mq($screen-m) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .magazine-article {
        flex: 0 0 25%;
        max-width: 25%;
        display: flex;
        padding: 11px;
        color: $brew-text;
        align-items: normal;

        &:hover {
            text-decoration: none;

            .card {
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
                transition: box-shadow 125ms;
            }
        }
    }

    .card--magazine-article {
        width: 100%;
        word-wrap: break-word;
        background-clip: border-box;
        background-color: $bg-color-base;
        border: $border-magazin;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        min-width: 0;
        position: relative;

        .card-header--magazine-article {
            align-items: baseline;
            color: $brew-text;
            display: flex;
            flex-direction: row-reverse;
            font-size: $font-size-base;
            justify-content: space-between;
            padding: 10px 20px;
            text-align: right;
            border-radius: 3px 3px 0 0;
            border-bottom: $border-magazin;
            margin-bottom: 0;
            font-weight: $font-weight-semibold;
        }
        .card-img-top {
            border-radius: 0;
            height: 228px;
            flex-shrink: 0;
            width: 100%;
            vertical-align: middle;
        }
        .card--magazine-article {
            color: $brew-text;
        }
        .card-body--magazine-article {
            padding: 17px 19px 52px 16px;
            flex: 1 1 auto;
        }
        .card-title {
            color: $brew-text;
            margin-bottom: 12px;
            font-size: $font-size-medium;
            line-height: $font-size-extra-large;
            font-weight: $font-weight-semibold;
        }
        .card-excerpt {
            color: $brew-text;
        }
        .card-footer--magazine-article {
            background-color: $bg-color-base;
            border-top: $border-magazin;
            padding: 20px 14px 18px 16px;
            font-size: $font-size-medium;
            min-height: 8.5rem;
        }
        .article-categories {
            height: 60px;
            display: block;
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: $border-magazin;
        }
    }
}

.page-main.magazin {
    margin-top: 0 !important;
    background-color: $brew-section-midtone;

    .magazine-overview .breadcrumbs {
        display: block;
        margin: 0;
        height: auto;
        padding-left: 15px;

        @include mq($screen-l) {
            margin: 0 auto;
            padding-top: 35px;
            padding-left: 15px;
        }
    }
}

@include mq(false, $screen-xl) {
    .magazine-overview .magazine-article {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@include mq(false, $screen-s) {
    .magazine-overview .magazine-article {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@include mq($screen-xl) {
    .magazine-overview .magazine-article {
        &:nth-child(4n - 3) {
            padding-left: 0;
        }
        &:nth-child(4n) {
            padding-right: 0;
        }
    }
}
