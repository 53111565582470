.popup-request-quote.modal-slide {
    max-width: 530px;
    z-index: 100;
    left: auto;

    .modal-inner-wrap {
        padding: 22px 0;
        width: 100% !important;

        .modal-header {
            box-shadow: none;
            padding: 0 0 8px 0;
            border-bottom: 1px solid #E5E5E5;

            .action-close {
                display: none;
            }

            .modal-title {
                padding: 0 18px;
            }

            h1 {
                display: block !important;
            }
        }

        .modal-content {
            padding: 0 0 30px 0;
            flex-grow: 1;
            overflow-y: unset;
            max-height: 100%;

            .action-close {
                z-index: 105;
            }

            .modal-container {
                max-height: 100%;
                display: flex;
                flex-direction: column;

                .modal__content--block  {
                    flex-grow: 1;
                    overflow-y: scroll;
                    padding-top: 21px;

                    .negotiable-product-note {
                        padding: 0 18px;
                    }
                }

                .modal__content--actions {
                    padding: 18px 18px 0 18px;

                    button.button.action {
                        margin-bottom: 20px;
                        padding: 0 24px;
                        height: 60px;
                        font-size: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

#negotiable-product-list {
    overflow-y: auto;
}

.negotiable-product {
    &__item {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 18px;
    }

    &__item:nth-child(even) {
        background-color: #F3F3F3;
    }

    &__image {
        flex-basis: 85px;
        max-height: 85px;
        flex-shrink: 0;

        img {
            width: 50px;
            height: auto;
        }
    }

    &__image-link {
        display: flex;
        align-items: center;
    }

    &__data {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        padding: 0 0 0 16px;
    }

    &__info {
        margin: 0 0 0 8px;
        display: flex;
        justify-content: space-between;
        column-gap: 14px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: #333333;
        hyphens: auto;

        &:hover {
            text-decoration: none;
        }
    }

    &__name {
        margin-bottom: 8px !important;
        font-size: 14px !important;
        line-height: 19px;
        text-transform: none;
        font-weight: 600 !important;
        color: inherit;
    }

    &__sku {
        font-size: 14px;
        text-transform: none;
        color: inherit;
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .product-price {
            text-align: end;
        }

        .price {
            font-size: 16px;
            font-weight: 700;
            color: #666666;
            white-space: nowrap;
        }

        .zero-price {
            font-size: 13px;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    &__qty {
        display: flex;
        flex-direction: row;
    }

    &__qty-label,
    &__qty-value {
        font-size: 13px;
    }

    &__actions {
        display: flex;

        .negotiable-product__button {
            background-color: transparent;
            height: 40px;
            padding-left: 0;
            padding-right: 0;
            margin-right: -5px;
            min-width: 20px;

            &.button__icon {
                width: 25px;
                min-width: 25px;
            }

            &.delete {
                position: relative;
                width: 10px;
                height: 10px;
                padding-bottom: 1px;

                &::after,
                &::before {
                    content: '';
                    display: block;
                    background-color: #A8A8A8;
                    width: 14px;
                    height: 3px;
                    position: absolute;
                    transition: all 0.3s ease-in-out;
                }

                &::after {
                    transform: rotate(45deg);
                }

                &::before {
                    transform: rotate(-45deg);
                }

                &:hover {
                    &::after,
                    &::before {
                        background-color: #666666;
                    }
                }
            }
        }
    }

    &__empty-cart {
        background-color: #f3f3f3;
        text-align: center;
        padding: 3rem 1rem;
        color: #666;
        font-size: 16px;
    }
}
