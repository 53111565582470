.frc-captcha {
    width: 100%;
    max-width: 100% !important;
    margin: 0 0 2.5rem 0;
}
.frc-content button {
    padding: 8px 6px;
    border-radius: 4px;
    margin: 5px 0;
}
.hide_friendly_captcha {
    display: none;
}
