$product-grid-item__product-color                           : $brew-titles;
$product-grid-item__specia                                  : $brew-red-brewes;

$product-grid-item__width                                   : 100%;
$product-grid-item__padding                                 : 0;
$product-grid-item__border                                  : 1px solid $gray-light;
$product-grid-item__border-width                            : 0 0 1px 0;
$product-grid-item__transition                              : $transition-base;

$product-grid-item__badges-top-position                     : 0;
$product-grid-item__badges-left-position                    : 0;
$product-grid-item__badges-z-index                          : $z-index-low;

$product-grid-item__image-margin                            : 0 auto;
$product-grid-item__image-width                             : auto;
$product-grid-item__image-width--lazyload                   : 100%;
$product-grid-item__image-height--lazyload                  : auto;
$product-grid-item__image-height                            : auto;

$product-grid-item__details-padding                         : 0 15px;
$product-grid-item__details-margin                          : 6px 0 22px;
$product-grid-item__details-border                          : $border-base;
$product-grid-item__details-border-width                    : 0;

$product-grid-item__name-color                              : $brew-titles;
$product-grid-item__name-color-hover                        : $brew-titles;
$product-grid-item__name-font-size                          : $font-size-base;
$product-grid-item__name-font-weight                        : $font-weight-normal;
$product-grid-item__name-line-height                        : 19px;
$product-grid-item__name-text-decoration                    : none;
$product-grid-item__name-text-decoration-hover              : none;
$product-grid-item__name-margin                             : 0 0 10px;
$product-grid-item__name-height                             : 77px;

$product-grid-item__rating-margin-bottom                    : $spacer;
$product-grid-item__rating-height                           : $spacer--medium;
$product-grid-item__price-label-font-weight                 : $font-weight-semibold;
$product-grid-item__price-old-font-size                     : $font-size-small;
$product-grid-item__price-old-font-size\@medium             : $font-size-medium;
$product-grid-item__price-min-height                        : 45px;

$product-gird-item__primary-margin                          : 0 0 0 $spacer;

$product-grid-item__action-wishlist-background              : transparent;

$product-grid-item__IE-margin                               : 0 $spacer $spacer--medium $spacer;
$product-grid-item__IE-width                                : calc(50% - #{$spacer--medium} - 1px);
$product-grid-item__IE-width\@medium                        : calc(100% / 3 - #{$spacer--medium} - 1px);

// wishlist variant
$product-grid-item__action-margin--wishlist                 : $spacer 0 0 0;

$product-grid-item__comment-input-width--wishlist           : 100%;
$product-grid-item__comment-margin--wishlist                : 0 0 $spacer 0;
$product-grid-item__comment-margin--wishlist\@medium        : 0 0 $spacer--medium 0;

$product-grid-item__qty-input-width--wishlist               : 72px;
$product-grid-item__qty-input-width--wishlist\@xl           : 60px;
$product-grid-item__qty-button-size--wishlist               : 48px;
$product-grid-item__qty-input-border-width--wishlist        : 1px;
$product-grid-item__qty-input-border-width--wishlist\@xl    : 1px 0;
$product-grid-item__qty-width--wishlist                     : 100%;
$product-grid-item__qty-width--wishlist\@medium             : 80px;
$product-grid-item__qty-width--wishlist\@xl                 : 156px;

$product-grid-item__primary-action-width--wishlist          : 100%;
$product-grid-item__primary-action-margin--wishlist         : $spacer 0 0 0;
$product-grid-item__primary-action-margin--wishlist\@medium : $spacer--medium 0 0 0;
$product-grid-item__primary-action-button-padding--wishlist : 0 $spacer--semi-medium;

$product-grid-item__secondary-action-width--wishlist        : 100%;
$product-grid-item__secondary-action-width--wishlist\@medium: calc(100% - #{$product-grid-item__qty-width--wishlist\@medium});
$product-grid-item__secondary-action-width--wishlist\@xl    : calc(100% - #{$product-grid-item__qty-width--wishlist\@xl});

$product-grid-item__tocompare-background-color              : $gray-lighter;

// Slider item variant
$product-grid-item__image-wrapper-width--slider-item        : 100%;

// EE Version
$product-grid-item-ee__actions-width                        : 100%;
$product-grid-item-ee__tocart-width                         : 100%;
$product-grid-item-ee__tocart-height                        : 40px;
$product-grid-item-ee__requisition-margin                   : 0 $spacer 0 0;
$product-grid-item-ee__multiwishlist-width                  : 48px;
$product-grid-item-ee__multiwishlist-margin                 : 0 $spacer;
$product-grid-item-ee__dropdown-list-content-width          : 180px;
$product-grid-item-ee__dropdown-list-content-width\@medium  : 210px;
$product-grid-item-slider__dropdown-list-content-top        : auto;
$product-grid-item-slider__dropdown-list-content-bottom     : 100%;
$product-grid-item-ee__dropdown-list-label-width            : 48px;
$product-grid-item-ee__dropdown-list-label-height           : 48px;
