$auth__link-white               : $brew-section-bright;
$auth__link-dark                : $brew-section-dark;
$auth__link-grey                : $brew-border;
$auth__link-dark-grey           : $brew-titles;

$auth__link-width               : 100%;
$auth__link-width\@medium       : 440px;
$auth__link-height              : 72px;
$auth__link-border              : $border-base;
$auth__link-border-width        : $border-width-base 0;
$auth__link-color               : $color-secondary;
$auth__link-color--active       : $color-primary;
$auth__link-background--active  : $gray-light;
$auth__link-font-size           : $font-size-large;
$auth__link-font-weight         : $font-weight-normal;
$auth__link-font-weight--active : $font-weight-bold;
$auth__link-text-decoration     : none;
$auth__link-text-transform      : uppercase;

$auth__icon-transform--active   : rotate(180deg);

$auth__heading-margin           : $spacer--extra-large 0 $spacer--semi-large;
$auth__heading-margin\@medium   : 120px 0 72px;

$auth__container-max-width      : 880px;
$auth__container-margin         : 0 auto;
