// Colors
$white                       : #fff;
$black                       : #000;
$red                         : #E3000F;
$orange                      : #fab216;
$yellow                      : #ffd500;
$green                       : #13b200;
$blue                        : #0075e4;
$bright-sky-blue             : #00b8ff;
$teal                        : #0194ab;
$pink                        : #ff5b77;
$purple                      : #613d7c;
$seafoam-blue                : #77ccb9;

// Grayscale colors
$gray-darkest                : #1a1b1d;
$gray-darker                 : #393243;
$gray-dark                   : #6e7577;
$gray                        : #cccccc;
$gray-light                  : #eaeaea;
$gray-lighter                : #fbfbfb;
$gray-lightest               : #f7f7f9;

// Semantic color scheme
$color-primary               : #333333;
$color-secondary             : #666666;
$color-success               : $green;
$color-info                  : $blue;
$color-warning               : $red;
$color-error                 : $red;
$color-inverse               : $gray-dark;
$color-focus                 : $bright-sky-blue;

// Background
$bg-color-base               : $white;
$bg-color-secondary          : $gray-lighter;
$bg-color-dark               : $gray-darkest;

// Border
$border-color-base           : #e5e5e5;
$border-width-base           : 1px;
$border-style-base           : solid;
$border-color-secondary      : $gray-dark;
$border-width-secondary      : 1px;
$border-style-secondary      : solid;

$border-base                 : $border-width-base $border-style-base $border-color-base;
$border-secondary            : $border-width-secondary $border-style-secondary $border-color-secondary;

// Outline
$outline-base                : none;

// Focus
$border-focus                : 2px solid $color-focus;
$border-focus-dark           : 2px solid $color-primary;

// Focus inline
$color-focus-inline          : $color-primary;
$color-focus-inline-dark     : $color-primary;
$bg-focus-inline             : $color-focus;
$bg-focus-inline-dark        : $color-primary;
$outline-focus-inline        : 0;
$text-decoration-focus-inline: underline;
$focus-outline-offset        : -1px;
$focus-input-border          : $border-focus-dark;

// Spacers
$spacer--extra-small         : 4px;
$spacer                      : 8px;
$spacer--small               : 12px;
$spacer--medium              : 16px;
$spacer--semi-medium         : 24px;
$spacer--large               : 32px;
$spacer--semi-large          : 40px;
$spacer--extra-large         : 44px;

// Typography
$font-family-sans-serif      : 'Open Sans', sans-serif;
$font-family-lato            : 'Lato', sans-serif;
$font-family-monospace       : Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$font-family-base            : $font-family-sans-serif;
$font-family-secondary       : $font-family-lato;

$font-color-base             : $color-primary;
$font-weight-normal          : 400;
$font-weight-medium          : 500;
$font-weight-semibold        : 600;
$font-weight-bold            : 700;
$font-weight-base            : $font-weight-normal;
$font-line-height            : 1.6;

$font-size-super-extra-large : 32px;
$font-size-extra-large       : 20px;
$font-size-large             : 18px;
$font-size-medium            : 16px;
$font-size-base              : 14px;
$font-size-small             : 12px;
$font-size-extra-small       : 10px;

// Z-index
$z-index-lowest              : -1;
$z-index-initial             : auto;
$z-index-low                 : 1;
$z-index-normal              : 10;
$z-index-high                : 50;
$z-index-highest             : 99; // reserved for modals - check _modal-variables.scss for more

// Layout
$max-content-width           : 1450px;

// Layout - breakpoints
$screen-xs                   : 320px;
$screen-s                    : 480px;
$screen-m                    : 768px;
$screen-l                    : 1024px;
$screen-xl                   : 1200px;
$screen-xxl                  : 1600px;

// shadow
$shadow                      : 0 2px 6px 0 rgba(0, 0, 0, 0.1);
$darken-layout-hover         : rgba($black, 0.2);

// animations
$animation-position-top      : -300px;
$animation-position-right    : -300px;
$animation-position-left     : -300px;

// Components
$border-radius               : 0;
$form-elements-radius        : 3px;
$form-elements-radius--small : 0;
$form-elements-border-color  : #e5e5e5;
$form-element-border-width   : 1px;
$form-element-border-style   : solid;
$form-elements-border        : $form-element-border-width $form-element-border-style $form-elements-border-color;
$form-elements-border-focus  : 1px solid $color-primary;
$form-input-box-shadow-focus : none;
$transition-base             : all 0.3s ease-in-out;
$transition-fade             : opacity 0.25s linear;

// Icon
$icon-padding: 0;
$icon-size: 24px;
