$filters__white                      : $white;
$filters__grey                       : $brew-border;
$filters__border-grey                : $brew-border-dark;
$filters__grey-light                 : $brew-text--light-grey2;
$filters__grey-dark                  : $brew-text;
$filters__blue                       : $brew-text--accent;
$filters__grey-light-2               : $brew-section-midtone;

$filters__item-margin                : 0;
$filters__item-margin\@large         : 0 0 25px 0;

$filters__content-width\@medium      : 100%;
$filters__content-width\@large       : 100%;
$filters__content-margin\@medium     : 0;
$filters__content-margin\@large      : 0;

$filters__container-padding\@large   : 0;

$filters__trigger-bg-color           : transparent;
$filters__trigger-padding            : $spacer--small $spacer--medium $spacer--small 0;
$filters__trigger-padding\@medium    : $spacer--small $spacer--medium;
$filters__trigger-text-padding       : 0;
$filters__trigger-text-color         : $color-primary;
$filters__trigger-icon-size--open    : 24px;
$filters__trigger-icon-fill--open    : $color-secondary;
$filters__trigger-border             : $border-base;
$filters__trigger-border-width       : 0 0 1px 0;
$filters__trigger-text-transform     : uppercase;
$filters__trigger-font-weight        : $font-weight-bold;

$filters__heading-font-family        : $font-family-base;
$filters__heading-font-size          : 16px;
$filters__heading-line-height        : 29px;
$filters__heading-font-weight        : 600;
$filters__heading-margin             : 0;
$filters__heading-padding\@large     : 0;
$filters__heading-padding\@xl        : $spacer $spacer--medium $spacer;

$filters__modal-top-padding          : 0 0 5px 0;
$filters__modal-middle-padding       : 0;
$filters__modal-middle-padding\@large: 0;
$filters__modal-top-padding\@large   : $filters__modal-top-padding;
$filters__border-width\@large        : 0;

$filters__sticky-position            : 140px;
$filters__label-padding              : 5px 37px 5px 10px;
$filters__content-padding            : 16px 9px 10px;

$filters__middle-font-size           : 15px;
$filters__middle-line-height         : 21px;
$filters__middle-line-height-2       : 22px;
