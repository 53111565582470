@import "cart-variables";

.cart-grid {
    @include mq($screen-m) {
        margin: 0 -8px;
    }

    @include mq($screen-l) {
        margin: 0;
    }

    @include mq($screen-xl) {
        padding-right: 15px;
    }

    &__header {
        @include mq($screen-m) {
            display: flex;
            border-bottom: $cart-grid__header-border-bottom;
            padding: 0 34px 0 26px;
        }

        @include mq($screen-l) {
            position: sticky;
            top: 160px;
            background: $cart-grid__header-background-color;
            z-index: 1;

            &::before {
                content: '';
                display: block;
                background: $cart-grid__header-background-color;
                height: 40px;
                width: 100%;
                position: absolute;
                top: -40px;
                left: 0;
            }
        }
    }

    &__header-label {
        display: none;
        padding: $cart-grid__header-label-padding;
        color: $cart-grid__header-color;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;

        @include mq($screen-m) {
            display: flex;
            font-size: 14px;
            line-height: 1;
        }
    }

    &__header-items {
        display: flex;
        flex-basis: calc(100% - 310px);
        margin: 0 -10px;
        padding: 14px 10px 11px;
        border-bottom: $border-base;

        @include mq($screen-m) {
            margin: 0;
            padding: 14px 0 11px;
            border-bottom: none;
        }

        @include mq($screen-l) {
            padding: 14px 0 11px;
        }
    }

    &__header-price,
    &__header-subtotal {
        flex-basis: 100px;
    }

    &__header-subtotal {
        @include mq($screen-m) {
            padding-left: 27px;
        }
    }

    &__header-qty {
        flex-basis: 110px;
    }

    &__button {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__footer {
        @include mq($screen-m) {
            display: flex;
            justify-content: $cart-grid__footer-justify-content\@medium;
            flex-direction: $cart-grid__footer-flex-direction\@medium;
        }
    }
}
