@import 'quantity-update-variables';

.quantity-update {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-width: $quantity-update__max-width;
    width: $quantity-update__width;
    transition: $quantity-update__transition;

    @include mq($screen-m) {
        max-width: $quantity-update__max-width\@medium;
    }

    &--active {
        .quantity-update__input {
            border: $quantity-update__border--active;
        }
    }

    &--no-buttons-m {
        @include mq($screen-m) {
            .quantity-update {
                &__button {
                    display: none;
                }

                &__input {
                    border-width: $border-width-base;
                }
            }
        }

        @include mq($screen-xxl) {
            .quantity-update {
                &__button {
                    display: flex;
                }

                &__input {
                    border-width: $quantity-update__input-border-width;
                }
            }
        }
    }

    &__input {
        flex-grow: 1;
        order: 2;
        border-style: $quantity-update__input-border-style;
        border-color: $quantity-update__input-border-color;
        border-width: $quantity-update__input-border-width;
        border-radius: $quantity-update__input-border-radius;
        width: $quantity-update__input-width;
        height: $quantity-update__input-height;
        color: $quantity-update__input-color;
        font-size: $quantity-update__input-font-size;
        text-align: center;
        appearance: none;

        @include mq($screen-m) {
            flex-grow: initial;
        }

        &.focus-visible {
            outline: none;
        }

        &.mage-error {
            border-width: $quantity-update__input-border-width--error;
        }
    }

    & &__button {
        width: $quantity-update__button-width;
        height: $quantity-update__button-height;
        margin: $quantity-update__button-margin;
        border-style: $quantity-update__button-border-style;
        border-color: $quantity-update__button-border-color;
        border-width: $quantity-update__button-border-width;
        border-radius: 0;
        padding: 0;
        background: none;

        &::after,
        &::before {
            content: '';
            position: absolute;
            background: $quantity-update__background;
        }

        &::after {
            width: 8px;
            height: 2px;
            left: 11px;
            top: 18px;
        }

        &:focus {
            border-color: $quantity-update__button-border-color;
        }

        &--plus {
            border-left: 0;
            order: 3;
            border-radius: $quantity-update__button-radius-plus;
            background-color: $white;

            &::before {
                width: 2px;
                height: 8px;
                left: 14px;
                top: 15px;
            }
        }

        &--minus {
            border-right: 0;
            order: 1;
            border-radius: $quantity-update__button-radius-minus;
            background-color: $white;
        }
    }

    &__alert {
        @include visually-hidden();
    }

    // Disable spin buttons in number input type
    input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
    }

    input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }

    div.mage-error {
        bottom: $quantity-update__mage-error-bottom;
        width: $quantity-update__mage-error-width;
        position: absolute;
    }

    &__tooltip {
        position: absolute;
        display: block;
        background: $quantity-update__background-tooltip;
        border-radius: 3px;
        color: $quantity-update__color-tooltip;
        white-space: nowrap;
        font-size: $quantity-update__fontsize-tooltip;
        padding: $quantity-update__tooltip-padding;
        top: $quantity-update__tooltip-top;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;

        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $quantity-update__background-tooltip;
        }
    }

    &:hover {
        .quantity-update__tooltip {
            opacity: 1;
            z-index: 1;
        }
    }
}

.grouped-product-list {
    .quantity-update__input {
        max-width: 30px;
    }
    .quantity-update {
        @include mq($screen-m) {
            max-width: 82px;
        }
    }
}
