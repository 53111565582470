@import 'filters-variables';

.filters {
    display: flex;
    flex-direction: column;

    &__item {
        margin: $filters__item-margin;
        border-bottom: 1px solid $brew-border-dark;

        @include mq($screen-l) {
            margin: $filters__item-margin\@large;
            padding-bottom: 8px;
        }
    }

    &__container {
        .modal & {
            @include mq($screen-l) {
                height: auto;
                animation: none;
                padding: $filters__container-padding\@large;
                max-height: 100%;
            }
        }
    }

    &__modal {
        @include mq($screen-l) {
            display: block;
            position: static;
            visibility: visible;
            opacity: 1;
            height: auto;
            padding: 0;
            background-color: $white;
            z-index: 70;
        }
    }

    &__content {
        position: relative;

        @include mq($screen-m) {
            width: $filters__content-width\@medium;
            margin: $filters__content-margin\@medium;
        }

        @include mq($screen-l) {
            width: $filters__content-width\@large;
            margin: $filters__content-margin\@large;
        }

        .range-filter {
            padding: 16px 5px 5px 5px;

            @include mq($screen-l) {
                padding: 16px 5px 5px 5px;
            }

            &__input {
                width: 95px;

                input {
                    -moz-appearance: textfield;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }

            &__suffix {
                padding: 0 8px 0 0;
            }

            &__button {
                margin-bottom: 0;
            }
        }
    }

    &__trigger {
        width: 100%;
        justify-content: space-between;
        padding: $filters__trigger-padding;
        border: $filters__trigger-border;
        border-width: $filters__trigger-border-width;
        background-color: $filters__trigger-bg-color;

        @include mq($screen-m) {
            padding: $filters__trigger-padding\@medium;
        }

        @include mq($screen-l) {
            display: none;
        }

        > * {
            pointer-events: none;
        }

        .icon {
            width: $filters__trigger-icon-size--open;
            height: $filters__trigger-icon-size--open;
            fill: $filters__trigger-icon-fill--open;
        }

        .button__text {
            color: $filters__trigger-text-color;
            padding: $filters__trigger-text-padding;
            text-transform: $filters__trigger-text-transform;
            font-weight: $filters__trigger-font-weight;
        }
    }

    &__heading {
        padding: 0;
    }

    &__heading,
    &__trigger {
        font-family: $filters__heading-font-family;
        font-size: $filters__heading-font-size;
        line-height: $filters__heading-line-height;
        font-weight: $filters__heading-font-weight;
        margin: $filters__heading-margin;

        @include mq($screen-l) {
            padding: $filters__heading-padding\@large;
        }
    }

    &__content-top {
        padding: $filters__modal-top-padding;

        @include mq($screen-l) {
            padding: $filters__modal-top-padding\@large;
            border-width: $filters__border-width\@large;
        }
    }

    &__content-middle {
        padding: $filters__modal-middle-padding;
        overflow: visible;

        @include mq($screen-l) {
            padding: $filters__modal-middle-padding\@large;
            overflow: initial;
        }
    }

    &__close-button {
        @include mq($screen-l) {
            display: none;
        }
    }

    &__dropdown {
        ul {
            display: flex;
            flex-wrap: wrap;

            li  {
                width: 100%;
            }

            .dropdown-list {
                &__item {
                    width: auto;
                    border: none;
                    margin-right: 10px;
                    margin-bottom: 10px;

                    @include mq($screen-m) {
                        margin-bottom: 20px;
                    }

                    &.show-more-filters {
                        margin-right: 0;

                        button {
                            position: relative;
                            background-color: $filters__white;
                            transition: $filter__transition;

                            &:hover {
                                background-color: $filters__grey;
                            }

                            &::after,
                            &::before {
                                content: '';
                                display: block;
                                background-color: $filters__blue;
                                position: absolute;
                            }

                            &::after {
                                width: 12px;
                                height: 2px;
                                right: 10px;
                                top: 19px;
                            }

                            &::before {
                                width: 2px;
                                height: 12px;
                                right: 15px;
                                top: 14px;
                            }
                        }
                    }

                    .range-filter {
                        &__input {
                            max-width: 75px;
                            input {
                                text-align: center;
                                padding: 0 8px;

                                &:disabled {
                                    background-color: $filters__white;
                                    border: none;
                                    cursor: text;
                                    padding: 0;
                                }
                            }
                        }
                        &__suffix {
                            padding: 8px;
                        }
                    }
                    &.filter--range {
                        &--type-left {
                            .range-filter__slider {
                                padding-right: 0;
                            }
                            .noUi-handle-upper {
                                display: none;
                            }
                        }
                        &--type-right {
                            .range-filter__slider {
                                padding-left: 0;
                            }
                            .noUi-handle-lower {
                                display: none;
                            }
                        }
                    }
                }

                &__label {
                    background: $filters__grey-light-2;
                    border: 1px solid $filters__grey;
                    border-radius: 4px;
                    padding: $filters__label-padding;

                    span {
                        font-size: $filters__heading-font-size;
                        line-height: 27px;
                        color: $filters__grey-dark;
                        font-weight: 400;
                    }

                    &:hover > .dropdown-list__icon,
                    &[aria-expanded="true"] > .dropdown-list__icon {
                        fill: $filters__grey-dark;
                    }
                }

                &__icon {
                    right: 6px;
                }

                &__content {
                    background: $filters__white;
                    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3);
                    border: 1px solid $filters__grey;
                    border-radius: 3px;
                    margin-top: 3px;
                    padding: $filters__content-padding;
                    position: absolute;
                    left: 0;
                    top: 40px;
                    min-width: 170px;
                    z-index: 2;
                    overflow: unset;
                    max-height: none;

                    .scroll-wrapper {
                        overflow-y: auto;
                        max-height: 250px;
                        margin-bottom: 15px;
                        padding: 2px 0;

                        &__individual{
                            margin-bottom: 0;
                            padding: 0;
                            overflow-y: auto;
                            max-height: 250px;
                        }
                    }

                    .list.items {
                        li {
                            margin-bottom: 15px;

                            .checkbox {
                                &__icon {
                                    width: 13px;
                                    height: 13px;
                                }

                                &__label {
                                    padding: 0 0 0 23px;
                                    font-size: $filters__middle-font-size;
                                    line-height: $filters__middle-line-height;
                                    color: $filters__grey-dark;
                                    width: max-content;
                                    margin-right: 20px;
                                    max-width: 325px;

                                    &::after {
                                        width: 9px;
                                        height: 9px;
                                        border-radius: 0;
                                        background-color: $filters__grey-dark;
                                    }
                                }

                                &--link-checked {
                                    .checkbox__label {
                                        font-weight: 400;
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .filter-button {
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.customizable-filter-checkbox {
    margin-bottom: 20px;
    margin-right: 30px;
}

.checkbox-styled {
    display: inline-block;

    input {
        display: none;

        & ~ label {
            position: relative;
            display: flex;
            align-items: center;
            font-size: $filters__heading-font-size;
            line-height: $filters__middle-line-height-2;
            color: $filters__grey-dark;
            margin: 0;

            .checkbox-swatcher {
                position: relative;

                &::after,
                &::before {
                    content: '';
                    display: block;
                    cursor: pointer;
                }

                &::after {
                    width: 20px;
                    height: 20px;
                    background: $filters__white;
                    border: 1px solid $filters__grey-light;
                    border-radius: 50%;
                    position: absolute;
                    top: -4px;
                    left: 3px;
                    transition: $filter__transition;
                }

                &::before {
                    width: 40px;
                    height: 12px;
                    background: $filters__grey;
                    border-radius: 6px;
                    position: relative;
                    margin-right: 13px;
                }
            }
        }

        &:checked ~ label {
            .checkbox-swatcher {
                &::after {
                    background-color: $filters__blue;
                    border: 1px solid $filters__blue;
                    left: 17px;
                }
            }
        }
    }
}

.filter--category {
    border: none;
}
