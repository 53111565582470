@import 'magazin-widget-variables';

.magazin-widget {
    flex-wrap: wrap;
    padding: $magazin-widget__padding;
    max-width: $max-content-width - 10;
    margin: 0 auto;

    @include mq($screen-l) {
        display: flex;
        padding: $magazin-widget__padding\@large;
    }

    a {
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }

    &__title {
        margin-bottom: $magazin-widget__title-indent;
        flex: 0 0 100%;
        font-size: $magazin-widget__title-font-size;

        @include mq($screen-l) {
            font-size: $magazin-widget__title-font-size\@large;
        }
    }

    &__main {
        list-style-type: none;
        padding: 0;
        margin: 0;

        @include mq($screen-l) {
            flex: 0 1 $magazin-widget__main-size;
            padding: $magazin-widget__main-padding;
            border-right: $magazin-widget__main-border;
        }

        @include mq(false, $screen-l) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            &:not(:last-child) {
                margin-bottom: $magazin-widget__main-item-margin;

                @include mq($screen-l) {
                    margin-bottom: $magazin-widget__main-item-margin\@large;
                }
            }

            @include mq(false, $screen-l) {
                width: calc(50% - 10px);
            }

            @include mq(false, $screen-s) {
                width: calc(100%);
            }
        }

        &-link {
            display: flex;
            flex-direction: column;

            @include mq($screen-l) {
                flex-direction: row;
                padding: $magazin-widget__main-item-padding;

                &:hover {
                    box-shadow: $magazin-widget__hover-shadow;
                }
            }
        }

        &-image {
            margin-bottom: 17px;

            @include mq($screen-l) {
                flex: 0 0 $magazin-widget__main-item-image-size;
                margin: 0 46px 0 0;
            }

            @include mq(false, $screen-l) {
                display: block;
                width: 100%;

                img {
                    width: 100%;
                }
            }

            img {
                object-fit: contain;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 24px;
            color: $brew-text;

            @include mq($screen-l) {
                line-height: 18px;
            }
        }

        &-title {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 17px;
            line-height: 30px;
            color: $brew-titles;

            @include mq($screen-l) {
                line-height: 17px;
                margin-bottom: 8px;
            }
        }

        &-author {
            margin-bottom: 25px;

            @include mq($screen-l) {
                margin-bottom: 22px;
            }
        }

        &-description {
            overflow: hidden;
            font-size: 16px;
            line-height: 28px;
            position: relative;

            @include mq($screen-l) {
                line-height: 25px; 
            }
        }
    }

    &__sidebar {
        flex: 0 1 $magazin-widget__sidebar-size;
        padding: 48px 0 0 0;
        list-style-type: none;
        margin: 0;

        @include mq($screen-l) {
            padding: 0 0 0 $magazin-widget__sidebar-padding;
        }
    }

    &__item {
        &:nth-last-child(2) {
            margin-bottom: 29px;
        }

        &:last-child {
            @include mq($screen-l) {
                padding-left: 28px;
            }
        }

        &-link {
            display: flex;
            padding: $magazin-widget__item-padding;

            @include mq($screen-l) {
                &:hover {
                    box-shadow: $magazin-widget__hover-shadow;
                }
            }
        }

        &-left {
            position: relative;
            display: inline-flex;
            width: 56px;
            height: 60px;
            margin-right: 13px;
            flex: 0 0 56px;

            .icon {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: 21px;
            color: #999999;
            line-height: 19px;

            &-row {
                &:first-child {
                    font-size: 20px;
                    font-weight: 300;
                }

                &:last-child {
                    font-size: 10px;
                }
            }
        }

        &-right {
            color: $brew-text;
            font-weight: 600;
        }

        &-title {
            display: inline-block;
            width: 100%;
            font-size: 17px;
            line-height: 19px;
            margin-bottom: 6px;
            color: $brew-titles;
        }

        &-author {
            font-size: 13px;
            line-height: 19px;
            display: block;
        }

        .magazin-widget__show-all {
            text-transform: uppercase;

            &:hover {
                text-decoration: none;
                color: $brew-text;
            }

            @include mq($screen-l) {
                &:hover {
                    padding-left: 20px;
                }
            }
        }
    }
}
