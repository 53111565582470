$filter__padding\@xl                        : 0 $spacer--medium;
$filter__transition                         : $transition-base;
$filter__heading-font-family                : $font-family-base;
$filter__heading-font-size                  : $font-size-large;
$filter__heading-text-transform             : none;
$filter__heading-margin                     : 0;
$filter__content-padding                    : 0 0 $spacer--medium;
$filter__content-padding\@medium            : 0 0 $spacer--medium $spacer--medium;
$filter__content-padding\@large             : 0 0 $spacer--medium 0;
$filter__content-overflow-y                 : auto;
$filter__label-wrapper-padding\@medium      : 0;
$filter__label-wrapper-padding\@large       : 0;

// checkbox
$filter__content-max-height--checkbox       : 295px;
$filter__label-font-size--checkbox          : $font-size-medium;
$filter__margin--checkbox-link              : 0 0 0 $spacer--extra-small;
$filter__margin--checkbox-link\@medium      : 0;
$filter__label-font-wieght-checked--checkbox: $font-weight-bold;

// Swatch
$filter__swatch-size\@large                 : 25%;
$filter__swatch-margin                      : 0 $spacer $spacer--medium 0;
$filter__swatch-margin\@large               : 0 0 $spacer--medium 0;
$filter__swatch-option-selected-border-color: $color-primary;
$filter__swatch-option-size                 : 48px;
$filter__swatch-option-background           : $white;
$filter__swatch-option-color                : $color-primary;
$filter__swatch-option-border               : $border-base;
$filter__swatch-option-border-color-hover   : $color-primary;
$filter__swatch-option-border-color--active : $color-primary;

// Color
$filter__color-padding                      : 0;
$filter__color-size\@large                  : auto;
$filter__color-option-width                 : 48px;
$filter__color-option-height                : auto;
$filter__color-option-margin                : 0 $spacer $spacer 0;
$filter__color-option-border                : $border-base;
$filter__color-option-after-top             : 0;
$filter__color-option-after-left            : 0;
$filter__color-option-after-size            : 48px;
$filter__color-option-after-border-radius   : 0;
$filter__color-option-after-border-color    : $color-primary;
$filter__color-sample-size                  : 32px;
$filter__color-sample-margin                : $spacer;
$filter__color-sample-border                : $border-base;
$filter__color-sample-border-radius         : 0;
$filter__color-label-font-size              : $font-size-small;
$filter__color-label-color                  : $gray-darkest;

// category variant
$filter__padding--category\@large           : 0;
$filter__border--category\@large            : $border-base;
$filter__border-width--category\@large      : 0 0 $border-width-base 0;
