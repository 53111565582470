@import 'footer-variables';

.footer {
    flex-shrink: $footer__flex-shrink;
    border: $footer__border;
    border-width: $footer__border-width;
    background-color: $footer__background-color;
    padding: $footer__padding;

    @include mq($screen-l) {
        padding: $footer__padding\@large;
    }

    .brew-footer {

        @include mq($screen-xxl) {
            padding: $footer__brew-padding\@large;
        }
    }

    a, .link {
        color: $brew-text--light;
        font-weight: normal;

        &:hover {
            color: $brew-gray--darken;
        }
    }

    &-top {
        padding-bottom: $footer__top-padding-bottom;
        margin-bottom: $footer__top-margin-bottom;

        @include mq($screen-l) {
            margin-bottom: $footer__top-margin-bottom\@large;
            padding-bottom: $footer__top-padding-bottom\@large;
            border-bottom: $footer__border;
        }

        .list--grid {
            .list__item {
                order: 2;

                @include mq($screen-l) {
                    width: 20%;
                }

                @include mq($max-screen: $screen-l - 1) {
                    &:first-child {
                        order: 1;
                    }

                    &:nth-child(2) {
                        order: 2;
                    }

                    &:nth-child(3) {
                        order: 3;
                    }

                    &:nth-child(4) {
                        order: 4;
                    }

                    &:nth-child(5) {
                        order: 5;
                    }

                    &:nth-child(6) {
                        order: 6;
                    }

                    &:nth-child(7) {
                        order: 7;
                    }

                    &:nth-child(8) {
                        order: 8;
                    }

                    &:nth-child(9) {
                        order: 9;
                    }

                    &:nth-child(10) {
                        order: 10;
                    }
                }
            }

            &.mage-accordion-disabled {
                .list__item-content {
                    display: list-item;
                }
            }
        }


        .item-inner {
            a, .link {
                line-height: 1.5 !important;
                padding-top: 0.65rem;
                padding-bottom: 0.65rem;
            }
        }

        .list__title {
            order: 1;
            border-bottom: $footer__border;
            cursor: pointer;
            transition: border-bottom-color 0ms ease-in-out 100ms;

            &.active {
                border-bottom-color: transparent;
                transition: none;

                .list__trigger-icon {
                    transform: rotate(0);
                }
            }

            @include mq($screen-l) {
                width: 20%;
                margin-bottom: 24px;
                border-bottom: none;
                cursor: default;

                &:not(:last-child) {
                    padding-right: 15px;
                }
            }

            @include mq($max-screen: $screen-l - 1) {
                &:first-child {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }

                &:nth-child(4) {
                    order: 4;
                }

                &:nth-child(5) {
                    order: 5;
                }

                &:nth-child(6) {
                    order: 6;
                }

                &:nth-child(7) {
                    order: 7;
                }

                &:nth-child(8) {
                    order: 8;
                }

                &:nth-child(9) {
                    order: 9;
                }

                &:nth-child(10) {
                    order: 10;
                }
            }
        }

        .list__trigger {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 12px 18px 11px;

            @include mq($screen-l) {
                display: block;
                padding: 0;
            }

            &-icon {
                width: 24px;
                height: 24px;
                fill: $white;
                transform: rotate(-90deg);
                transition: transform 200ms ease-in-out;

                @include mq($screen-l) {
                    display: none;
                }
            }

            &.active {
                .list__trigger-icon {
                    transform: rotate(0);
                }
            }
        }

        .list__item-content {
            display: none;
            padding: $footer__item-content-padding;
            border-bottom: $footer__border;

            @include mq($screen-l) {
                display: list-item;
                border-bottom: none;
                padding: $footer__item-content-padding\@large;
                margin: 0;
                background-color: transparent;
            }
        }
    }

    &-bottom {
        padding-bottom: 22px;
        border-bottom: $footer__border;
        margin-bottom: 35px;
        text-align: center;

        @include mq($screen-l) {
            margin-bottom: 23px;
        }

        .list--grid {
            .list__item {
                padding-bottom: 0;
                margin-bottom: 44px;

                @include mq($screen-l) {
                    width: 25%;
                    margin-bottom: 0;
                }
            }

            .footer-social {
                margin-bottom: 12px;

                @include mq($screen-l) {
                    margin-bottom: 0;
                }
            }
        }

        .list__title {
            margin-bottom: 30px;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &-shipping {
        border: none;
        order: 3;

        @include mq($screen-l) {
            order: 1;
        }

        .item-inner {
            a, .link {
                width: 106px;
            }
        }
    }

    &-payment {
        order: 2;

        @include mq($screen-l) {
            order: 1;
        }

        .brew-footer__inner-link {
            max-height: 21px;

            img {
                max-height: inherit;
            }
        }

        .list {
            align-items: flex-end;
        }

        .item-inner {
            text-align: left;

            &:nth-child(2n) {
                margin: 0 35px 0 15px;
            }
        }

        .item-subtitle {
            color: $brew-text--light;
            margin-bottom: 17px;
            font-size: 13px;
        }

        .mastercard {
            transform: translateY(7px);

            .brew-footer__inner-link {
                max-height: 30px;
            }
        }
    }

    &-social {
        order: 4;

        @include mq($screen-l) {
            order: 1;
        }

        .brew-footer__inner-link {
            max-height: 40px;

            img {
                max-height: inherit;
            }
        }

        .item-inner:not(:last-child) {
            margin-right: 20px;
        }
    }

    &-safe {
        order: 1;

        .list {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            @include mq($screen-l) {
                display: grid;
                grid-template-columns: repeat(2, 58px);
                grid-gap: 12px 35px;
            }
        }

        .item-inner:not(:last-child) {
            margin-right: 20px;

            @include mq($screen-l) {
                margin-right: 0;
            }
        }

        .image {
            width: 60px;

            @include mq($screen-l) {
                width: auto;
            }
        }
    }

    &-description {
        letter-spacing: 0;
        margin-bottom: 18px;

        @include mq($screen-l) {
            margin-bottom: 13px;
        }

        p {
            font-size: 13px;
            color: $brew-text;
            margin-bottom: 0;
            line-height: 17px;

            @include mq($screen-l) {
                font-size: 14px;
                line-height: 18px;

            }
        }
    }

    .list {
        &--grid {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            @include mq($screen-l) {
                flex-direction: row;
            }
        }

        &__title {
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0;
            color: $brew-text--light;
            font-weight: 600;

            @include mq($screen-l) {
                font-size: 22px;
            }
        }
    }

    .item-inner {
        &:not(:last-child) {
            margin-bottom: 10px;

            @include mq($screen-l) {
                margin-bottom: 0;
            }
        }

        a, .link {
            font-size: 16px;
            line-height: 1;
            letter-spacing: 0;

            @include mq($screen-l) {
                line-height: 40px;
            }
        }
    }

    &__handler {
        margin: 0;

        @include mq($screen-l) {
            flex-direction: row;
            margin: 0 auto;
        }
    }

    &__bottom-bar {
        color: $footer__bottom-bar-color;
        background-color: $footer__bottom-bar-background;
    }

    &__bottom-bar-handler {
        display: $footer__bottom-display;
        align-items: $footer__bottom-align;
        flex-direction: $footer__bottom-flex-direction;
        min-height: $footer__bottom-min-height;

        @include mq($screen-m) {
            justify-content: $footer__bottom-justify-content\@medium;
            flex-direction: $footer__bottom-flex-direction\@medium;
            flex-wrap: $footer__bottom-flex-wrap\@medium;
        }

        @include mq($screen-l) {
            padding: $footer__bottom-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $footer__bottom-padding\@xl;
        }
    }

    .copyright {
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        color: $brew-text--light;
        max-width: 210px;
        margin: 0 auto;

        @include mq($screen-l) {
            max-width: 100%;
        }
    }

    &__payments-list {
        justify-content: $footer__payments-list-justify-content;
        margin: $footer__payments-list-margin;

        @include mq($screen-m) {
            flex-flow: $footer__payments-list-flex-flow\@medium;
            margin: $footer__payments-list-margin\@medium;
            order: $footer__payments-list-order\@medium;
        }
    }

    &__payments-list-icon {
        width: $footer__payments-list-icon-width;
        height: $footer__payments-list-icon-height;
        fill: $footer__payments-list-icon-fill;

        &--visa {
            width: $footer__payments-list-icon-width--visa;
        }

        &--paypal {
            width: $footer__payments-list-icon-width--paypal;
        }
    }

    &__scroll-top {
        position: $footer__scroll-top-position;
        right: $footer__scroll-top-right;
        top: $footer__scroll-top-top;
        background-color: $footer__scroll-top-bg;

        &:before {
            transform: $footer__scroll-top-before-transform;
            background: $footer__scroll-top-before-background;
        }

        &:hover,
        &:focus,
        &.focus-visible {
            background-color: $footer__scroll-top-bg-hover;
        }

        .icon {
            fill: $footer__scroll-top-icon-fill;
        }
    }

    &__switchers {
        display: $footer__switchers-display;
        align-items: $footer__switchers-align-items;
        flex-direction: $footer__switchers-flex-direction;
        padding: $footer__switchers-padding;
        order: $footer__switchers-order;

        @include mq($screen-s) {
            flex-direction: $footer__switchers-flex-direction\@small;
            justify-content: $footer__switchers-justify-content\@small;
            width: $footer__switchers-width\@small;
        }

        @include mq($screen-xl) {
            width: $footer__switchers-width\@xl;
        }
    }

    &__dropdown-switcher {
        width: $footer__switcher-width;
        background-color: $footer__switcher-background-color;

        .dropdown-list__label {
            background-color: $footer__switcher-label-background-color;
            padding: $footer__switcher-label-padding;
            color: $footer__switcher-label-color;
            white-space: $footer__switcher-label-white-space;

            &[aria-expanded=true] {
                background-color: $footer__switcher-label-color;
                color: $footer__switcher-label-background-color;

                &:hover {
                    .dropdown-list__icon {
                        fill: $footer__switcher-label-background-color;
                    }
                }
            }

            &:hover {
                .dropdown-list__icon {
                    fill: $footer__switcher-label-color;
                }
            }
        }

        .dropdown-list__content  {
            position: $footer__switcher-content-position;
            bottom: $footer__switcher-content-bottom;
            width: $footer__switcher-content-width;
            padding: $footer__switcher-content-padding;
            background-color: $footer__switcher-label-color;

            .button {
                font-weight: $footer__switcher-button-font-weight;
                min-height: $footer__switcher-button-min-height;
                text-decoration: $footer__switcher-button-text-decoration;

                &:hover,
                &:focus,
                &.focus-visible {
                    text-decoration: $footer__switcher-button-hover-text-decoration;
                }
            }
        }

        .dropdown-list__icon {
            fill: $footer__switcher-label-color;
        }
    }

    .vat-toggle-link {
        font-size: 13px;
        color: #666666;
        line-height: 17px;
        text-decoration: none;

        @include mq($screen-l) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
