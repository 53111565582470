// Colors
$contact-page__blue                              : #3683C5;
$contact-page__grey-light                        : #e5e5e5;
$contact-page__gray-lighter                      : #F3F3F3;
$contact-page__gray-darker                       : $brew-titles;
$contact-page__gray-dark                         : $brew-text;
$contact-page__gray-dark-label                   : $gray-dark;

// Content
$contact-page__max-width                         : 1240px;
$contact-page__image-icon-size                   : 50px;

$contact-page__textarea-min-height               : 225px;
$contact-page__bubble-width                      : 420px;
$contact-page__icon-width                        : 20px;
$contact-page__triangle                          : 20px;
$contact-page__triangle-large                    : 22px;

$contact-page__height                            : 40px;
$contact-page__icon-height                       : 20px;
$contact-page__button-height                     : 40px;

$contact-page__button-icon-width                 : 18px;
$contact-page__button-icon-height                : 18px;

// Line Height
$contact-page__font-line-height-low              : 20px;
$contact-page__font-line-height                  : 22px;
$contact-page__font-line-height-large            : 24px;
$contact-page__font-line-height-larger           : 25px;
$contact-page__font-line-semi-large              : 38px;

// Font Size
$contact-page__font-size-extra-small             : $font-size-extra-small;
$contact-page__font-size-medium                  : $font-size-medium;
$contact-page__font-size-base                    : $font-size-base;
$contact-page__font-size-base-large              : 15px;

// Space
$contact-page__spacer--extra-large               : 50px;
$contact-page__spacer--larger                    : 35px;
$contact-page__spacer--large                     : 30px;
$contact-page__spacer--medium-low-plus           : 25px;
$contact-page__spacer--medium-low                : 20px;
$contact-page__spacer--medium                    : $spacer--medium;
$contact-page__spacer--semi-medium               : $spacer--semi-medium;
$contact-page__spacer--base                      : 15px;
$contact-page__spacer--extra-small               : 2px;
$contact-page__spacer--small                     : 7px;
$contact-page__spacer--extra-large-plus          : 100px;
$contact-page__checkbox-margin                   : -2px 0 0 -2px;

// Components
$contact-page__box-shadow                        : 0 3px 16px;
$contact-page__opacity                           : 0.7;
$contact-page__border-radius                     : 3px;
