@import 'info-tooltip-variables';

.info-tooltip {
    position: relative;
    margin-left: auto;
    z-index: $z-index-normal + 1;

    &__button {
        position: relative;
        display: inline-flex;
        width: $tooltip__icon-size;
        height: $tooltip__icon-size;
        background-color: $tooltip__icon-color;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        color: $tooltip__icon-font-color;
        font-size: 13px;
        line-height: 1;
        font-weight: 600;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            transition: $transition-base;
            pointer-events: none;
            opacity: 0;
        }

        &::before {
            bottom: -15px;
            border-width: 0 10.5px 14px 10.5px;
            z-index: 29;
            border-color: transparent transparent #ACACAC transparent;
        }

        &::after {
            bottom: -16px;
            border-width: 0 10px 13px 10px;
            z-index: 30;
            border-color: transparent transparent $brew-section-bright transparent;
        }

        &--active {
            &::before,
            &::after {
                display: block;
            }
        }
    }

    &__content {
        position: absolute;
        top: 31px;
        right: -15px;
        width: 364px;
        max-width: 100vw;
        padding: 18px 21px 25px;
        background-color: $tooltip__content-color;
        box-shadow: $tooltip__content-shadow;
        border: $tooltip__border;
        z-index: 29;
        transition: $transition-base;
        pointer-events: none;
        opacity: 0;

        @include mq($screen-l) {
            right: -58px;
        }
    }

    &:hover {
        .info-tooltip__content {
            pointer-events: all;
            opacity: 1;
        }

        .info-tooltip__button {
            &::before,
            &::after {
                pointer-events: all;
                opacity: 1;
            }
        }
    }
}

.catalog-list__products {
    .info-tooltip {
        &__button {
            width: auto;
            height: auto;
            background: none;
        }
    }
}

.tier-tooltip {
    padding: 0;
    list-style-type: none;

    &__row {
        display: flex;

        &:nth-child(even) {
            .tier-tooltip__text {
                background-color: $brew-border;
            }
        }
    }

    &__text {
        flex: 0 0 50%;
        padding: 5px 19px 5px 3px;
        font-size: 13px;
        line-height: 18px;
        color: $brew-text;

        &:last-child {
            text-align: right;
        }
    }
}

.product-grid-item__details-bottom {
    position: relative;

    .info-tooltip {
        position: absolute;
        right: 0;
        top: 23px;
    }
}
