.inquiry-cart {
    margin-bottom: 5rem;

    .content__container {
        display: flex;
        flex-direction: row;
        column-gap: 3rem;

        .form__container {
            flex-basis: 70%;

            &--xs {
                flex-basis: auto;
            }
        }

        .actions__container {
            flex-basis: 30%;

            &--xl {
                flex-basis: auto;
                flex-grow: 1;
            }
        }
    }

    &__table {
        margin-bottom: 3rem;

        &--header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 2rem;
            padding: 30px 25px 0 25px;
            border-bottom: 1px solid #eaeaea;
            position: sticky;
            top: 120px;
            background: #FFFFFF;
            z-index: 1;

            &-title,
            &-price,
            &-qty,
            &-actions {
                padding: 4px 0 11px 0;
                font-size: 14px;
                color: #666666;
                font-weight: 600;
            }

            &-title {
                flex-basis: calc(100% - 390px);
            }
            &-price {
                flex-basis: 200px;
            }
            &-qty {
                flex-basis: 110px;
            }
            &-actions {
                flex-basis: 80px;
            }
        }

        &--empty {
            margin-bottom: 3rem;
            font-size: 16px;
            color: #666666;
        }
    }

    &__overview {
        margin-bottom: 3rem;

        &--header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 0.5rem;
            padding: 0 25px 0 25px;
            border-bottom: 1px solid #eaeaea;
            background: #FFFFFF;
            margin-bottom: 2rem;

            &-title,
            &-price,
            &-qty {
                padding: 4px 0 11px 0;
                font-size: 14px;
                color: #666666;
                font-weight: 600;
            }

            &-title {
                flex-basis: calc(100% - 250px);
            }
            &-qty {
                flex-basis: 50px;
            }
            &-price {
                flex-basis: 200px;
                text-align: end;
            }
        }

        .inquiry-product {
            &__item {
                display: flex;
                width: 100%;
                flex-direction: row;
                flex-wrap: nowrap;
                column-gap: 0.5rem;
                padding: 25px;
            }

            &__item:nth-child(odd) {
                background-color: #F3F3F3;
            }

            &__item:nth-child(even) {
                background-color: #FFFFFF;
            }

            &__info {
                flex-basis: calc(100% - 250px);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                column-gap: 1rem;

                &-image {
                    flex-basis: 90px;
                    max-height: 90px;
                    flex-shrink: 0;

                    .ratio-container {
                        width: 90px;
                    }

                    img {
                        width: 90px;
                        height: auto;
                    }
                }

                &-link {
                    &--name {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 25px;
                        font-weight: 600;
                    }
                }

                &-link:hover {
                    text-decoration: none;
                }
            }

            &__price {
                flex-basis: 200px;
                text-align: end;

                .price {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                }

                .zero-price {
                    font-size: 14px;
                }

                .excluding-tax {
                    display: inline-block;
                }
            }

            &__qty {
                flex-basis: 50px;
                text-align: center;
            }

        }
    }

    &__actions {
        padding: 20px 20px 25px 20px;
        background: #F3F3F3;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        position: sticky;
        top: 150px;

        &--note-label {
            font-size: 12px;
            font-weight: 600;
        }

        &--note p {
            font-size: 12px;
        }

        &--button button {
            width: 100%;
        }
    }

    .progress-bar {
        padding: 0;
        display: block;
        visibility: visible;
        width: 100%;
        margin: 21px auto 97px;
        counter-reset: i;
        font-size: 0;

        &__item {
            display: inline-block;
            position: relative;
            width: 50%;
            line-height: 1.6;
            text-align: center;
            vertical-align: top;
            font-size: 18px;

            &:before {
                content: '';
                position: absolute;
                top: 22px;
                left: 0;
                background: #E5E5E5;
                height: 2px;
                width: 100%;
            }

            &:after {
                background-color: #fff;
            }

            .progress-bar__label {
                width: 200%;
            }

            &:first-child {
                &:before {
                    border-radius: 5px 0 0 5px;
                }
            }

            &:last-child {
                .progress-bar__label {
                    transform: translateX(-50%);
                }

                &:before {
                    border-radius: 0 5px 5px 0;
                }
            }

            &--active {
                &:before {
                    background: #666666;
                }
                .progress-bar__line {
                    color: #1a1b1d;

                    &:before {
                        background: #666666;
                    }

                    &:after {
                        background-color: transparent;
                        color: #ffffff;
                    }
                }

                .progress-bar__label {
                    display: block;
                }
            }

            &--complete {
                cursor: pointer;

                .progress-bar__line {
                    color: #1a1b1d;
                }
            }
        }

        &__line {
            display: inline-block;
            padding: 70px 0 0;
            width: 100%;
            word-wrap: break-word;
            color: #666666;
            font-weight: 400;
            font-size: 14px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                background: #E5E5E5;
                height: 44px;
                margin-left: -22px;
                width: 44px;
                border-radius: 50%;
            }

            &:after {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background: #ffffff;
                color: #666666;
                font-size: 22px;
                font-weight: 600;
                height: 40px;
                margin-left: -20px;
                top: 2px;
                width: 40px;
                content: counter(i);
                counter-increment: i;
            }
        }

        &__label {
            display: none;
            visibility: visible;
            margin: 0;
            font-size: 30px;
            text-align: center;
            white-space: nowrap;
            color: #666666;
        }
    }

    .inquiry-address__table {
        .shipping-address-group {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            max-width: 600px;
            width: 600px;
            margin-top: 2rem;

            .shipping-address-item {
                border: 1px solid #E5E5E5;
                border-radius: 3px;
                line-height: 30px;
                letter-spacing: 0;
                margin: 0 20px 20px 0;
                padding: 15px 22px 21px 22px;
                transition: 0.3s background-color;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                position: relative;
                word-wrap: break-word;
                background: #EEEEEE;
                color: #666666;
                aspect-ratio: 1;
                width: calc(50% - 20px);
                min-width: 278px;

                .action-select-shipping-item {
                    margin-top: auto;

                    &.locked:hover {
                        background: #FFFFFF;
                        cursor: default;
                    }
                }

                &-row {
                    white-space: nowrap;
                    overflow: hidden;
                    overflow: clip;
                    text-overflow: ellipsis;
                }
            }

            .shipping-address-item.selected {
                background: #FFFFFF;
            }
        }

        .new-address-popup {
            width: calc(50% - 20px);
            min-width: 278px;

            button {
                background: #EEEEEE;
                border: 1px solid #e5e5e5;
                border-radius: 3px;
                margin: 0;
                padding: 0;
                width: 100%;
                aspect-ratio: 1;

                span::before {
                    content: '\002B';
                    font-size: 100px;
                    font-weight: 700;
                    line-height: 1;
                    color: #A9A9A9;
                    transition: all 500ms ease-in-out;
                }
            }

            button:hover {
                background-color: #D9D9D9;
            }
        }
    }
}

#inquirycart:has(> .header__inquirycart)  {
    display: none;
    position: absolute;
}

#inquirycart:has(> .header__inquirycart--visible)  {
    display: flex;
    position: static;
    align-items: center;
}

.shipping-address-popup.modal-popup {
    .modal-inner-wrap {
        max-width: 644px;
        margin: auto !important;
        bottom: 0;
        top: 0;
    }

    .modal-header {
        padding-bottom: 29px;
    }

    .modal-content {
        padding-top: 0;

        .shipping-info {
            display: block;
            margin-top: 1rem;
        }
    }

    .modal-footer {
        display: flex;
        column-gap: 20px;

        button {
            width: calc(50% - 10px);
        }
    }

    form {
        margin-top: 1rem;
    }

    label.label.required::after {
        content: '*';
    }

    .double-inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 20px;

        .input {
            width: calc(50% - 10px);
        }
    }
}

.inquiry-product {
    &__item {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 2rem;
        padding: 25px;
    }

    &__item:nth-child(even) {
        background-color: #F3F3F3;
    }

    &__info {
        flex-basis: calc(100% - 390px);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 1rem;

        &-image {
            flex-basis: 120px;
            max-height: 120px;
            flex-shrink: 0;

            .ratio-container {
                width: 120px;
            }

            img {
                width: 120px;
                height: auto;
            }
        }

        &-link {
            flex-direction: column;

            &--name {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 25px;
                font-weight: 600;
            }
        }

        &-link:hover {
            text-decoration: none;
        }
    }

    &__price {
        flex-basis: 200px;

        .price {
            font-size: 16px;
            line-height: 18px;
            font-weight: 600;
        }

        .zero-price {
            font-size: 14px;
        }

        .excluding-tax {
            display: inline-block;
        }
    }

    &__qty {
        flex-basis: 110px;
    }

    &__actions {
        flex-basis: 80px;
        text-align: center;
    }

    &__button {
        background-color: transparent;
        height: 40px;
        padding-left: 0;
        padding-right: 0;
        margin-right: -5px;
        min-width: 20px;

        &.button__icon {
            width: 25px;
            min-width: 25px;
        }

        &.delete {
            position: relative;
            width: 10px;
            height: 10px;
            padding-bottom: 1px;

            &::after,
            &::before {
                content: '';
                display: block;
                background-color: #A8A8A8;
                width: 14px;
                height: 3px;
                position: absolute;
                transition: all 0.3s ease-in-out;
            }

            &::after {
                transform: rotate(45deg);
            }

            &::before {
                transform: rotate(-45deg);
            }

            &:hover {
                &::after,
                &::before {
                    background-color: #666666;
                }
            }
        }
    }
}

.inquiry-address__table {
    .shipping-address-group {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        max-width: 600px;
        margin-top: 2rem;

        .shipping-address-item {
            border: 1px solid #E5E5E5;
            border-radius: 3px;
            line-height: 30px;
            letter-spacing: 0;
            margin: 0 20px 20px 0;
            padding: 15px 22px 21px 22px;
            transition: 0.3s background-color;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            position: relative;
            word-wrap: break-word;
            background: #EEEEEE;
            color: #666666;
            aspect-ratio: 1;
            width: calc(50% - 20px);
            min-width: 278px;

            .action-select-shipping-item {
                margin-top: auto;

                &.locked:hover {
                    background: #FFFFFF;
                    cursor: default;
                }
            }
        }

        .shipping-address-item.selected {
            background: #FFFFFF;
        }
    }

    .new-address-popup {
        width: calc(50% - 20px);
        min-width: 278px;

        button {
            background: #EEEEEE;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            margin: 0;
            padding: 0;
            width: 100%;
            aspect-ratio: 1;

            span::before {
                content: '\002B';
                font-size: 100px;
                font-weight: 700;
                line-height: 1;
                color: #A9A9A9;
                transition: all 500ms ease-in-out;
            }
        }

        button:hover {
            background-color: #D9D9D9;
        }
    }
}

@media (max-width: 1280px) {
    .inquiry-cart {
        .content__container {
            flex-direction: column;
        }

        .inquiry-address__table {
            .shipping-address-group {
                max-width: 70%;
                width: auto;
                margin-left: auto;
                margin-right: auto;
                flex-direction: column;

                .shipping-address-item {
                    width: 100%;
                    aspect-ratio: unset;

                    .action-select-shipping-item {
                        margin-top: 1.5rem;
                    }

                    &-row {
                        white-space: normal;
                    }
                }

                .new-address-popup {
                    width: 100%;

                    button {
                        width: 100%;
                        aspect-ratio: unset;
                        min-height: 278px;
                    }
                }
            }

            textarea.input-text {
                margin-bottom: 4rem;
            }

        }
    }


}

@media (max-width: 1023px) {
    .inquiry-cart {
        &__table--header {
            top: 68px;
        }
    }
}

@media (max-width: 992px) {
    .inquiry-cart {
        .progress-bar {
            margin: 44px auto 41px;

            &__item {
                &:before {
                    top: 20.5px;
                }
            }

            &__line {
                padding: 52px 0 0;

                &:before,
                &:after {
                    height: 41px;
                    margin-left: -20.5px;
                    width: 41px;
                }

                &:after {
                    margin-left: -18.5px;
                    width: 37px;
                    height: 37px;
                    font-size: 14px;
                }
            }

            &__label {
                font-size: 20px;
            }
        }

        &__table {
            .inquiry-product {
                &__item {
                    flex-wrap: wrap;
                    row-gap: 22px;
                    column-gap: 22px;
                }

                &__info {
                    flex-basis: 100%;
                }

                &__price {
                    flex-basis: calc(100% - 246px);
                    padding-left: 131px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .inquiry-cart {
        &__table--header {
            top: 35px;
        }

        &__overview {
            .inquiry-product {
                &__item {
                    flex-wrap: wrap;
                    column-gap: 10px;
                    justify-content: end;
                }

                &__info {
                    flex-basis: calc(100% - 60px);

                    &-link--name {
                        margin-bottom: 10px;
                    }
                }
            }

        }

        .progress-bar {
            &__item {
                &:last-child {
                    .progress-bar__label {
                        span {
                            display: none;
                        }

                        &::after {
                            content: attr(data-h1);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 639px) {
    .inquiry-cart {
        &__table {
            .inquiry-product {
                &__info {
                    flex-direction: column;
                    row-gap: 1rem;

                    &-link--name {
                        margin-bottom: 10px;
                    }
                }

                &__price {
                    flex-basis: 100%;
                    padding-left: 0;
                }

                &__qty {
                    flex-basis: calc(100% - 102px);
                }
            }
        }

        .inquiry-address__table {
            .shipping-address-group {
                max-width: 100%;
            }
        }
    }
}
